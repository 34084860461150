import React, { useCallback, type UIEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import { LinkIconButton } from '@atlaskit/button/new';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from '@atlassian/jira-servicedesk-queues-common/src/view/messages.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';

const getIssueNavUrl = (projectKey: string | undefined, queueJql: string) =>
	projectKey
		? `/jira/servicedesk/projects/${projectKey}/issues/?jql=${encodeURIComponent(queueJql)}`
		: `/issues/?jql=${encodeURIComponent(queueJql)}`;

export type Props = {
	projectKey?: string;
	queueJql: string;
};

export const OpenInIssueNavButton = (_props: Props) => {
	const { projectKey, queueJql } = _props;
	const issueNavUrl = getIssueNavUrl(projectKey, queueJql);
	const { formatMessage } = useIntl();

	const onClick = useCallback(
		(_: UIEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'navigateToIssueNavFromQueues', {
				hasProjectKey: typeof projectKey === 'string',
			});
		},
		[projectKey],
	);

	return (
		<LinkIconButton
			href={issueNavUrl}
			target="_blank"
			icon={ShortcutIcon}
			onClick={onClick}
			label={formatMessage(
				fg('itsm-issue-renaming')
					? messages.openWithIssueNavIssueTermRefresh
					: messages.openWithIssueNav,
			)}
			isTooltipDisabled={false}
			{...(getWillShowNav4() ? {} : { appearance: 'subtle', spacing: 'compact' })}
		/>
	);
};
