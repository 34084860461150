import React, { useEffect } from 'react';
import noop from 'lodash/noop';
import memoizeOne from 'memoize-one';
import { Box, xcss } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsItsmTemplateProject } from '@atlassian/jira-router-resources-service-desk-is-itsm-project/src/controllers/index.tsx';
import { HrExpansionSpotlight } from '@atlassian/jira-service-management-hr-expansion-experiment-spotlight/src/index.tsx';
import { useProjectBuilder } from '@atlassian/jira-service-project-layout/src/services/use-project-builder/index.tsx';
import { ProjectTour } from '@atlassian/jira-servicedesk-itsm-first-session-flow/src/ui/project-tour/async.tsx';
import AgentView from '@atlassian/jira-servicedesk-queues-agent-view/src/view/index.tsx';
import QueueProvider from '@atlassian/jira-servicedesk-queues-available-queues/src/ui/queue-provider/index.tsx';
import { PageStyle } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-style/index.tsx';
import {
	useStylingPatch,
	QueueNav3StylingPatchContainer,
} from '@atlassian/jira-horizontal-nav-queue/src/controllers/styling-patch/index.tsx';
import { toProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

import type { AgentViewProps } from '../../types.tsx';
import CipherDeprecationBanner from './cipher-deprecation-banner/index.tsx';

const deprecatedAgentViewProps = {
	onAtLeastOneIssueLoaded: noop,
} as const;

const getInitialState = memoizeOne((_queue) => {
	// Only change the reference of initial state when we transition to a new queue, to avoid unnecessary re-renders
	// This shape of prop is required to be compatible what the AgentView expects in the non-spa
	// When JSD agent view is in the SPA by the default we can probably just pass appProps directly
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const initialState: Record<string, any> = {};
	return memoizeOne((appProps) => {
		initialState.appProps = appProps;
		return initialState;
	});
});

const QueuesAgentViewContent = ({
	queuesData,
	queueId,
	push,
	pageContext,
	isQueueVisible,
	isAgentViewMountedRef,
	onIssuesChanged,
	onIssuesCountChanged,
}: AgentViewProps) => {
	useEffect(() => {
		if (isAgentViewMountedRef) {
			// eslint-disable-next-line no-param-reassign
			isAgentViewMountedRef.current = true;
			return () => {
				// eslint-disable-next-line no-param-reassign
				isAgentViewMountedRef.current = false;
			};
		}
		return noop;
	});

	const { data: isItsmProject } = useIsItsmTemplateProject();

	const { canSeeNewIssueView, isSiteAdmin, userRole, serviceDeskId, ...appProps } = pageContext;
	const [state] = useProjectBuilder();

	const { shouldUseStylingPatch: shouldShowContainer } = useStylingPatch();

	const content = (
		<>
			{isItsmProject === true && !state.isProjectBuilderOpen && (
				<ProjectTour
					projectId={toProjectId(`${pageContext.projectId}`)}
					portalUrl={pageContext.portalUrl}
				/>
			)}
			<QueueProvider
				queuesDataSource={queuesData}
				baseUrl=""
				queueId={queueId}
				projectKey={pageContext.projectKey}
			>
				{(queue) => (
					<>
						<AgentView
							{...deprecatedAgentViewProps}
							// @ts-expect-error - TS2741 - Property 'appProps' is missing in type 'Record<string, any>' but required in type 'InitialState'.
							initialState={getInitialState(queue)(appProps)}
							locale={pageContext.locale}
							isQueueVisible={isQueueVisible}
							queue={queue}
							push={push}
							// set onQueueRenderFailure - undefined to satisfy flow (ignored prop)
							onQueueRenderFailure={undefined}
							onIssuesChanged={onIssuesChanged}
							onIssuesCountChanged={onIssuesCountChanged}
						/>
					</>
				)}
			</QueueProvider>
		</>
	);

	const queueLayout = shouldShowContainer ? (
		<QueueNav3StylingPatchContainer>
			<Box paddingInline="space.300" xcss={layoutStyles}>
				{content}
			</Box>
		</QueueNav3StylingPatchContainer>
	) : (
		<Box paddingInline="space.300" xcss={containerStyles}>
			<Box xcss={layoutStyles}>{content}</Box>
		</Box>
	);

	return (
		<UFOSegment name="queues-agent-view">
			{
				// eslint-disable-next-line jira/ff/no-preconditioning
				fg('spartans_cipher_deprecation_banner') && isQueueVisible && <CipherDeprecationBanner />
			}
			{fg('jsm_views_inside_queues_-_main_flag') ? queueLayout : <PageStyle>{content}</PageStyle>}
			{expVal('jsm_hr_expansion', 'cohort', 'not-enrolled') !== 'not-enrolled' ? (
				<HrExpansionSpotlight />
			) : null}
		</UFOSegment>
	);
};

export default QueuesAgentViewContent;
const containerStyles = xcss({ flexGrow: 1 });
const layoutStyles = xcss({ height: '100%' });
