import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AsyncQueuesAutomationMenu } from '@atlassian/jira-servicedesk-queues-automation-menu/src/async.tsx';
import { SyncQueuesAutomationMenu } from '@atlassian/jira-servicedesk-queues-automation-menu/src/ui/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	isManuallySorted: boolean;
	isFavoriteEnabled: boolean;
	canManageQueue: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ResetSortingButton: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ActionsMenu: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FavoriteButton: ComponentType<any>;
	showIssueFailureError: boolean;
	onDeleteComplete: (arg1: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	OpenInIssueNavButton: ComponentType<any>;
};

export default function HeaderActions({
	canManageQueue,
	isManuallySorted,
	isFavoriteEnabled,
	onDeleteComplete,
	showIssueFailureError,
	ActionsMenu,
	FavoriteButton,
	ResetSortingButton,
	OpenInIssueNavButton,
}: Props) {
	const shouldShowResetSortingButton =
		!showIssueFailureError && !canManageQueue && isManuallySorted;
	const hasMultipleActions = isFavoriteEnabled && (canManageQueue || shouldShowResetSortingButton);

	return (
		<Wrapper>
			{!__SERVER__ &&
				/* eslint-disable-next-line jira/ff/no-preconditioning */
				(fg('jsm_queues_jql_builder_perf_improvement') &&
				fg('move_jsm_queue_automation_menu_to_sync') ? (
					<SyncQueuesAutomationMenu />
				) : (
					<AsyncQueuesAutomationMenu />
				))}
			<>
				<OpenInIssueNavButton />
				<Spacer />
			</>
			{!__SERVER__ && isFavoriteEnabled && <FavoriteButton />}
			{!__SERVER__ && hasMultipleActions && <Spacer />}
			{!__SERVER__ && canManageQueue && <ActionsMenu onDeleteComplete={onDeleteComplete} />}
			{!__SERVER__ && shouldShowResetSortingButton && <ResetSortingButton />}
		</Wrapper>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Spacer = styled.div({
	width: token('space.100'),
});
