import React, { useCallback, useEffect, type ReactNode } from 'react';
import isEqual from 'lodash/fp/isEqual';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { ExperienceFailureTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { staticJSMProjectConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-jsm-connect-addons-queues/index.tsx';
import { PROJECTS_NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-id.tsx';
import { FIXED_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { useConnectAppNavigationData } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/controllers/use-connect-app-navigation-data/index.tsx';
import { ConnectMenu } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/ui/connect-menu/index.tsx';
import type { ForgeItemProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-forge/src/common/types.tsx';
import { ForgeServiceDeskQueueSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-forge/src/ui/servicedesk-queue/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context/index.tsx';
import { usePriorityGroups } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/priority-group/index.tsx';
import { useCategorizedNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';

import { NO_CATEGORY } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { useOnFavoritesChanged } from '../../controllers/favoriting/index.tsx';
import { useIssueCountEnabledQueues } from '../../controllers/issue-count-enabled-queues/index.tsx';
import { useSelectedNavLocation } from '../../controllers/nav-location/index.tsx';
import {
	getCategoryStateKey,
	useLastVisitedQueueId,
} from '../../controllers/queues-category-state/index.tsx';
import { QueueListSection } from '../../types/index.tsx';
import { usePopoutConfig } from '../../../utils/popout-view/index.tsx';
import { AllQueuesSection } from './all-queues-section/index.tsx';
import { messages } from './messages.tsx';
import { QueuesExperienceTracker } from './experience-tracker/main.tsx';
import { QueueGroupsSection } from './queue-groups/index.tsx';
import { StarredSection } from './starred-section/index.tsx';
import type { PracticeQueuesProps } from './types.tsx';

const PracticeQueuesError = ({ onRefresh }: { onRefresh: () => void }) => {
	const { formatMessage } = useIntl();
	return (
		<Box padding="space.100">
			{formatMessage(messages.fetchQueuesError, {
				button: (buttonLabel: ReactNode) => (
					<Button spacing="none" appearance="link" onClick={onRefresh}>
						{buttonLabel}
					</Button>
				),
			})}
		</Box>
	);
};

const connectItemAnalytics = {
	actionSubjectId: PROJECTS_NAVIGATION_ITEM,
	level: 4,
};

const forgeItemAnalytics: ForgeItemProps['analytics'] = {
	actionSubjectId: PROJECTS_NAVIGATION_ITEM,
	componentType: FIXED_ITEM,
	level: 4,
};

const PracticeQueuesInternal = ({ practice = undefined, projectKey }: PracticeQueuesProps) => {
	const currentCategory = practice || NO_CATEGORY;
	const queueCustomContext = useQueueCustomRouterContext(projectKey);

	const [, { updateLastVisitedQueueId }] = useLastVisitedQueueId(
		getCategoryStateKey(projectKey, currentCategory),
	);
	const { data: queueConnectItems } = useConnectAppNavigationData(
		projectKey,
		staticJSMProjectConnectAddonsNavigationResource,
	);
	const [queues, queuesLoading, queuesError, methods, refreshQueues] = useCategorizedNavItems(
		'',
		projectKey,
		currentCategory,
		queueCustomContext,
	);

	const popoutConfig = usePopoutConfig(projectKey, currentCategory);
	const [projectKeyFromPath] = usePathParam('projectKey');
	const [queueIdFromPath] = usePathParam('queueId');
	const [practiceFromPath] = usePathParam('practiceType');
	const categoryFromPath = toItsmPractice(practiceFromPath);
	const queueFromPath = queues?.find((queue) => queue.id === queueIdFromPath);
	const [selectedNavLocation, { updateSelectedNavLocation }] = useSelectedNavLocation(
		queueIdFromPath || '',
	);
	const [groups, groupsLoading, groupsFetchingError, refreshGroups] = usePriorityGroups(
		currentCategory,
		queueCustomContext,
	);
	const [issueCountEnabledQueues, { updateIssueCountEnabledQueues }] = useIssueCountEnabledQueues();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useOnFavoritesChanged(projectKey, practice || NO_CATEGORY, queues, methods.editItemFavorite);

	const isInvalidNavLocation = useCallback(() => {
		if (queueIdFromPath) {
			if (!queueFromPath?.starred && selectedNavLocation.section === QueueListSection.STARRED) {
				return true;
			}
		}
		return false;
	}, [queueIdFromPath, queueFromPath, selectedNavLocation]);

	const selectDefaultNavLocation = useCallback(() => {
		if (queueIdFromPath === undefined) {
			return;
		}

		// Queue is in starred section
		if (queueFromPath?.starred) {
			updateSelectedNavLocation(queueIdFromPath, QueueListSection.STARRED);
			return;
		}

		// Queue is in priority group
		const firstGroupWithQueue = groups.find((group) =>
			group.queues.includes(Number(queueIdFromPath)),
		);
		if (firstGroupWithQueue) {
			updateSelectedNavLocation(
				queueIdFromPath,
				QueueListSection.PRIORITY_GROUP,
				firstGroupWithQueue.id,
			);
			return;
		}

		// Queue is in all queues section
		updateSelectedNavLocation(queueIdFromPath, QueueListSection.ALL_QUEUES);
	}, [queueIdFromPath, queueFromPath, groups, updateSelectedNavLocation]);

	const disableIssueCountRefreshForAllQueues = useCallback(() => {
		if (issueCountEnabledQueues.length > 0) {
			methods.enableIssueCountForSelectedQueues([]);
			updateIssueCountEnabledQueues([]);
		}
	}, [issueCountEnabledQueues, methods, updateIssueCountEnabledQueues]);

	const enableIssueCountRefreshForSelectedQueues = useCallback(
		(newQueues: number[]) => {
			if (!queues) {
				return;
			}

			// Enable issue-counts for starred queues as well
			const starredQueues = queues
				.filter((queue) => queue.starred)
				.map((queue) => Number(queue.id));
			const queuesToRefresh = Array.from(new Set([...newQueues, ...starredQueues]));

			// If the queues to enable have not changed, do nothing
			if (isEqual(queuesToRefresh, issueCountEnabledQueues)) {
				return;
			}

			// Refresh issue count for the newly visible queues in the selected group
			const newlyVisibleQueuesInSelectedGroup = queuesToRefresh
				.filter((queueId) => !issueCountEnabledQueues.includes(queueId))
				.map((queueId) => String(queueId));

			methods.refreshIssueCountForItems(newlyVisibleQueuesInSelectedGroup);
			updateIssueCountEnabledQueues(queuesToRefresh);

			// Enable issue count for the selected queues - group queues only
			methods.enableIssueCountForSelectedQueues(newQueues);
		},
		[queues, methods, issueCountEnabledQueues, updateIssueCountEnabledQueues],
	);

	// Enable issue-counts refresh for the correct queues for the current nav location
	useEffect(() => {
		if (!queueIdFromPath) {
			disableIssueCountRefreshForAllQueues();
			return;
		}

		const inSelectedProject = projectKey === projectKeyFromPath;
		const inSelectedCategory = currentCategory === categoryFromPath;

		const shouldUpdateIssueCounts =
			!inSelectedProject ||
			!inSelectedCategory ||
			groupsLoading ||
			groupsFetchingError ||
			queuesLoading ||
			queuesError;

		if (shouldUpdateIssueCounts) {
			return;
		}

		// If the queue does not have a valid section, set a default location
		if (selectedNavLocation.section === undefined || isInvalidNavLocation()) {
			selectDefaultNavLocation();
			return;
		}

		// update the last visited queue for this category in the local storage
		updateLastVisitedQueueId(projectKey, currentCategory, queueIdFromPath);

		// enable issue count refresh for the selected queues
		const selectedGroup = groups.find((group) => group.id === selectedNavLocation.groupId);
		enableIssueCountRefreshForSelectedQueues(selectedGroup?.queues || []);
	}, [
		projectKey,
		projectKeyFromPath,
		queueIdFromPath,
		groups,
		groupsLoading,
		groupsFetchingError,
		selectedNavLocation,
		issueCountEnabledQueues,
		selectDefaultNavLocation,
		isInvalidNavLocation,
		disableIssueCountRefreshForAllQueues,
		enableIssueCountRefreshForSelectedQueues,
		updateLastVisitedQueueId,
		currentCategory,
		categoryFromPath,
		queuesLoading,
		queuesError,
	]);

	// analytics
	useEffect(() => {
		if (!queues && !queuesLoading) {
			return;
		}

		if (queuesError) {
			fireErrorAnalytics({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: queuesError as Error,
				meta: {
					id: 'jsmQueuesListError',
					packageName: 'jiraServicedeskQueuesNav4',
					teamName: 'jsd-shield',
				},
			});
		} else {
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'nav4PracticeQueueNavItemsWithQueueGroups rendered',
				{
					projectKey,
					category: currentCategory,
					packageName: 'jiraServicedeskQueuesNav4',
					teamName: 'jsd-shield',
				},
			);
		}
	}, [createAnalyticsEvent, currentCategory, queues, queuesError, projectKey, queuesLoading]);

	if (queuesError) {
		if (!fg('update_viewqueuesnav_instrumentation')) {
			return <PracticeQueuesError onRefresh={refreshQueues} />;
		}

		return (
			<ExperienceFailureTracker
				location="failed-fetching-initial-data"
				failureEventAttributes={{
					errorName: queuesError.name,
					errorMessage: queuesError.message,
					errorStack: queuesError.stack,
				}}
			>
				<PracticeQueuesError onRefresh={refreshQueues} />
			</ExperienceFailureTracker>
		);
	}

	if (fg('jsm_views_inside_queues_-_main_flag')) {
		return (
			<Box xcss={popoutConfig.isPoppedOut ? poppedOutPadding : undefined}>
				<StarredSection practice={currentCategory} projectKey={projectKey} />
				<QueueGroupsSection
					projectKey={projectKey}
					currentCategory={currentCategory}
					onGroupDataRefresh={refreshGroups}
				/>
				<AllQueuesSection practice={currentCategory} projectKey={projectKey} />
				{(!practice || practice === NO_CATEGORY) && (
					<>
						<ConnectMenu
							items={queueConnectItems}
							showIcons
							connectItemAnalytics={connectItemAnalytics}
						/>
						<ForgeServiceDeskQueueSection projectKey={projectKey} analytics={forgeItemAnalytics} />
					</>
				)}
			</Box>
		);
	}

	return (
		<>
			<StarredSection practice={currentCategory} projectKey={projectKey} />
			<QueueGroupsSection
				projectKey={projectKey}
				currentCategory={currentCategory}
				onGroupDataRefresh={refreshGroups}
			/>
			<AllQueuesSection practice={currentCategory} projectKey={projectKey} />
			{(!practice || practice === NO_CATEGORY) && (
				<>
					<ConnectMenu
						items={queueConnectItems}
						showIcons
						connectItemAnalytics={connectItemAnalytics}
					/>
					<ForgeServiceDeskQueueSection projectKey={projectKey} analytics={forgeItemAnalytics} />
				</>
			)}
		</>
	);
};

export const PracticeQueuesWithExperienceTracker = ({
	practice = undefined,
	projectKey,
}: PracticeQueuesProps) => {
	return (
		<QueuesExperienceTracker>
			<PracticeQueuesInternal practice={practice} projectKey={projectKey} />
		</QueuesExperienceTracker>
	);
};

export const PracticeQueues = componentWithFG(
	'update_viewqueuesnav_instrumentation',
	PracticeQueuesWithExperienceTracker,
	PracticeQueuesInternal,
);

const poppedOutPadding = xcss({
	padding: 'space.300',
	paddingTop: 'space.250',
});
