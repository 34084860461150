import { Environment as A4JEnv } from '@atlassian/automation-api';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

export const mapEnv = (env: Environment, devHelperFFEnabled?: boolean): A4JEnv => {
	switch (env) {
		case 'prod':
			return devHelperFFEnabled ? A4JEnv.PRE_PROD : A4JEnv.PROD;
		default:
			return A4JEnv.DEV;
	}
};
