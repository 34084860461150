import React from 'react';

import DashboardIcon from '@atlaskit/icon/core/migration/dashboard--activity';
import { Stack } from '@atlaskit/primitives';
import { getIconByDisplayMetadata } from '@atlassian/automation-ui-commons';

import { TemplateIcon } from '../template-icon';
import { TemplateItem } from '../template-item';

import { type Template } from './types';

interface Props {
	templates: Template[];
	onTemplateSelected: (templateId: string) => void;
	isDisabled: boolean;
}

/**
 *
 * Container that resolves a list of templates passed in using the iconResolveIndex, and creates Templates.
 * It uses an icon resolver which takes in a string and produces an icon, which then
 * passes this list to TemplateIcons.
 *
 * @param props - templates, onTemplateSelected, isDisabled
 */
export const TemplateList = ({ templates, onTemplateSelected, isDisabled }: Props) => {
	return (
		<Stack>
			{templates.map(
				({ description, displayMetadata: { triggerIcons, actionIcons }, id }, index) => {
					const [triggerIcon] = triggerIcons;
					const [actionIcon] = actionIcons;
					return (
						<TemplateItem
							onClick={() => onTemplateSelected(id)}
							key={index}
							triggerIcon={
								<TemplateIcon
									// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19608
									icon={triggerIcon ? getIconByDisplayMetadata(triggerIcon) : DashboardIcon}
									size="small"
									type="trigger"
								/>
							}
							actionIcon={
								<TemplateIcon
									// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO - https://product-fabric.atlassian.net/browse/DSP-19608
									icon={actionIcon ? getIconByDisplayMetadata(actionIcon) : DashboardIcon}
									size="small"
									type="action"
								/>
							}
							description={description}
							templateId={id}
							isDisabled={isDisabled}
						/>
					);
				},
			)}
		</Stack>
	);
};
