import React, { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { Box, xcss } from '@atlaskit/primitives';
import { TemplateDropdown } from '@atlassian/automation-templates';
import { getBaseAutomationUrl } from '@atlassian/jira-automation-platform/src/common/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { toFlagId, useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { IntlProvider } from '@atlassian/jira-intl/src/intl-provider.tsx';
import { useSettingFeatures } from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/controllers/features/main.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useWorkCategoriesAllSupported } from '@atlassian/jira-router-resources-service-desk-practices/src/controllers/index.tsx';
import { CHANGES } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { PACKAGE_NAME } from '../../common/constants.tsx';
import { mapEnv } from '../../common/utils/map-env.tsx';
import { FailureFlag } from './failure-flag/index.tsx';
import { SuccessFlag } from './success-flag/index.tsx';
import { useTemplateCategories } from './use-template-categories/index.tsx';

export const AutomationMenu = () => {
	const cloudID = useCloudId();
	const environment = useEnvironment();
	const { locale } = useIntl();
	const enabledPractices = useWorkCategoriesAllSupported();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const projectContext = useProjectContext();
	const { showFlag } = useFlagsService();
	const automationEnabled = !!useSettingFeatures().data?.hasProjectAutomation;

	const onFetchFailedHandler = useCallback(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.onFetch failed`);
	}, [createAnalyticsEvent]);

	const onFetchSuccessHandler = useCallback(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.onFetch succeeded`);
	}, [createAnalyticsEvent]);

	if (!automationEnabled) {
		return null;
	}

	if (!projectContext.data) {
		fireTrackAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.useProjectContext failed`);
		return null;
	}
	const { projectKey, simplified, projectType, projectId } = projectContext.data;

	const automationBaseUrl = getBaseAutomationUrl({
		projectKey: `${projectKey}`,
		isSimplified: simplified || false,
		projectType,
	});

	// automationBaseUrl is needed, depends on projectContext.data
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const onCreateFailureHandler = useCallback(
		(templateId: string) => {
			const flagId = toFlagId(uuid());
			showFlag({
				id: flagId,
				render: (flagProps) => {
					const props = {
						automationBaseUrl,
						flagKey: String(flagProps.key),
						flagId,
						templateId,
						messageId:
							'servicedesk-queues-automation-menu.ui.automation-menu.props.show-flag.error',
						messageType: 'transactional',
					};
					return <FailureFlag {...props} />;
				},
			});
			fireTrackAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.onCreate failed`);
		},
		[automationBaseUrl, createAnalyticsEvent, showFlag],
	);
	// automationBaseUrl is needed, depends on projectContext.data
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const onCreateSuccessHandler = useCallback(
		(templateId: string, ruleId: number) => {
			const flagId = toFlagId(uuid());
			showFlag({
				id: flagId,
				render: (flagProps) => {
					const props = {
						automationBaseUrl,
						flagKey: String(flagProps.key),
						flagId,
						ruleId,
						messageId:
							'servicedesk-queues-automation-menu.ui.automation-menu.props.show-flag.success',
						messageType: 'transactional',
					};
					return <SuccessFlag {...props} />;
				},
			});
			fireTrackAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.onCreate succeeded`);
		},
		[automationBaseUrl, createAnalyticsEvent, showFlag],
	);

	if (enabledPractices.loading) {
		return null;
	}
	if (enabledPractices.error) {
		fireTrackAnalytics(
			createAnalyticsEvent({}),
			`${PACKAGE_NAME}.useWorkCategoriesAllSupported failed`,
		);
		return null;
	}
	const isChangeManagementPracticeEnabled: boolean =
		enabledPractices.data?.includes(CHANGES) || false;

	// projectId and isChangeManagementPracticeEnabled is needed, depends on projectContext.data and enabledPractices.data
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const templateCategories = useTemplateCategories(projectId, isChangeManagementPracticeEnabled);

	if (templateCategories.loading) {
		return null;
	}

	const mappedEnvironment = mapEnv(environment, fg('automation-dev-helper'));

	return (
		<Box xcss={styles}>
			<IntlProvider locale={locale}>
				<TemplateDropdown
					env={mappedEnvironment}
					site={`ari:cloud:jira::site/${cloudID}`}
					query={{ categories: templateCategories.data }}
					baseAutomationUrl={automationBaseUrl}
					targetRuleHome={`ari:cloud:jira:${cloudID}:project/${projectId}`}
					onCreateFailure={onCreateFailureHandler}
					onCreateSuccess={onCreateSuccessHandler}
					onFetchFailed={onFetchFailedHandler}
					onFetchSuccess={onFetchSuccessHandler}
					dropdownMenuProps={{ placement: 'bottom-end' }}
				/>
			</IntlProvider>
		</Box>
	);
};

const styles = xcss({ marginRight: 'space.100', whiteSpace: 'normal' });
