import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import Image from '@atlaskit/image';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';

import { AutomationIntlMessagesProvider, locales } from '../../common/utils/i18n';

import { messages } from './messages';

const src =
	'https://jira-frontend-bifrost.stg-east.frontend.public.atl-paas.net/assets/error.4fa1b24b.svg';
const containerStyles = xcss({
	maxWidth: '100%',
});
const contentContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	marginBottom: 'space.200',
	marginTop: 'space.200',
});
const ErrorState = () => (
	<AutomationIntlMessagesProvider locales={locales}>
		<Box testId="templates-dropdown.error-state-container">
			<Inline alignBlock="center" alignInline="center" xcss={containerStyles}>
				{/* Image accepts native passthrough props for width/height so we cannot use XCSS here */}
				<Image src={src} alt="Error" testId="error.state.img" width="112" height="84" />
			</Inline>
			<Box xcss={contentContainerStyles}>
				<Text size="large" weight="semibold" align="center">
					<FormattedMessage {...messages.errorTitle} />
				</Text>
				<Text size="medium" align="center">
					<FormattedMessage {...messages.errorDescription} />
				</Text>
			</Box>
		</Box>
	</AutomationIntlMessagesProvider>
);

export default ErrorState;
