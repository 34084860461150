import React, { type ReactNode } from 'react';
import { ExperienceTracker as ViewExperienceTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/tracker-base/index.tsx';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import OnCatch from '@atlassian/jira-common-util-op-triggers/src/on-catch/OnCatch.tsx';
import OpTrigger from '@atlassian/jira-common-util-op-triggers/src/on-mount/OpTrigger.tsx';
import { JIRA_SERVICE_DESK } from '@atlassian/jira-shared-types/src/application.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';

type QueuesExperienceTrackerProps = {
	children: ReactNode;
};
export const QueuesExperienceTracker = ({ children }: QueuesExperienceTrackerProps) => {
	const appEditions = useAppEditions();
	return (
		<ViewExperienceTrackingProvider
			application={JIRA_SERVICE_DESK}
			edition={appEditions.serviceDesk}
			// DESK-5408 replace "viewQueuesNav4" with "viewQueuesNav"
			experience="viewQueuesNav4"
			analyticsSource="queuesNav"
		>
			<ViewExperienceTracker location="queues-nav-render-error">
				{({ onExperienceSuccess, onExperienceFailure }) => (
					<OnCatch
						handler={(error) => {
							onExperienceFailure(undefined, {
								errorMessage: error?.message,
								errorName: error?.name,
							});
						}}
						isRethrowEnabled
					>
						<>
							{children}
							<OpTrigger op={onExperienceSuccess} />
						</>
					</OnCatch>
				)}
			</ViewExperienceTracker>
		</ViewExperienceTrackingProvider>
	);
};
