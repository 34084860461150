import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

import { messages } from './messages';

interface NumberParameterInputProps {
	name: string;
	required: boolean;
}

export enum NumberParameterInputErrors {
	EMPTY = 'EMPTY',
	INVALID_NUMBER = 'INVALID_NUMBER',
}

export const numberValidate = (isRequired: boolean, value?: string) => {
	if (isRequired && !value) {
		return NumberParameterInputErrors.EMPTY;
	}

	if (value && Number.isNaN(Number(value))) {
		return NumberParameterInputErrors.INVALID_NUMBER;
	}

	return undefined;
};

const NumberParameterInput = ({ name, required }: NumberParameterInputProps) => {
	const generateErrorMessage = (error?: string) => {
		switch (error) {
			case NumberParameterInputErrors.EMPTY:
				return (
					<ErrorMessage testId={'number-parameter-error-' + name}>
						<FormattedMessage {...messages.parameterModalErrorEmptyNumber} />
					</ErrorMessage>
				);
			case NumberParameterInputErrors.INVALID_NUMBER:
				return (
					<ErrorMessage testId={'number-parameter-error-' + name}>
						<FormattedMessage {...messages.parameterModalErrorInvalidNumber} />
					</ErrorMessage>
				);
			default:
				return null;
		}
	};

	return (
		<Field
			name={name}
			label={name}
			isRequired={required}
			validate={(value: any) => numberValidate(required, value)}
		>
			{({ fieldProps, error }: any) => (
				<>
					<Textfield {...fieldProps} testId={'number-parameter-input-' + name} />
					{generateErrorMessage(error)}
				</>
			)}
		</Field>
	);
};

export default NumberParameterInput;
