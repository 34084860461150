import React from 'react';

import { type IconProps } from '@atlaskit/icon';

import { CustomIcon } from './custom-icon';

// Because this is a logo it should be presented on white and design tokens are not used
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const ProductDiscoveryGlyph: React.FunctionComponent = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.9987 16.28C18.9987 16.28 19.018 16.66 19.018 17.1847C19.018 19.1394 17.4333 20.724 15.4787 20.724C13.524 20.724 11.9393 19.1394 11.9393 17.1847C11.9393 15.23 13.524 13.6454 15.4787 13.6454C16.0033 13.6454 16.3253 13.6647 16.3253 13.6647V6.74736C16.046 6.72469 15.764 6.71069 15.4787 6.71069C9.69201 6.71069 5.00067 11.402 5.00067 17.1887C5.00067 22.9754 9.69201 27.6667 15.4787 27.6667C21.2653 27.6667 25.9567 22.9754 25.9567 17.1887C25.9567 16.8554 25.9507 16.624 25.9233 16.2807H18.9987V16.28Z"
			fill="url(#paint0_linear_1522_5711)"
		/>
		<path
			d="M17.9233 5.6767C20.8313 6.4627 23.7393 6.4627 26.6473 5.6767C26.8573 5.62003 27.046 5.8087 26.9893 6.0187C26.2033 8.9267 26.2033 11.8347 26.9893 14.7427C27.046 14.9527 26.8573 15.1414 26.6473 15.0847C23.7393 14.2987 20.8313 14.2987 17.9233 15.0847C17.7133 15.1414 17.5247 14.9527 17.5813 14.7427C18.3673 11.8347 18.3673 8.9267 17.5813 6.0187C17.5247 5.8087 17.7133 5.62003 17.9233 5.6767Z"
			fill="#1D7AFC"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_1522_5711"
				x1="14.178"
				y1="18.4887"
				x2="21.4387"
				y2="11.228"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#1D7AFC" />
				<stop offset="1" stopColor="#0055CC" />
			</linearGradient>
		</defs>
	</svg>
);

export const ProductDiscoveryIcon: React.FunctionComponent<IconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <ProductDiscoveryGlyph />} />
);
