import React, { useState, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import type { TriggerProps } from '@atlaskit/popup';
import { SimpleTag as Tag } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';

type Props =
	| JsonComponentProps<'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes'>
	| JsonComponentProps<'com.atlassian.jira.plugin.system.customfieldtypes:multiselect'>;

const MultiValueField = (props: Props) => {
	const {
		dataSelectorProps: { value: items },
		tableInjectedProps: { fieldName, fieldId },
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const expandedView = useMemo(
		() =>
			items.map(({ value }, index) => <Tag key={`multi-value-${fieldId}-${index}`} text={value} />),
		[items, fieldId],
	);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const onToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [setIsOpen, isOpen]);

	const getContent = () => (
		<PopUpContent>
			<TagGroup>{expandedView}</TagGroup>
		</PopUpContent>
	);

	const getTrigger = (triggerProps: TriggerProps) => (
		<Button {...triggerProps} appearance="link" isSelected={isOpen} onClick={onToggle}>
			{`${items.length} ${fieldName}`}
		</Button>
	);

	if (!items || items.length === 0) {
		return null;
	}

	if (items.length <= 3) {
		return (
			<Container>
				<Content>
					<TagGroup>{expandedView}</TagGroup>
				</Content>
			</Container>
		);
	}

	return (
		<Container>
			<Content>
				<Popup
					isOpen={isOpen}
					onClose={onClose}
					placement="bottom-start"
					messageId="issue-table.common.fields.multi-value-field.popup"
					messageType="transactional"
					content={getContent}
					trigger={getTrigger}
				/>
			</Content>
		</Container>
	);
};

export default MultiValueField;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const PopUpContent = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 40}px`,
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Content = styled.div({
	paddingTop: token('space.100'),
	paddingRight: token('space.300'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	overflowWrap: 'break-word',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Container = styled.div({
	overflow: 'auto',
	cursor: 'pointer',
});
