// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { withTheme } from '../../../app/context/theme-context/index.tsx';
import type { ColumnId } from '../../../model/columns/index.tsx';
import type { CompiledTheme } from '../../../model/themes/index.tsx';

type StyleProps = {
	hasScrolled: boolean;
	leftShadowVisible: boolean;
	rightShadowVisible: boolean;
	theme: CompiledTheme;
	width: number;
};
const getShadowWidth = ({ width, leftShadowVisible, rightShadowVisible }: StyleProps) =>
	width + (leftShadowVisible ? 0 : 8) + (rightShadowVisible ? 0 : 8);
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const HeaderShadow = styled.div<StyleProps>({
	position: 'absolute',
	top: token('space.negative.100'),
	right: token('space.0'),
	bottom: token('space.negative.100'),
	left: token('space.0'),
	height: token('space.150'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${getShadowWidth(props)}px`,
});

const getMargin = (hasScrolled: boolean, isShadowVisible: boolean) =>
	8 * marginMultiplier(hasScrolled, isShadowVisible);

const marginMultiplier = (hasScrolled: boolean, isShadowVisible: boolean) => {
	if (isShadowVisible) return 0;
	if (hasScrolled) return -1;
	return 1;
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const InnerWrapper = styled.div<StyleProps>({
	height: 'auto',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ hasScrolled, leftShadowVisible }) =>
		`${getMargin(hasScrolled, leftShadowVisible) * -1}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ hasScrolled, rightShadowVisible }) =>
		`${getMargin(hasScrolled, rightShadowVisible) * -1}px`,
	overflowY: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Wrapper = styled.div<StyleProps>({
	height: 'auto',
	borderBottom: `${token('space.025')} solid ${token('color.border')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ hasScrolled, leftShadowVisible }) =>
		`${getMargin(hasScrolled, leftShadowVisible)}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ hasScrolled, rightShadowVisible }) =>
		`${getMargin(hasScrolled, rightShadowVisible)}px`,
	paddingBottom: token('space.050'),
	paddingTop: token('space.100'),
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ShadowWrapper = styled.div<StyleProps>({
	position: 'absolute',
	right: token('space.0'),
	bottom: token('space.negative.150'),
	left: token('space.0'),
	overflow: 'hidden',
	height: token('space.150'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${getShadowWidth(props)}px`,
	zIndex: 1,
	pointerEvents: 'none',
});

type Props = {
	coreColumnIds: ColumnId[];
	additionalColumnIds: ColumnId[];
	isColumnConfigurationVisible: boolean;
	hasVerticallyScrolled: boolean;
	tableHasLeftShadow: boolean;
	tableHasRightShadow: boolean;
	bottomShadowWidth: number;
	isAutoRowHeight: boolean;
	theme: CompiledTheme;
	setHeaderHeight: (height: number) => void;
	AdditionalColumnsContainer: ComponentType<{
		children: ReactNode;
	}>;
	Column: ComponentType<{
		id: ColumnId;
	}>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ColumnConfiguration: ComponentType<Record<any, any>>;
};

// eslint-disable-next-line jira/react/no-class-components
class ColumnHeaders extends PureComponent<Props> {
	componentDidMount() {
		if (this.props.isAutoRowHeight && this.ref) {
			this.props.setHeaderHeight(Math.ceil(this.ref.getBoundingClientRect().height));
		}
	}

	componentDidUpdate() {
		if (this.props.isAutoRowHeight && this.ref) {
			this.props.setHeaderHeight(Math.ceil(this.ref.getBoundingClientRect().height));
		}
	}

	setRef = (el: HTMLElement | null) => {
		this.ref = el;
	};

	// @ts-expect-error - TS2564 - Property 'ref' has no initializer and is not definitely assigned in the constructor.
	ref: HTMLElement | null;

	renderCoreColumns() {
		const { coreColumnIds, Column } = this.props;
		return coreColumnIds.map((columnId) => <Column key={columnId} id={columnId} />);
	}

	renderAdditionalColumns() {
		const { additionalColumnIds, AdditionalColumnsContainer, Column } = this.props;

		return (
			<AdditionalColumnsContainer>
				{additionalColumnIds.map((columnId) => (
					<Column key={columnId} id={columnId} />
				))}
			</AdditionalColumnsContainer>
		);
	}

	render() {
		const {
			additionalColumnIds,
			bottomShadowWidth,
			isColumnConfigurationVisible,
			hasVerticallyScrolled,
			tableHasLeftShadow,
			tableHasRightShadow,
			ColumnConfiguration,
			theme,
		} = this.props;

		const styledCompProps = {
			hasScrolled: hasVerticallyScrolled,
			leftShadowVisible: tableHasLeftShadow,
			rightShadowVisible: tableHasRightShadow,
			width: bottomShadowWidth,
		};

		return (
			<Wrapper
				ref={this.setRef}
				{...styledCompProps}
				theme={theme}
				{...(fg('jsm_virtual_table_a11y') && { role: 'row' })}
			>
				<InnerWrapper {...styledCompProps} theme={theme}>
					{this.renderCoreColumns()}
					{additionalColumnIds.length > 0 && this.renderAdditionalColumns()}
					{isColumnConfigurationVisible && <ColumnConfiguration />}
					{theme.header.hasShadow && hasVerticallyScrolled && (
						<ShadowWrapper {...styledCompProps} theme={theme}>
							<HeaderShadow {...styledCompProps} theme={theme} />
						</ShadowWrapper>
					)}
				</InnerWrapper>
			</Wrapper>
		);
	}
}

export default withTheme(ColumnHeaders);
