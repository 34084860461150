export const EXPERIENCE_PENDING = 'pending' as const;
export const EXPERIENCE_FAILED = 'failed' as const;
export const EXPERIENCE_SUCCEEDED = 'succeeded' as const;

export type ExperienceResult =
	| typeof EXPERIENCE_PENDING
	| typeof EXPERIENCE_FAILED
	| typeof EXPERIENCE_SUCCEEDED;

export const JSM_VIEWS_IN_QUEUES_AGENT_VIEW_CHANGE_BOARDING_SEEN_STATUS =
	'jsm.views-in-queues-agent-view-change-boarding-seen-status';
export const PACKAGE_NAME = 'servicedesk.queues.agent-view';
export const TEAM_NAME = 'jsd-shield';
