import React, { useMemo, useState } from 'react';

import { ModalTransition } from '@atlaskit/modal-dialog';
import { AutomationIntlMessagesProvider } from '@atlassian/automation-ui-commons';
import { ResourceIdentifier } from '@atlassian/cs-ari';

import { type Environment } from '../../common/types';
import { locales } from '../../common/utils/i18n';
import { type PaginatedApiResponse } from '../../common/utils/types';
import { TemplatesClient } from '../../services/template';
import {
	type CreateRuleFromTemplatePayload,
	type Template,
	type TemplateSearchQuery,
} from '../../services/template/types';

import { type TargetRuleCreation } from './template-parameters';
import TemplateParameterForm from './template-parameters/main';

export type TemplatesContainerProps = {
	env: Environment;
	site: string;
	query: TemplateSearchQuery;
	limit?: number;
	onCreateFailure?: (templateId: string, error: string) => void;
	onCreateSuccess?: (templateId: string, ruleId: number) => void;
	onFetchSuccess?: (templates: Template[]) => void;
	onFetchFailed?: (error: string) => void;

	children: (renderedProps: TemplatesContainerRenderedProps) => React.ReactElement;
};
export type TemplatesContainerRenderedProps = {
	isLoading: boolean;
	isDisabled: boolean;
	atStartBoundary: boolean;
	atEndBoundary: boolean;
	error: string | null;
	templates: Template[] | null;
	fetchPrev: () => Promise<void>;
	fetchCurrent: () => Promise<void>;
	fetchNext: () => Promise<void>;
	createRuleOrShowDialog: (templateId: string, ruleHome: string) => Promise<void>;
};
/**
 * Higher-Order-Component for communicating with the Automation Public REST API's Templates domain. You can read more about this
 * API [here](https://developer.atlassian.com/cloud/automation/rest/api-group-rest/#api-rest-v1-template-search-get)
 */
const TemplatesContainer = ({
	env,
	site,
	query,
	limit,
	onCreateFailure,
	onCreateSuccess,
	onFetchSuccess,
	onFetchFailed,
	children,
}: TemplatesContainerProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [lastResponse, setLastResponse] = useState<PaginatedApiResponse<Template> | null>(null);
	const [atStartBoundary, setAtStartBoundary] = useState<boolean>(false);
	const [atEndBoundary, setAtEndBoundary] = useState<boolean>(false);
	// TODO likely combine these
	const [ruleCreation, setRuleCreation] = useState<TargetRuleCreation | null>(null);

	const templatesClient = useMemo(() => new TemplatesClient(env, site), [env, site]);
	/**
	 * Perform a fetch to the API. This helper will handle the loading and error state. The fetcher
	 * argument will either be the initial search query, or a fetcher in a page direction.
	 */
	const _handleFetch = async (fetcher: () => Promise<PaginatedApiResponse<Template> | null>) => {
		setIsLoading(true);
		try {
			// Note safe cast here as we bail the try block if resolving the type ApiErrorResponse
			const result = (await fetcher()) as PaginatedApiResponse<Template> | null;
			// Once we've hit the last page (i.e. the client didn't bother with another request),
			// Don't update the result.
			if (result === null) {
				return;
			}
			// If the response from the client does contain a result (we didn't attempt to a
			// page that doesn't exist), then update the boundary flags according to the
			// presence of the previous and next links.
			setAtStartBoundary(false);
			setAtEndBoundary(false);
			if (!result.links.next) {
				setAtEndBoundary(true);
			}
			if (!result.links.prev) {
				setAtStartBoundary(true);
			}
			// Set the result only if there is a result.
			setLastResponse(result);
			onFetchSuccess?.(result.data);
		} catch (e: any) {
			setError((e as Error).message);
			onFetchFailed?.((e as Error).message);
		} finally {
			setIsLoading(false);
		}
	};
	const _handleCreate = async (
		templateId: string,
		ruleHome: string,
		parameters?: CreateRuleFromTemplatePayload['parameters'],
	) => {
		setIsDisabled(true);
		try {
			const response = await templatesClient.createRuleFromTemplate(
				templateId,
				ruleHome,
				parameters,
			);
			onCreateSuccess?.(templateId, response.ruleId);
		} catch (e) {
			const errorMessage = (e as Error).message;
			onCreateFailure?.(templateId, errorMessage);
		} finally {
			setIsDisabled(false);
			setRuleCreation(null);
		}
	};
	/**
	 * When paging forwards, if no prior request has been made a consequently no links exist to follow,
	 * then perform the initial request. Otherwise, follow the next link (if present).
	 */
	const fetchNext = async () => {
		if (atEndBoundary) {
			return;
		}
		if (!lastResponse) {
			return _handleFetch(() => templatesClient.searchTemplatesByFilters(query, limit));
		}
		return _handleFetch(() => templatesClient.getNextSearchPageResults(lastResponse, limit));
	};
	const fetchPrev = async () => {
		if (!lastResponse || atStartBoundary) {
			return;
		}
		return _handleFetch(() => templatesClient.getPrevSearchPageResults(lastResponse, limit));
	};
	const fetchCurrent = async () => {
		if (!lastResponse) {
			return;
		}
		return _handleFetch(() => templatesClient.getCurrentSearchPageResults(lastResponse, limit));
	};
	const createRuleOrShowDialog = async (templateId: string, ruleHome: string) => {
		if (!lastResponse) {
			return onCreateFailure?.(templateId, 'Cannot find template with id ' + templateId);
		}
		const maybeTemplate = lastResponse.data.find((template) => template.id === templateId);
		if (!maybeTemplate) {
			return onCreateFailure?.(templateId, 'Cannot find template with id ' + templateId);
		}
		try {
			ResourceIdentifier.parse(ruleHome);
		} catch (e) {
			return onCreateFailure?.(templateId, 'Provided ruleHome is not a valid ARI: ' + ruleHome);
		}
		// If the template has parameters, handle this with the form. If not, create a rule.
		if (maybeTemplate.parameters.length !== 0) {
			setRuleCreation({ template: maybeTemplate, ruleHome });
			return;
		}
		return _handleCreate(templateId, ruleHome);
	};
	return (
		<AutomationIntlMessagesProvider locales={locales}>
			{children({
				isLoading,
				isDisabled,
				atStartBoundary,
				atEndBoundary,
				error,
				// Normalise to null here as the templates variable is set but has no value
				templates: lastResponse?.data ?? null,
				fetchPrev,
				fetchCurrent,
				fetchNext,
				createRuleOrShowDialog,
			})}
			<ModalTransition>
				{ruleCreation && (
					<TemplateParameterForm
						targetRuleCreation={ruleCreation}
						clearSelectedTemplate={() => setRuleCreation(null)}
						createRule={(parameters) =>
							_handleCreate(ruleCreation.template.id, ruleCreation.ruleHome, parameters)
						}
					/>
				)}
			</ModalTransition>
		</AutomationIntlMessagesProvider>
	);
};
export default TemplatesContainer;
