import React from 'react';
import { styled } from '@compiled/react';
import type { EntryPointProps } from 'react-relay';
import Heading from '@atlaskit/heading';
import { MenuGroup, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { DeepLinks } from './deep-links/index.tsx';
import messages from './messages.tsx';
import { TemplateRules } from './template-rules/index.tsx';

export type Props = {
	onClick: () => void;
};

export const MenuContent = ({ onClick }: Props) => {
	const intl = useIntl();
	return (
		<MenuContentWrapper>
			<Heading size="small" as="h5">
				<MenuTitle>{intl.formatMessage(messages.menuTitle)}</MenuTitle>
			</Heading>
			<MenuSubTitle>{intl.formatMessage(messages.menuSubTitle)}</MenuSubTitle>
			<MenuGroup>
				<TemplateRules onLinkClick={onClick} />
				<Section hasSeparator>
					<DeepLinks onLinkClick={onClick} />
				</Section>
			</MenuGroup>
		</MenuContentWrapper>
	);
};

const MenuContentEntryPoint = ({ props }: EntryPointProps<{}, {}, Props, {}>) => (
	<MenuContent {...props} />
);

export default MenuContentEntryPoint;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuContentWrapper = styled.div({
	width: '400px',
});

// eslint-disable-next-line @atlaskit/design-system/use-primitives, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuTitle = styled.div({
	paddingTop: token('space.300'),
	paddingRight: '0px',
	paddingBottom: token('space.100'),
	paddingLeft: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuSubTitle = styled.div({
	color: token('color.text.subtlest'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `0px ${7 * gridSize}px ${token('space.200')} ${token('space.300')}`,
});
