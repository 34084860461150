import { assoc, chain } from 'icepick';
import { fg } from '@atlassian/jira-feature-gating';
import type { AnError } from '@atlassian/jira-servicedesk-common/src/model/error/anerror/index.tsx';
import { LOCAL_STORAGE_SOURCE } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import {
	type ExperienceResult,
	EXPERIENCE_PENDING,
	EXPERIENCE_SUCCEEDED,
	EXPERIENCE_FAILED,
} from '../../../../common/constants.tsx';
import { LOAD_ISSUES_ACTION_SOURCE_PAGE } from '../../../../model/index.tsx';
import {
	type ExperienceTrackingResetAction,
	EXPERIENCE_TRACKING_RESET,
} from '../../../actions/experience-tracking/index.tsx';
import {
	type LoadIssuesFailureAction,
	LOAD_ISSUES_FAILURE,
	LOAD_ISSUES_SUCCESS,
	type LoadIssuesSuccessAction,
} from '../../../actions/issue/index.tsx';
import {
	LOAD_PAGE_DATA_FAILURE,
	RESET_STATE,
	type ResetStateAction,
	type LoadPageDataFailureAction,
} from '../../../actions/page/index.tsx';

export type Actions =
	| ExperienceTrackingResetAction
	| LoadIssuesFailureAction
	| LoadPageDataFailureAction
	| ResetStateAction
	| LoadIssuesSuccessAction;

export type ExperienceTrackingState = {
	initialDataLoadStatus: ExperienceResult;
	hasLoadedIssueDataFromCache: boolean;
	errorStatusCode?: number | undefined;
	errorKey?: string;
	errorMessages?: { errorMessage: string }[] | undefined;
	traceId?: string;
};

const initialExperienceTrackingState = {
	initialDataLoadStatus: EXPERIENCE_PENDING,
	hasLoadedIssueDataFromCache: false,
} as const;

const ERROR_STATUS_CODE_ALLOWLIST_OLD: number[] = [403, 401, 429];
const ERROR_STATUS_CODE_ALLOWLIST: number[] = [400, 401, 403, 429];

// We don't want to cover all 400 errors because some might be a bug, in most cases issuelist shouldn't return 400.
const NON_EXPERIENCE_FAILURE_REASON_KEY: string[] = [
	'sd.issue.search.invalid.project',
	'Invalid category',
];

const isStatusCodeAnExperienceFailureOld = (
	statusCode?: number,
): boolean => // Void or 0 statusCode indicates connectivity problem and generated by client side
	// This indicates that browser has no connectivity
	// @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
	Boolean(statusCode) && !ERROR_STATUS_CODE_ALLOWLIST_OLD.includes(statusCode);

const isStatusCodeAnExperienceFailure = (
	statusCode?: number,
): boolean => // Void or 0 statusCode indicates connectivity problem and generated by client side
	// This indicates that browser has no connectivity
	// @ts-expect-error - TS2345 - Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
	Boolean(statusCode) && !ERROR_STATUS_CODE_ALLOWLIST.includes(statusCode);

const isProjectOrCategoryNotFound = (error: AnError, statusCode?: number): boolean =>
	statusCode === 400 && NON_EXPERIENCE_FAILURE_REASON_KEY.includes(error?.reasonKey);

const isAnExperienceFailure = (error: AnError, statusCode?: number): boolean => {
	if (fg('exclude_issue_list_400_from_view_queue')) {
		return isStatusCodeAnExperienceFailure(statusCode);
	}
	return (
		isStatusCodeAnExperienceFailureOld(statusCode) &&
		!isProjectOrCategoryNotFound(error, statusCode)
	);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: ExperienceTrackingState = initialExperienceTrackingState,
	action: Actions,
) => {
	switch (action.type) {
		case LOAD_ISSUES_SUCCESS: {
			let newStateChain = chain(state);
			if (action.payload.loadedIssuesResponse.source === LOCAL_STORAGE_SOURCE) {
				newStateChain = newStateChain.assoc('hasLoadedIssueDataFromCache', true);
			} else if (action.payload.source === LOAD_ISSUES_ACTION_SOURCE_PAGE) {
				// Do no set status to true if the payload is from localstorage, that doesn't count
				newStateChain = newStateChain.assoc('initialDataLoadStatus', EXPERIENCE_SUCCEEDED);
			}
			return newStateChain.value();
		}
		case LOAD_ISSUES_FAILURE: {
			if (action.payload.source === LOAD_ISSUES_ACTION_SOURCE_PAGE) {
				if (isAnExperienceFailure(action.payload.error, action.payload.statusCode)) {
					if (fg('adding-more-data-for-viewqueue-and-viewnav-event')) {
						return {
							...state,
							initialDataLoadStatus: EXPERIENCE_FAILED,
							errorStatusCode: action.payload.statusCode,
							errorMessages: action.payload.error?.errors,
							errorKey: action.payload.error?.reasonKey,
							traceId: action.payload.traceId,
						};
					}
					return {
						...state,
						initialDataLoadStatus: EXPERIENCE_FAILED,
						errorStatusCode: action.payload.statusCode,
					};
				}
				return assoc(state, 'initialDataLoadStatus', EXPERIENCE_SUCCEEDED);
			}
			return state;
		}
		case LOAD_PAGE_DATA_FAILURE: {
			if (isStatusCodeAnExperienceFailure(action.payload.statusCode)) {
				return assoc(state, 'initialDataLoadStatus', EXPERIENCE_FAILED);
			}
			return state;
		}
		case RESET_STATE:
		case EXPERIENCE_TRACKING_RESET: {
			return initialExperienceTrackingState;
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
