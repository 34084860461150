import React, { type ComponentType, useState } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	ASSIGNED_ISSUE_ACTION,
	VIEW_PROFILE_ACTION,
} from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import type { ProfileCardProps } from '@atlassian/jira-profilecard-next/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { UserProps } from '../../../../../model/fields/json-fields/common/types.tsx';
import messages from '../user-select/messages.tsx';

type Props = {
	isLastColumn: boolean;
	isButtonDisabled: boolean;
	assignee: UserProps | undefined;
	overrideDisabledAppearance: boolean;
	onClick: () => void;
	onMouseEnter: () => void;
	intl: IntlShape;
	ProfileCard?: ComponentType<ProfileCardProps>;
	accountId?: string;
};

const AssigneeButton = ({
	isLastColumn,
	assignee,
	intl,
	overrideDisabledAppearance,
	accountId,
	ProfileCard,
	...rest
}: Props) => {
	const [isHover, setIsHover] = useState<boolean>(false);
	const setIsHoverToTrue = () => setIsHover(true);
	const setIsHoverToFalse = () => setIsHover(false);
	const assigneeName = assignee
		? assignee.displayName || assignee.emailAddress
		: intl.formatMessage(messages.unassigned);
	const isFromServerSide = __SERVER__ && fg('issue_list_ssr_disable_sort_and_inline_edit');
	return (
		<Box
			xcss={wrapperBaseStyles}
			paddingInlineEnd={isLastColumn ? 'space.100' : 'space.0'}
			onMouseEnter={setIsHoverToTrue}
			onMouseLeave={setIsHoverToFalse}
		>
			<StyledButton
				{...rest}
				isHovered={isHover}
				aria-label={`${assigneeName} - edit Assignee`}
				isFromServerSide={isFromServerSide}
			>
				{accountId && ProfileCard ? (
					<ProfileCard accountId={accountId} actions={[VIEW_PROFILE_ACTION, ASSIGNED_ISSUE_ACTION]}>
						<Avatar
							src={assignee && assignee.avatarUrl}
							size="small"
							borderColor="transparent"
							isDisabled={!overrideDisabledAppearance && rest.isButtonDisabled}
						/>
					</ProfileCard>
				) : (
					<Avatar
						src={assignee && assignee.avatarUrl}
						size="small"
						borderColor="transparent"
						isDisabled={!overrideDisabledAppearance && rest.isButtonDisabled}
					/>
				)}

				<Text>{assigneeName}</Text>
			</StyledButton>
		</Box>
	);
};

AssigneeButton.defaultProps = {
	isLastColumn: false,
	isButtonDisabled: false,
	onClick: noop,
	onMouseEnter: noop,
	overrideDisabledAppearance: false,
};

export default AssigneeButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton = styled.button<{
	isButtonDisabled: boolean;
	isHovered?: boolean;
	isFromServerSide?: boolean;
}>({
	display: 'flex',
	gap: token('space.100'),
	flex: 1,
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isButtonDisabled, isHovered }) =>
		!isButtonDisabled && isHovered ? token('color.background.neutral.hovered') : 'transparent',
	border: 0,
	borderRadius: 3,
	paddingTop: 0,
	paddingRight: token('space.050'),
	paddingBottom: 0,
	paddingLeft: token('space.050'),
	width: '100%',
	height: token('space.400'),
	overflowX: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isButtonDisabled, isFromServerSide }) => {
		if (isFromServerSide) {
			return {
				cursor: 'not-allowed',
			};
		}

		return isButtonDisabled ? { cursor: 'default' } : 'pointer';
	},
	/* CSS reset to Atlaskit defaults */
	color: 'inherit',
	outline: 'none',
});

const wrapperBaseStyles = xcss({
	paddingTop: 'space.050',
	paddingRight: 'space.0',
	paddingBottom: 'space.050',
	paddingLeft: 'space.0',
	display: 'flex',
	textAlign: 'left',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.span({
	flex: 1,
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	textAlign: 'left',
});
