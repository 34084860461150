import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { Box, Text, xcss } from '@atlaskit/primitives';
import { B500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { EmptyStateIcon } from './icons/main';
import { messages } from './messages';

const emptyStateIconStyles = xcss({
	height: `115px`,
	width: `192px`,
});

const messageStyles = xcss({
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '1.25rem',
	paddingTop: 'space.150',
	width: '17.75rem',
});

const emptyStateContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	paddingTop: 'space.100',
	paddingBottom: 'space.400',
});

type EmptyStateProps = {
	ruleLibraryUrl: string;
};

/**
 * Empty state component for the template dropdown. This component is displayed when there are no templates to show (error or empty templates list received).
 * It provides a message with a link to the rule library.
 * @param ruleLibraryUrl - URL pointing to the rule library
 */
export const TemplateEmptyState = ({ ruleLibraryUrl }: EmptyStateProps) => {
	return (
		<Box xcss={emptyStateContainerStyles}>
			<Box xcss={emptyStateIconStyles} testId="empty-state-icon">
				{<EmptyStateIcon />}
			</Box>
			<Box xcss={messageStyles}>
				<Text
					weight="regular"
					align="center"
					size="medium"
					color="color.text"
					testId="empty-state-message"
				>
					<FormattedMessage
						id={messages.emptyStateMessage.id}
						defaultMessage={messages.emptyStateMessage.defaultMessage}
						description={messages.emptyStateMessage.description}
						values={{
							link: (text: React.ReactNode[]) => (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								<a href={ruleLibraryUrl} style={{ color: token('color.link', B500) }}>
									{text}
								</a>
							),
						}}
					/>
				</Text>
			</Box>
		</Box>
	);
};
