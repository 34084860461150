import React from 'react';
import CustomThemeButton from '@atlaskit/button/custom-theme-button';
import type { CustomThemeButtonProps, ThemeProps, ThemeTokens } from '@atlaskit/button/types';
import { token } from '@atlaskit/tokens';

// It is a copy of the theme used in the AK's onboarding component!
// atlaskit/onboarding/theme
const purpleButtonTheme = {
	primary: {
		background: {
			default: {
				light: token('color.background.discovery.bold'),
				dark: token('color.background.discovery.bold'),
			},
			hover: {
				light: token('color.background.discovery.bold.hovered'),
				dark: token('color.background.discovery.bold.hovered'),
			},
			active: {
				light: token('color.background.discovery.bold.pressed'),
				dark: token('color.background.discovery.bold.pressed'),
			},
			disabled: {
				light: token('color.background.disabled'),
				dark: token('color.background.disabled'),
			},
			selected: {
				light: token('color.background.danger'),
				dark: token('color.background.danger'),
			},
			focus: {
				light: token('color.background.discovery.bold'),
				dark: token('color.background.discovery.bold'),
			},
		},
		boxShadow: {
			focus: {
				light: `${token('color.border.discovery')}`,
				dark: `${token('color.border.discovery')}`,
			},
		},
		color: {
			default: {
				light: token('color.text.inverse'),
				dark: token('color.text.inverse'),
			},
			hover: {
				light: token('color.text.inverse'),
				dark: token('color.text.inverse'),
			},
			active: {
				light: token('color.text.inverse'),
				dark: token('color.text.inverse'),
			},
			disabled: {
				light: token('color.text.inverse'),
				dark: token('color.text.inverse'),
			},
			selected: {
				light: token('color.text.inverse'),
				dark: token('color.text.inverse'),
			},
			focus: {
				light: token('color.text.inverse'),
				dark: token('color.text.inverse'),
			},
		},
		outline: {
			focus: { light: 'none', dark: 'none' },
		},
	},
	link: {
		color: {
			default: {
				light: token('color.text.discovery'),
				dark: token('color.text.discovery'),
			},
			hover: {
				light: token('color.text.discovery'),
				dark: token('color.text.discovery'),
			},
			active: {
				light: token('color.text.discovery'),
				dark: token('color.text.discovery'),
			},
			disabled: {
				light: token('color.text.disabled'),
				dark: token('color.text.disabled'),
			},
			selected: {
				light: token('color.text.discovery'),
				dark: token('color.text.discovery'),
			},
			focus: {
				light: token('color.text.discovery'),
				dark: token('color.text.discovery'),
			},
		},
		boxShadow: {
			focus: {
				light: `${token('color.border.discovery')}`,
				dark: `${token('color.border.discovery')}`,
			},
		},
		outline: {
			focus: { light: 'none', dark: 'none' },
		},
	},
	'subtle-link': {
		textDecoration: {
			hover: {
				light: `underline ${token('color.text.discovery')}`,
				dark: `underline ${token('color.text.discovery')}`,
			},
		},
		textDecorationLine: {
			active: { light: 'none', dark: 'none' },
		},
		boxShadow: {
			focus: {
				light: `${token('color.border.discovery')}`,
				dark: `${token('color.border.discovery')}`,
			},
		},
		color: {
			default: {
				light: token('color.text.subtle'),
				dark: token('color.text.subtle'),
			},
			hover: {
				light: token('color.text.subtlest'),
				dark: token('color.text.subtlest'),
			},
			active: {
				light: token('color.text.subtle'),
				dark: token('color.text.subtle'),
			},
			disabled: {
				light: token('color.text.disabled'),
				dark: token('color.text.disabled'),
			},
			selected: {
				light: token('color.text.subtle'),
				dark: token('color.text.subtle'),
			},
			focus: {
				light: token('color.text.subtle'),
				dark: token('color.text.subtle'),
			},
		},
	},
} as const;

const applyTheme =
	(customTheme: typeof purpleButtonTheme) =>
	(currentTheme: (arg1: ThemeProps) => ThemeTokens, themeProps: ThemeProps) => {
		const { buttonStyles, spinnerStyles } = currentTheme(themeProps);
		const { appearance, state, mode } = themeProps;
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '"" | Appearance' can't be used to index type '{ readonly primary: { readonly background: { readonly default: { readonly light: "#6554C0"; readonly dark: "#6554C0"; }; readonly hover: { readonly light: "#8777D9"; readonly dark: "#8777D9"; }; readonly active: { readonly light: "#403294"; readonly dark: "#403294"; }; readonly disabled: { ...; }; readonly selected:...'.
		const customThemeAppearance = customTheme[`${appearance || ''}`]; // Trying to find custom theme rules for the provided appearance.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let customStyles: Record<string, any> = {};
		if (customThemeAppearance) {
			// Extracting rules for the provided state.
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			customStyles = Object.keys(customThemeAppearance).reduce<Record<string, any>>((acc, key) => {
				const value = customThemeAppearance[key];
				// @ts-expect-error - Type 'undefined' cannot be used as an index type.
				if (value[state]) acc[key] = value[state][mode];
				return acc;
			}, {});
		}
		// Returning a theme that is a combination of default values and the custom button theme rules.
		return {
			buttonStyles: {
				...buttonStyles,
				...customStyles,
			},
			spinnerStyles,
		};
	};

export const PurpleButton = (props: CustomThemeButtonProps) => (
	<CustomThemeButton theme={applyTheme(purpleButtonTheme)} {...props} />
);
