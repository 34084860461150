// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import NodeIcon from '@atlaskit/icon/core/node';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { defineMessages, injectIntl } from '@atlassian/jira-intl';
import { ellipsis } from '../../../../../../../../common/styles/index.tsx';
import { itemTypes, type ItemType } from '../item-types.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Description = styled.div<{ shouldIndent: boolean }>({
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingLeft: ({ shouldIndent }) => (shouldIndent ? `${gridSize * 3}px` : '0'),
	boxSizing: token('color.text.subtlest'),
	font: token('font.body.UNSAFE_small'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const LozengeWrapper = styled.div({
	marginLeft: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	flexShrink: 0,
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Title = styled.div({
	flexGrow: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	...ellipsis,
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Content = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexGrow: 1,
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-unsafe-values
const avatarSize = `${gridSize * 2}px`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Avatar = styled.img({
	flexShrink: 0,
	width: avatarSize,
	height: avatarSize,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	marginRight: token('space.100'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const CheckIconWrapper = styled.div<{ isDefaultSelected: boolean }>({
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isDefaultSelected }) => (isDefaultSelected ? 0.5 : 1),
});
type WrapperProps = {
	hasDivider: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	type: any;
	isFocused: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	appearance: any;
	isDisabled: boolean;
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Wrapper = styled.div<WrapperProps>({
	userSelect: 'none',
	paddingTop: token('space.075'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.150'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderBottom: ({ hasDivider }) => (hasDivider ? `1px solid ${token('color.border')}` : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ type, appearance: { color } }) =>
		color ||
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		(type === itemTypes.HEADING ? token('color.text.subtle') : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: ({ type }) => (type === itemTypes.HEADING ? token('font.body.UNSAFE_small') : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ type, isDisabled }) =>
		// eslint-disable-next-line no-nested-ternary -- https://compiledcssinjs.com/docs/limitations#returning-static-values-from-a-dynamic-property
		isDisabled
			? 'not-allowed'
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				type === itemTypes.HEADING
				? 'pointer'
				: undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: ({ isFocused }) => (isFocused ? colors.N20 : undefined),
});

const shouldShowSelect = (type: ItemType) =>
	[itemTypes.CHECKBOX, itemTypes.RADIO].indexOf(type) !== -1;

const messages = defineMessages({
	selected: {
		id: 'virtual-table.table.header.column-configuration.list.flyout.multi-select-list.list.item.selected',
		defaultMessage: 'Item selected',
		description: '',
	},
});

const getAriaRole = (type: ItemType) => {
	if (type === itemTypes.CHECKBOX) {
		return 'menuitemcheckbox';
	}

	if (type === itemTypes.RADIO) {
		return 'menuitemradio';
	}

	return 'menuitem';
};

const getAriaChecked = (isSelected: boolean, isDefaultSelected: boolean) =>
	isSelected || isDefaultSelected ? 'true' : 'false';

const getAriaProps = (type: ItemType, isSelected: boolean, isDefaultSelected: boolean) => ({
	role: getAriaRole(type),
	tabIndex: 0,
	...(shouldShowSelect(type) && {
		'aria-checked': getAriaChecked(isSelected, isDefaultSelected),
	}),
});

// eslint-disable-next-line jira/react/no-class-components
export class MultiSelectItem extends PureComponent<Props> {
	static defaultProps = {
		type: itemTypes.DEFAULT,
		description: undefined,
		lozenge: undefined,
		avatarUrl: undefined,
		appearance: {},
		isFocused: false,
		isSelected: false,
		isDefaultSelected: false,
		isDisabled: false,
		hasDivider: false,
		onHover: noop,
		onSelect: noop,
	};

	onClick = () => {
		const { isDisabled, onSelect } = this.props;

		if (!isDisabled) {
			onSelect();
		}
	};

	renderContent() {
		const { avatarUrl, content, lozenge, type } = this.props;

		return (
			<Content>
				{shouldShowSelect(type) && this.renderSelect()}
				{avatarUrl &&
					(fg('jsm_queue_a11y_improvement') ? (
						<Avatar src={avatarUrl} alt="" />
					) : (
						<Avatar src={avatarUrl} />
					))}
				<Title>{content}</Title>
				{lozenge && <LozengeWrapper>{lozenge}</LozengeWrapper>}
			</Content>
		);
	}

	renderSelect() {
		const {
			type,
			isSelected,
			isDefaultSelected,
			intl: { formatMessage },
		} = this.props;

		return (
			<CheckIconWrapper isDefaultSelected={isDefaultSelected}>
				{(isSelected || isDefaultSelected) &&
					(type === itemTypes.CHECKBOX ? (
						<CheckIcon LEGACY_size="small" label={formatMessage(messages.selected)} />
					) : (
						<NodeIcon color="currentColor" label={formatMessage(messages.selected)} />
					))}
			</CheckIconWrapper>
		);
	}

	renderDescription() {
		const { avatarUrl, description, type } = this.props;

		// @ts-expect-error - TS2365 - Operator '+' cannot be applied to types 'boolean' and 'boolean'.
		const shouldIndent = shouldShowSelect(type) + !!avatarUrl;

		return <Description shouldIndent={shouldIndent}>{description}</Description>;
	}

	render() {
		const {
			appearance,
			description,
			hasDivider,
			onHover,
			isFocused,
			isDisabled,
			type,
			id,
			isSelected,
			isDefaultSelected,
		} = this.props;

		const ariaProps = fg('jsm_queue_a11y_improvement')
			? getAriaProps(type, isSelected, isDefaultSelected)
			: {};

		return (
			<Wrapper
				{...(fg('jsm_queue_a11y_improvement') && ariaProps)}
				type={type}
				onClick={this.onClick}
				isFocused={isFocused}
				isDisabled={isDisabled}
				onMouseMove={onHover}
				hasDivider={hasDivider}
				appearance={appearance}
				data-testid={`common.components.virtual-table.table.header.column-configuration.list.flyout.multi-select-list.list.item.wrapper-${id}`}
			>
				{this.renderContent()}
				{description && this.renderDescription()}
			</Wrapper>
		);
	}
}

export default injectIntl(MultiSelectItem);
