import { useCallback, useEffect, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME, TEAM_NAME } from '../../../common/constants.tsx';

const getTemplateCategory = (
	teamType: string | undefined,
	isChangeManagementPracticeEnabled: boolean | undefined,
): string => {
	const map: { [key: string]: string } = {
		businessOperations: 'jsm.team-type.business',
		design: 'jsm.team-type.design',
		hr: 'jsm.team-type.human-resources',
		it: 'jsm.team-type.information-technology',
	};

	if (teamType && map[teamType]) {
		return map[teamType];
	}

	return isChangeManagementPracticeEnabled
		? 'jira-service-management.change-management'
		: 'jsm.default';
};

export type State = {
	data: string[];
	loading: boolean;
};

export const useTemplateCategories = (
	projectId: number,
	isChangeManagementPracticeEnabled: boolean,
): State => {
	const [state, setState] = useState<State>({ data: [], loading: true });
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fetchTeamType = useCallback(async () => {
		try {
			const { teamType } = await fetchJson(
				`/rest/servicedesk/latest/project/properties/${projectId}/teamType`,
			);
			fireTrackAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.fetchTeamType succeeded`);
			setState({
				data: [getTemplateCategory(teamType, isChangeManagementPracticeEnabled)],
				loading: false,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			!isClientFetchError(error) &&
				fireErrorAnalytics({
					error,
					meta: {
						id: 'fetchTeamType',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			setState({
				data: [getTemplateCategory(undefined, isChangeManagementPracticeEnabled)],
				loading: false,
			});
		}
	}, [createAnalyticsEvent, isChangeManagementPracticeEnabled, projectId]);

	useEffect(() => {
		fetchTeamType();
	}, [fetchTeamType]);

	return state;
};
