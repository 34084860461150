import { Environment, type Product } from '../types';

import { extractResourceOwnerAndCloudIdFromSiteAri } from './ari';
import { getAutomationStack } from './stack-resolver';
import type { ApiErrorResponse, PaginatedApiResponse } from './types';
/**
 * AutomationClient - class for communicating with the {@link https://developer.atlassian.com/cloud/automation/rest/api-group-rest/#api-group-rest Automation Public REST API}
 * Class is abstract is not for standalone use. The base path used for requests is built from a provided {@param env} and {@param site}. If a devStack is necessary
 * Because the environment is a development environment, the client attempts to resolve this from your local web server running Automation. If not, it will
 * fallback to local storage.
 */
export default abstract class AutomationClient {
	static #API_VERSION = 'v1';
	#env: Environment;
	#site: string;
	#basePath: string | null = null;
	static #DEFAULT_HEADERS: Record<string, string> = { 'Content-Type': 'application/json' };
	/**
	 * @param env - the target environment that the {@link AutomationClient} should use.
	 * @param site - the site, in the form of a site ARI as string, that the {@link AutomationClient} should use.
	 */
	protected constructor(env: Environment, site: string) {
		this.#env = env;
		this.#site = site;
	}
	/**
	 * Get basePath. Note that this will only be available after a request has been made, otherwise will be set as `null`.
	 */
	get basePath() {
		return this.#basePath;
	}
	/**
	 * Issue request to the Automation Public REST API.
	 * Note that if no request has been made using the client yet, calling this method will first attempt to resolve the basepath
	 * (`async`).
	 * @param endpoint - the endpoint the request should be made to. This will be appended to the base path generated
	 * by the client
	 * @param method - Specify the HTTP method to use for the request
	 * @param body - Optionally supply JSON body to be serialised and attached as the request body
	 * @param headers - Optionally supply key value set to be attached as headers.
	 * @returns the awaited Promise that will resolve the JSON data from the API.
	 * @throws Error - if the underlying {@link Response} failed
	 */
	protected async makeRequest<T>(
		endpoint: string,
		method: RequestInit['method'],
		body?: Record<string, any>,
		headers?: Record<string, string>,
	): Promise<T> {
		if (!this.basePath) {
			await this.#resolveBasePath();
		}
		return this.#doFetch<T>(this.#basePath + endpoint, method, body, headers);
	}
	/**
	 * Issue request with known pagination to the Automation Public REST API. This is particularly useful for search-like requests
	 * as the response payload is already known to be jsonAPI compliant
	 * @param endpoint - the endpoint the request should be made to. This will be appended to the base path generated
	 * by the client
	 * @param method - Specify the HTTP method to use for the request
	 * @param body - Optionally supply JSON body to be serialised and attached as the request body
	 * @param headers - Optionally supply key value set to be attached as headers.
	 * @returns the awaited Promise that will resolve the JSON data from the API.
	 * @throws Error - if the underlying {@link Response} failed
	 */
	protected async makePaginatedRequest<T>(
		endpoint: string,
		method: RequestInit['method'],
		body?: Record<string, any>,
		headers?: Record<string, string>,
	): Promise<PaginatedApiResponse<T>> {
		if (!this.basePath) {
			await this.#resolveBasePath();
		}
		return this.#doFetch<PaginatedApiResponse<T>>(this.#basePath + endpoint, method, body, headers);
	}
	/**
	 * Attempt to page to next results page (GET), given a previous result.
	 * @param previousResult - the previously requested paginated response to attempt to follow forwards
	 * @param limit - an optional limit to size the next page of results
	 * @returns If the previous result contains no next link, return {@code null}. Else, return the next page of results.
	 * @throws Error - if the underlying {@link Response} failed
	 */
	async pageForwards<T>(
		previousResult: PaginatedApiResponse<T>,
		limit?: number,
	): Promise<PaginatedApiResponse<T> | null> {
		const nextCursor = previousResult.links.next;
		if (!nextCursor) {
			return null;
		}
		return this.#followCursor<T>(nextCursor, limit);
	}
	/**
	 * Attempt to page to prev results page (GET), given a previous result.
	 * @param previousResult - the previously requested paginated response to attempt to follow backwards
	 * @param limit - an optional limit to size the previous page of results
	 * @returns If the previous result contains no prev link, return {@code null}. Else, return the prev page of results.
	 * @throws Error - if the underlying {@link Response} failed
	 */
	async pageBackwards<T>(
		previousResult: PaginatedApiResponse<T>,
		limit?: number,
	): Promise<PaginatedApiResponse<T> | null> {
		const prevCursor = previousResult.links.prev;
		if (!prevCursor) {
			return null;
		}
		return this.#followCursor<T>(prevCursor, limit);
	}
	/**
	 * Attempt to page to current results page (GET), given a previous result.
	 * @param previousResult - the previously requested paginated response to attempt to follow self
	 * @param limit - an optional limit to size the current page of results
	 * @returns If the previous result contains no self link, return {@code null}. Else, return the current page of results.
	 * @throws Error - if the underlying {@link Response} failed
	 */
	async getSelf<T>(previousResult: PaginatedApiResponse<T>, limit?: number) {
		const selfCursor = previousResult.links.self;
		if (!selfCursor) {
			return null;
		}
		return this.#followCursor<T>(selfCursor, limit);
	}
	// this method performs a get request and ignores the resolved base path. Instead,
	// we simply perform a call to fetch with the whole cursor.
	async #followCursor<T>(url: string, limit?: number) {
		const urlWithOptionalLimit = limit ? `${url}?limit=${limit}` : url;
		return this.#doFetch<PaginatedApiResponse<T>>(urlWithOptionalLimit, 'GET');
	}
	// Fetcher method. Attempts to resolve base path if base path has not been set for instance.
	async #doFetch<T>(
		url: string,
		method: RequestInit['method'],
		body?: Record<string, any>,
		headers: Record<string, string> = AutomationClient.#DEFAULT_HEADERS,
	): Promise<T> {
		const requestConfig: RequestInit = { method };
		if (body) {
			requestConfig.body = JSON.stringify(body);
		}
		requestConfig.headers = {
			...AutomationClient.#DEFAULT_HEADERS,
			...headers,
		};
		const response = await fetch(url, requestConfig);
		if (!response.ok) {
			throw await AutomationClient.#normalizeErrorResponse(response);
		}
		// Force base here as the fetch API doesn't provide us any generics to pass T to.
		return response.json() as Promise<T>;
	}
	// Resolve the base path, given a target environment and a target site ARI.
	// The stack name will be resolved (if necessary) via the local dev server,
	// or as a fallback, through local storage.
	// JS does not allow async constructors. As a consequence, we need to attempt resolve the base path on the first
	// request made. Subsequent requests should not call this method. We cannot achieve this through a static
	// async factory, either, as it would prevent inheritance from the AutomationClient class.
	#resolveBasePath = async () => {
		const { resourceOwner: product, cloudId } = extractResourceOwnerAndCloudIdFromSiteAri(
			this.#site,
		);
		const basePath = await AutomationClient.#buildBasePath(this.#env, cloudId, product);
		this.#basePath = basePath;
	};
	// Given url parts and a target environment and stack, construct the base path of the API.
	// This will return null in the event that the target environment is DEV, but no
	// stack name was specified.
	static #buildBasePath = async (
		env: Environment,
		cloudId: string,
		product: Product,
	): Promise<string> => {
		return env !== Environment.PROD
			? AutomationClient.#getNonProdBasePath(env, cloudId, product)
			: AutomationClient.#getProdBasePath(cloudId, product);
	};
	static #getNonProdBasePath = async (
		env: Environment,
		cloudId: string,
		product: Product,
	): Promise<string> => {
		const stack = await getAutomationStack(env, cloudId);
		return `/gateway/api/automation/internal-api/${product}/${cloudId}/${stack}/public/rest/${AutomationClient.#API_VERSION}`;
	};
	static #getProdBasePath = (cloudId: string, product: Product): string =>
		`/gateway/api/automation/public/${product}/${cloudId}/rest/${AutomationClient.#API_VERSION}`;
	static #normalizeErrorResponse = async (response: Response): Promise<Error> => {
		try {
			const errorJson: ApiErrorResponse | any = await response.json();
			// Firstly attempt to parse API error if resolved
			if (errorJson.errors) {
				return new Error(errorJson.errors[0].title);
			}
			// Stringify whole JSON if not of known API error shape.
			throw new Error(JSON.stringify(errorJson));
		} catch (e) {
			// In the event of parse error etc, fallback.
			throw new Error(String(e));
		}
	};
}
