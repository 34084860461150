/* eslint-disable jira/react-no-inline-function-prop */
import React, { useCallback, useState } from 'react';
import { Flex } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import Popup from '@atlaskit/popup';
import { MenuGroup } from '@atlaskit/menu';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import OpenInIssueNavButton from './open-in-issue-nav-button/index.tsx';
import ResetSortingButton from './reset-sorting-button/index.tsx';

import { messages } from './messages.tsx';

type Props = {
	isManuallySorted: boolean;
	showIssueFailureError: boolean;
};

const HeaderActions = ({ isManuallySorted, showIssueFailureError }: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const isSortable = !showIssueFailureError && isManuallySorted;

	const { formatMessage } = useIntl();

	const onToggle = useCallback(() => {
		setIsOpen((prev) => !prev);
	}, [setIsOpen]);
	const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

	return (
		<Flex alignItems="center" gap="space.050">
			<OpenInIssueNavButton />
			<Popup
				shouldRenderToParent
				isOpen={isOpen}
				onClose={onClose}
				content={() => (
					<MenuGroup minWidth={0}>
						<ResetSortingButton isDisabled={!isSortable} />
					</MenuGroup>
				)}
				placement="bottom-end"
				trigger={(triggerProps) => (
					<IconButton
						{...triggerProps}
						icon={MoreIcon}
						label={formatMessage(messages.actions)}
						isSelected={isOpen}
						onClick={onToggle}
						{...(getWillShowNav4() ? {} : { appearance: 'subtle', spacing: 'compact' })}
					/>
				)}
			/>
		</Flex>
	);
};

export default HeaderActions;
