import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	emptyStateMessage: {
		id: 'automation-platform.automation-templates.template-dropdown.empty-state-message',
		defaultMessage:
			"Automate your team's manual tasks, so they can focus on the work that matters. <link>Explore templates</link> to get started.",
		description:
			'The message to show when there are no automation templates to display in the dropdown menu',
	},
});
