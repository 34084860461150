import React from 'react';

import { type I18NMessages, IntlMessagesProvider } from '@atlaskit/intl-messages-provider';

import enMessages from '../../i18n/en';

export type Locales = Record<string, () => Promise<any>>;

export const locales: Locales = {
	cs: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-cs" */ '../../i18n/cs'
		),
	da: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-da" */ '../../i18n/da'
		),
	de: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-de" */ '../../i18n/de'
		),
	en_GB: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-en-GB" */ '../../i18n/en_GB'
		),
	en_ZZ: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-en-ZZ" */ '../../i18n/en_ZZ'
		),
	en: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-en" */ '../../i18n/en'
		),
	es: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-es" */ '../../i18n/es'
		),
	fi: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-fi" */ '../../i18n/fi'
		),
	fr: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-fr" */ '../../i18n/fr'
		),
	hu: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-hu" */ '../../i18n/hu'
		),
	it: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-it" */ '../../i18n/it'
		),
	ja: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-ja" */ '../../i18n/ja'
		),
	ko: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-ko" */ '../../i18n/ko'
		),
	nb: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-nb" */ '../../i18n/nb'
		),
	nl: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-nl" */ '../../i18n/nl'
		),
	pl: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-pl" */ '../../i18n/pl'
		),
	pt_BR: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-pt-BR" */ '../../i18n/pt_BR'
		),
	ru: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-ru" */ '../../i18n/ru'
		),
	sv: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-sv" */ '../../i18n/sv'
		),
	tr: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-tr" */ '../../i18n/tr'
		),
	zh_TW: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-zh-tw" */ '../../i18n/zh_TW'
		),
	zh: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-ui-commons-i18n-zh" */ '../../i18n/zh'
		),
};

const localeMap: Record<string, string> = {
	pt: 'pt-BR',
	'pt-PT': 'pt-BR',
	'en-AU': 'en-GB',
};

const fetchMessagesForLocale = async (
	locale: string,
	locales: Locales,
): Promise<I18NMessages | undefined> => {
	try {
		const targetLocale = (localeMap[locale] ?? locale ?? '').replace('-', '_');

		if (targetLocale in locales) {
			const messages = await locales[targetLocale]();
			return messages.default;
		}
	} catch (e) {
		// ignore
	}

	try {
		const parentLocale = locale.split(/[-_]/)[0];
		if (parentLocale in locales) {
			const messages = await locales[parentLocale]();
			return messages.default;
		}
	} catch (e) {
		// ignore
	}

	// Set English as default.
	return enMessages;
};

type AutomationIntlMessagesProviderProps = {
	locales: Locales;
	children: React.ReactNode;
};

const createLoaderFunction = (locales: Locales) => {
	// Since loaderFn expects a single argument, we need this wrapper function to pass in the locales object to fetchMessagesForLocale.
	return async (locale: string): Promise<I18NMessages | undefined> => {
		return fetchMessagesForLocale(locale, locales);
	};
};

export const AutomationIntlMessagesProvider = ({
	locales,
	children,
}: AutomationIntlMessagesProviderProps) => {
	return (
		<IntlMessagesProvider loaderFn={createLoaderFunction(locales)}>{children}</IntlMessagesProvider>
	);
};
