// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { withTheme } from '../../../../app/context/theme-context/index.tsx';
import { scrollbarWidth } from '../../../../constants/index.tsx';
import type { CompiledTheme } from '../../../../model/themes/index.tsx';

const softShadow = {
	'@media screen and (-webkit-min-device-pixel-ratio: 0)': {
		boxShadow: token('elevation.shadow.overflow'),
	},
	'@media screen and (-webkit-transition)': {
		boxShadow: token('elevation.shadow.overflow'),
	},
	'@-moz-document url-prefix()': {
		boxShadow: token('elevation.shadow.overflow'),
	},
};

type Props = {
	getOffsetFromBottom: number;
	isHorizontalScrollVisible: boolean;
	offset: number;
	showBottomDecal: boolean;
	showLeftShadow: boolean;
	showRightShadow: boolean;
	tableHeight: number;
	tableWidth: number;
	theme: CompiledTheme;
	shouldUseCssForRendering: boolean;
};

export const BOTTOM_WRAPPER_HEIGHT = 20;

// eslint-disable-next-line jira/react/no-class-components
class HorizontalScrollOverlay extends PureComponent<Props> {
	calculateTopPositionForBottomShadow = (spaceTakenByHorizontalScrollbar: number) => {
		const { isHorizontalScrollVisible, tableHeight } = this.props;
		if (isHorizontalScrollVisible) {
			return tableHeight - spaceTakenByHorizontalScrollbar - BOTTOM_WRAPPER_HEIGHT;
		}

		return tableHeight - BOTTOM_WRAPPER_HEIGHT;
	};

	render() {
		const {
			offset,
			showLeftShadow,
			showRightShadow,
			tableHeight,
			tableWidth,
			isHorizontalScrollVisible,
			showBottomDecal,
			getOffsetFromBottom,
			theme,
			shouldUseCssForRendering,
		} = this.props;

		const showBottomShadow =
			(isHorizontalScrollVisible || showBottomDecal) && getOffsetFromBottom > 0;

		const spaceTakenByHorizontalScrollbar =
			scrollbarWidth + theme.scrollbar.marginTop + theme.scrollbar.marginBottom;

		const verticalShadowHeight = tableHeight - spaceTakenByHorizontalScrollbar;

		if (shouldUseCssForRendering && fg('css_support_for_virtual_table')) {
			return (
				<>
					{/* In case of SSR we don't expect customers to horizontally scroll, so we don't render the left shadow */}
					{!__SERVER__ && showLeftShadow && (
						<LeftWrapper
							data-testid="virtual-table.table.overlays.horizontal-scroll.left-wrapper"
							offset={offset}
							height={verticalShadowHeight}
							theme={theme}
						>
							<LeftShadow theme={theme} />
						</LeftWrapper>
					)}
					{/* In case of SSR we don't know the width of a screen anyway, so it's safer to not render the right shadow */}
					{!__SERVER__ && showRightShadow && (
						<RightWrapper
							data-testid="virtual-table.table.overlays.horizontal-scroll.right-wrapper"
							height="100%"
							theme={theme}
						>
							<RightShadow />
						</RightWrapper>
					)}
					{showBottomShadow && (
						<BottomWrapper
							data-testid="virtual-table.table.overlays.horizontal-scroll.bottom-wrapper"
							height={BOTTOM_WRAPPER_HEIGHT}
							tableWidth={tableWidth}
							topPositionForBottomShadow={this.calculateTopPositionForBottomShadow(
								spaceTakenByHorizontalScrollbar,
							)}
							theme={theme}
						>
							<BottomShadow />
						</BottomWrapper>
					)}
				</>
			);
		}

		if (fg('css_support_for_virtual_table')) {
			return (
				<>
					{showLeftShadow && (
						<LeftWrapper offset={offset} height={verticalShadowHeight} theme={theme}>
							<LeftShadow theme={theme} />
						</LeftWrapper>
					)}
					{showRightShadow && (
						<RightWrapper height={verticalShadowHeight} theme={theme}>
							<RightShadow />
						</RightWrapper>
					)}
					{showBottomShadow && (
						<BottomWrapper
							height={BOTTOM_WRAPPER_HEIGHT}
							tableWidth={tableWidth}
							topPositionForBottomShadow={this.calculateTopPositionForBottomShadow(
								spaceTakenByHorizontalScrollbar,
							)}
							theme={theme}
						>
							<BottomShadow />
						</BottomWrapper>
					)}
				</>
			);
		}

		return (
			<div>
				{showLeftShadow && (
					<LeftWrapper offset={offset} height={verticalShadowHeight} theme={theme}>
						<LeftShadow theme={theme} />
					</LeftWrapper>
				)}
				{showRightShadow && (
					<RightWrapperOld height={verticalShadowHeight} theme={theme}>
						<RightShadow />
					</RightWrapperOld>
				)}
				{showBottomShadow && (
					<BottomWrapper
						height={BOTTOM_WRAPPER_HEIGHT}
						tableWidth={tableWidth}
						topPositionForBottomShadow={this.calculateTopPositionForBottomShadow(
							spaceTakenByHorizontalScrollbar,
						)}
						theme={theme}
					>
						<BottomShadow />
					</BottomWrapper>
				)}
			</div>
		);
	}
}

export default withTheme(HorizontalScrollOverlay);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const BottomShadow = styled.div({
	position: 'absolute',
	left: token('space.0'),
	width: '100%',
	height: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...softShadow,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const BottomWrapper = styled.div<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	topPositionForBottomShadow: any;
	tableWidth: string | number;
	height: string | number;
	theme: CompiledTheme;
}>({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ topPositionForBottomShadow }) => `${topPositionForBottomShadow}px`,
	left: token('space.0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ tableWidth }) => `width: ${tableWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ theme }) => theme.shadow.bottom.display,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const RightShadow = styled.div({
	position: 'absolute',
	top: token('space.negative.100'),
	bottom: token('space.negative.100'),
	left: token('space.250'),
	width: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...softShadow,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const RightWrapper = styled.div<{ height: string | number; theme: CompiledTheme }>({
	position: 'absolute',
	top: token('space.0'),
	right: token('space.400'),
	bottom: token('space.0'),
	overflow: 'hidden',
	width: token('space.250'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ theme }) => theme.shadow.right.display,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => (typeof height === 'string' ? height : `${height}px`),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const RightWrapperOld = styled.div<{ height: string | number; theme: CompiledTheme }>({
	position: 'absolute',
	top: token('space.0'),
	right: token('space.400'),
	bottom: token('space.0'),
	overflow: 'hidden',
	width: token('space.250'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ theme }) => theme.shadow.right.display,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const LeftShadow = styled.div<{ theme: CompiledTheme }>({
	position: 'absolute',
	top: token('space.negative.100'),
	bottom: token('space.negative.100'),
	left: token('space.negative.100'),
	width: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...softShadow,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const LeftWrapper = styled.div<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	offset: any;
	height: string | number;
	theme: CompiledTheme;
}>({
	position: 'absolute',

	top: token('space.0'),
	bottom: token('space.0'),
	left: token('space.0'),
	overflow: 'hidden',
	width: token('space.250'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderLeft: ({ theme }) => `${theme.shadow.left.borderWidth}px solid ${theme.header.borderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ theme, height }) => (theme.shadow.left.computeHeight ? `${height}px` : 'inherit'),
});
