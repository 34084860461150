import React from 'react';

import { type IconProps } from '@atlaskit/icon';
import { useThemeObserver } from '@atlaskit/tokens';

import { CustomIcon } from './custom-icon';

/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const GitHubGlyph: React.FunctionComponent = () => {
	const theme = useThemeObserver() as string;
	const iconColor = theme === 'dark' ? '#FFFFFF' : '#161514';
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0001 0C5.3735 0 0 5.50847 0 12.3038C0 17.74 3.43839 22.3519 8.2064 23.9789C8.80613 24.0927 9.02631 23.7119 9.02631 23.387C9.02631 23.0936 9.01518 22.1244 9.01001 21.0963C5.67157 21.8405 4.96712 19.6446 4.96712 19.6446C4.42125 18.2224 3.63472 17.8443 3.63472 17.8443C2.54595 17.0806 3.71679 17.0963 3.71679 17.0963C4.92181 17.1831 5.55632 18.3642 5.55632 18.3642C6.6266 20.2452 8.36358 19.7014 9.04836 19.387C9.15607 18.5918 9.46706 18.049 9.81024 17.7418C7.14485 17.4307 4.34295 16.3757 4.34295 11.6612C4.34295 10.3179 4.81172 9.22032 5.57937 8.35868C5.45477 8.04878 5.04402 6.79737 5.69561 5.10261C5.69561 5.10261 6.7033 4.77193 8.99649 6.3638C9.95371 6.09119 10.9803 5.95447 12.0001 5.94979C13.0199 5.95447 14.0473 6.09119 15.0063 6.3638C17.2967 4.77193 18.303 5.10261 18.303 5.10261C18.9562 6.79737 18.5452 8.04878 18.4206 8.35868C19.1901 9.22032 19.6557 10.3179 19.6557 11.6612C19.6557 16.3869 16.8484 17.4274 14.1762 17.732C14.6067 18.1138 14.9902 18.8626 14.9902 20.0105C14.9902 21.6568 14.9763 22.9817 14.9763 23.387C14.9763 23.7144 15.1923 24.098 15.8006 23.9772C20.566 22.3485 24 17.7381 24 12.3038C24 5.50847 18.6273 0 12.0001 0Z"
				fill={iconColor}
			/>
		</svg>
	);
};

export const GitHubIcon: React.FunctionComponent<IconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <GitHubGlyph />} />
);
