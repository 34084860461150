import React, { type ReactElement } from 'react';

import { FormattedMessage } from 'react-intl-next';
import { di } from 'react-magnetic-di';

import Icon from '@atlaskit/icon';
import ShortcutIcon from '@atlaskit/icon/core/link-external';
import CreateRuleIcon from '@atlaskit/icon/utility/add';
import AuditLogIcon from '@atlaskit/icon/utility/check-circle';
import { LinkItem, Section } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';

import { AutomationIntlMessagesProvider, locales } from '../../common/utils/i18n';

import messages from './messages';

const menuIconStyle = xcss({
	width: 'size.300',
	height: 'size.300',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

interface MenuIconProps {
	children?: ReactElement;
}

const MenuIcon = ({ children }: MenuIconProps) => <Box xcss={menuIconStyle}>{children}</Box>;

export const ManageRulesGlyph = (props: any) => (
	<ShortcutIcon label="" {...props} color="currentColor" />
);

/**
 * baseAutomationUrl - the host url you'd like the create/manage automations links to route to
 * createAutomationComponentFilter - optional category string to apply as a filter when routing
 * the create automation button
 * onCreateAutomationFooterLinkClicked - is called when create automation is clicked
 * onManageAutomationsFooterLinkClicked - is called when manage automations is clicked
 * onAuditLogFooterLinkClicked - is called when audit log is clicked
 * */
interface Props {
	baseAutomationUrl: string;
	createAutomationComponentFilter?: string;
	onCreateAutomationFooterLinkClicked?: () => void;
	onManageAutomationsFooterLinkClicked?: () => void;
	onAuditLogFooterLinkClicked?: () => void;
}

const handleOptionalCallback = (optionalEvent?: () => void) => {
	if (optionalEvent) {
		optionalEvent();
	}
};

export default ({
	baseAutomationUrl,
	createAutomationComponentFilter,
	onCreateAutomationFooterLinkClicked,
	onManageAutomationsFooterLinkClicked,
	onAuditLogFooterLinkClicked,
}: Props) => {
	di(AuditLogIcon, CreateRuleIcon, Icon, MenuIcon, Section);
	const componentFilter = createAutomationComponentFilter
		? `?componentFilterCriteria=${createAutomationComponentFilter}`
		: '';
	const createNewRuleUrl = `${baseAutomationUrl}#/rule/new/__NEW__TRIGGER${componentFilter}`;

	return (
		<AutomationIntlMessagesProvider locales={locales}>
			<Section hasSeparator>
				<LinkItem
					onClick={() => handleOptionalCallback(onCreateAutomationFooterLinkClicked)}
					href={createNewRuleUrl}
					testId="create-automation-link"
					iconBefore={
						<MenuIcon>
							<CreateRuleIcon label="" color="currentColor" />
						</MenuIcon>
					}
				>
					<FormattedMessage {...messages.createAutomationItem} />
				</LinkItem>
				<LinkItem
					onClick={() => handleOptionalCallback(onManageAutomationsFooterLinkClicked)}
					href={baseAutomationUrl}
					testId="manage-automations-link"
					iconBefore={
						<MenuIcon>
							<ManageRulesGlyph />
						</MenuIcon>
					}
				>
					<FormattedMessage {...messages.manageAutomationsItem} />
				</LinkItem>
				<LinkItem
					onClick={() => {
						handleOptionalCallback(onAuditLogFooterLinkClicked);
					}}
					href={`${baseAutomationUrl}#tab/audit-log`}
					testId="audit-log-link"
					iconBefore={
						<MenuIcon>
							<AuditLogIcon label="" color="currentColor" />
						</MenuIcon>
					}
				>
					<FormattedMessage {...messages.auditLogItem} />
				</LinkItem>
			</Section>
		</AutomationIntlMessagesProvider>
	);
};
