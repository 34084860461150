import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import RefreshIcon from '@atlaskit/icon/core/migration/refresh';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export interface Props {
	issueCount: number;
	isCurrentDataFiltered: boolean;
	isLoadingIssues: boolean;
	onFilterReloadClick: () => void;
}

export const IssueCount = (props: Props) => {
	const { formatMessage } = useIntl();
	const { issueCount, isCurrentDataFiltered, isLoadingIssues, onFilterReloadClick } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleReloadClick = () => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'jsmQueueFiltering reloaded');
		onFilterReloadClick();
	};

	if (!isCurrentDataFiltered && issueCount !== 0 && !isLoadingIssues) {
		return (
			<Wrapper>
				{formatMessage(
					fg('itsm-issue-renaming')
						? messages.numberOfIssuesIssueTermRefresh
						: messages.numberOfIssues,
					{ issueCount },
				)}
			</Wrapper>
		);
	}
	if (isCurrentDataFiltered && !isLoadingIssues) {
		return (
			<Wrapper>
				<Layout>
					{formatMessage(
						fg('itsm-issue-renaming')
							? messages.numberOfFilteredIssuesIssueTermRefresh
							: messages.numberOfFilteredIssues,
						{
							filteredIssueCount: issueCount,
						},
					)}
					<Box xcss={spacerStyles} />
					<Tooltip position="bottom" content={formatMessage(messages.refreshResults)}>
						<Button
							aria-label={formatMessage(messages.refreshResults)}
							iconBefore={
								<RefreshIcon label={formatMessage(messages.refreshResults)} LEGACY_size="small" />
							}
							data-testId="servicedesk-queues-agent-view.layout.header.issue-count.reload-button"
							appearance="subtle"
							spacing="compact"
							onClick={handleReloadClick}
						/>
					</Tooltip>
				</Layout>
			</Wrapper>
		);
	}

	return isLoadingIssues ? (
		<Wrapper>
			<Skeleton
				height="24px"
				width="100px"
				data-testid="servicedesk-queues-agent-view.layout.header.issue-count.skeleton"
			/>
		</Wrapper>
	) : null;
};

export default IssueCount;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Layout = styled.div({
	display: 'flex',
	alignItems: 'center',
});

const spacerStyles = xcss({
	width: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const Wrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle'),
	marginTop: token('space.150'),
	marginBottom: token('space.300'),
});
