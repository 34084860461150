import React, { useState, useEffect, useMemo, useCallback, type RefCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import MenuButton from '@atlassian/jira-automation-menu/src/ui/button/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import {
	ContextualAnalyticsData,
	SCREEN,
	FireScreenAnalytics,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { ProjectContextProvider } from '@atlassian/jira-providers-project-context/src/index.tsx';
import type { ProjectContextData } from '@atlassian/jira-router-resources-project-context/src/services/project-context/types.tsx';
import { ServiceDeskAnalyticsContext } from '@atlassian/jira-servicedesk-analytics/src/ui/servicedesk-analytics-context/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../common/constants.tsx';
import {
	MenuStateContainer,
	useFetchState,
	useIsLoading,
	useHasError,
	useIsVisible,
} from '../services/menu-state/index.tsx';
import { AutomationMenu } from './automation-menu/index.tsx';
import { MenuContent } from './menu-content/index.tsx';
import { menuContentEntryPoint } from './menu-content/entrypoint.tsx';

const Menu = () => {
	const fetchState = useFetchState();
	const isLoading = useIsLoading();
	const hasError = useHasError();
	const isVisible = useIsVisible();
	const [isOpen, setIsOpen] = useState(false);
	const [startTime, setStartTime] = useState(Date.now());
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		fetchState();
	}, [fetchState]);

	if (isLoading || hasError || !isVisible) {
		return null;
	}
	const fireDurationEvent = () => {
		const duration = Date.now() - startTime;
		fireTrackAnalytics(createAnalyticsEvent({}), 'JSMHRAutomationMenuPopup View', { duration });
	};

	const showPopup = (open: boolean) => {
		if (open) {
			setStartTime(Date.now());
		} else {
			fireDurationEvent();
		}
		setIsOpen(open);
	};

	return (
		<>
			<Popup
				isOpen={isOpen}
				onClose={() => showPopup(false)}
				placement="bottom-end"
				messageId="servicedesk-queues-automation-menu.ui.popup"
				messageType="transactional"
				content={() => <MenuContent onClick={() => showPopup(false)} />}
				trigger={({ ...triggerProps }) => (
					<Box xcss={fg('jsm_views_inside_queues_-_main_flag') ? null : menuButtonWrapperStyles}>
						<MenuButton {...triggerProps} onClick={() => showPopup(!isOpen)} isSelected={isOpen} />
					</Box>
				)}
			/>
			{isOpen && <FireScreenAnalytics />}
		</>
	);
};

export const QueuesAutomationMenu = () => (
	<JSErrorBoundary
		id="queuesAutomationMenu"
		packageName={PACKAGE_NAME}
		teamName={TEAM_NAME}
		fallback="unmount"
	>
		{fg('automation-templates-team-types-refactor-m1_isj0e') ? (
			<ServiceDeskAnalyticsContext>
				<ContextualAnalyticsData sourceType={SCREEN} sourceName="queuesAutomationMenuNew">
					<AutomationMenu />
				</ContextualAnalyticsData>
			</ServiceDeskAnalyticsContext>
		) : (
			<ProjectContextProvider>
				{(projectContext: ProjectContextData) =>
					projectContext.data?.projectId !== undefined &&
					projectContext.data?.projectId !== null ? (
						<ServiceDeskAnalyticsContext>
							<ContextualAnalyticsData sourceType={SCREEN} sourceName="queuesAutomationMenu">
								<MenuStateContainer scope={`project-${projectContext.data.projectId}`}>
									<Menu />
								</MenuStateContainer>
							</ContextualAnalyticsData>
						</ServiceDeskAnalyticsContext>
					) : null
				}
			</ProjectContextProvider>
		)}
	</JSErrorBoundary>
);

export const MenuNew = () => {
	const fetchState = useFetchState();
	const isLoading = useIsLoading();
	const hasError = useHasError();
	const isVisible = useIsVisible();
	const [isOpen, setIsOpen] = useState(false);
	const [startTime, setStartTime] = useState(Date.now());
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const entryPointParams = useMemo(() => ({}), []);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		menuContentEntryPoint,
		entryPointParams,
	);

	const fireDurationEvent = useCallback(() => {
		const duration = Date.now() - startTime;
		fireTrackAnalytics(createAnalyticsEvent({}), 'JSMHRAutomationMenuPopup View', { duration });
	}, [createAnalyticsEvent, startTime]);

	const showPopup = useCallback(
		(open: boolean) => {
			if (open) {
				setStartTime(Date.now());
			} else {
				fireDurationEvent();
			}
			setIsOpen(open);
		},
		[fireDurationEvent, setStartTime],
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions, !isOpen);
	const runtimeProps = useMemo(
		() => ({
			onClick: () => showPopup(false),
		}),
		[showPopup],
	);

	useEffect(() => {
		fetchState();
	}, [fetchState]);

	if (isLoading || hasError || !isVisible) {
		return null;
	}

	return (
		<>
			<Popup
				isOpen={isOpen}
				onClose={() => showPopup(false)}
				placement="bottom-end"
				messageId="servicedesk-queues-automation-menu.ui.popup.new"
				messageType="transactional"
				content={() => (
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="queue-automation-content-menu"
						packageName="@atlassian/jira-servicedesk-queues-automation-menu"
						errorFallback="flag"
						runtimeProps={runtimeProps}
					/>
				)}
				trigger={({ ...triggerProps }) => (
					<Box xcss={fg('jsm_views_inside_queues_-_main_flag') ? null : menuButtonWrapperStyles}>
						<MenuButton
							{...triggerProps}
							ref={(element) => {
								triggerRef(element);
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								(triggerProps.ref as RefCallback<HTMLElement>)(element);
							}}
							onClick={() => showPopup(!isOpen)}
							isSelected={isOpen}
						/>
					</Box>
				)}
			/>
			{isOpen && <FireScreenAnalytics />}
		</>
	);
};

export const SyncQueuesAutomationMenu = () => (
	<JSErrorBoundary
		id="queuesAutomationMenu"
		packageName={PACKAGE_NAME}
		teamName={TEAM_NAME}
		fallback="unmount"
	>
		<ProjectContextProvider>
			{(projectContext: ProjectContextData) =>
				projectContext.data?.projectId !== undefined && projectContext.data?.projectId !== null ? (
					<ServiceDeskAnalyticsContext>
						<ContextualAnalyticsData sourceType={SCREEN} sourceName="queuesAutomationMenu">
							<MenuStateContainer scope={`project-${projectContext.data.projectId}`}>
								<MenuNew />
							</MenuStateContainer>
						</ContextualAnalyticsData>
					</ServiceDeskAnalyticsContext>
				) : null
			}
		</ProjectContextProvider>
	</JSErrorBoundary>
);

const menuButtonWrapperStyles = xcss({
	paddingRight: 'space.100',
});
