import { type Environment } from '../../common/types';
import AutomationClient from '../../common/utils/client';
import type { PaginatedApiResponse } from '../../common/utils/types';

import type {
	CreateRuleFromTemplatePayload,
	CreateRuleFromTemplateResponse,
	Template,
	TemplateParameterBooleanValue,
	TemplateParameterNumberValue,
	TemplateParameterTextValue,
	TemplateSearchQuery,
} from './types';

/**
 * Client for communicating with the Automation Public REST API's Templates domain. You can read more about this
 * API [here](https://developer.atlassian.com/cloud/automation/rest/api-group-rest/#api-rest-v1-template-search-get)
 */
export default class TemplatesClient extends AutomationClient {
	static #BASE_PATH = '/template';
	static #SEARCH_PATH = TemplatesClient.#BASE_PATH + '/search';
	static #CREATE_PATH = TemplatesClient.#BASE_PATH + '/create';
	/**
	 * Create an instance of the {@link TemplatesClient}.
	 * @param env - The environment (of type {@link Environment}) you'd like the client to target
	 * @param site - The site you'd like the client to target. This should be a string in site ARI format.
	 */
	constructor(env: Environment, site: string) {
		super(env, site);
	}
	/**
	 * Return a single {@link Template} by it's template id.
	 * @param id - the template id you'd like to fetch
	 * @returns a Promise resolving the requested {@link Template} if there exists one, or a Promise rejection otherwise.
	 */
	async getTemplateById(id: string): Promise<Template> {
		const path = `${TemplatesClient.#BASE_PATH}/${id}`;
		return this.makeRequest<Template>(path, 'GET');
	}
	/**
	 * Perform a multi-value search for a set of templates, matching on a set of template ids. This is a paginated request,
	 * so overflow of results will provide paged results.
	 * @param templateIds - a list of template ids you'd like to return templates for. Duplicate ids will be ignored.
	 * @param limit - Optionally provide a limit for the request. If you do not provide a limit, the API will default to max 50 results per page.
	 * @returns A Promise resolving the paginated search results (of type {@link PaginatedApiResponse<Template>}) or a rejection in the event of
	 * a 4xx, 5xx response.
	 * @throws `Error` if the underlying {@link Response} failed
	 */
	async searchTemplatesByIds(
		templateIds: string[],
		limit?: number,
	): Promise<PaginatedApiResponse<Template>> {
		const query = TemplatesClient.#buildQuery({ templateIds }, limit);
		return this.makePaginatedRequest<Template>(TemplatesClient.#SEARCH_PATH, 'POST', query);
	}
	/**
	 * Perform a multi-value search for a set of templates, matching on a set of categories (category keys). This is a paginated request,
	 * so overflow of results will provide paged results.
	 * @param categories - a list of category keys you'd like to return templates for. Duplicate category keys will be ignored.
	 * @param limit - Optionally provide a limit for the request. If you do not provide a limit, the API will default to a max of 50 results per page.
	 * @returns A Promise resolving the paginated search results (of type {@link PaginatedApiResponse<Template>}), or a rejection in the event of
	 * a 4xx, 5xx response.
	 * @throws `Error` if the underlying {@link Response} failed
	 */
	async searchTemplatesByCategories(
		categories: string[],
		limit?: number,
	): Promise<PaginatedApiResponse<Template>> {
		const query = TemplatesClient.#buildQuery({ categories }, limit);
		return this.makePaginatedRequest<Template>(TemplatesClient.#SEARCH_PATH, 'POST', query);
	}
	/**
	 * Perform a search for a set of templates, matching contextually against a provided rule home. This is a contextual action, so providing a JPD Project rule home ARI,
	 * for instance, will return a different set of relevant templates as compared to providing a Confluence site as a rule home ARI.
	 * @param ruleHome - a ruleHome (in format of an ARI) that matches the "scope" in which the templates are being requested. You can use this method in
	 * conjunction with the site/space/project ARI that it's being called from to return templates that are relevant to the product surface.
	 * @param limit - Optionally provide a limit for the request. If you do not provide a limit, the API will default to a max of 50 results per page.
	 * @returns A Promise resolving the paginated search results (of type {@link PaginatedApiResponse<Template>}), or a rejection in the event of
	 * a 4xx, 5xx response.
	 * @throws `Error` if the underlying {@link Response} failed
	 */
	async searchTemplatesByRuleHome(
		ruleHome: string,
		limit?: number,
	): Promise<PaginatedApiResponse<Template>> {
		const query = TemplatesClient.#buildQuery({ ruleHome }, limit);
		return this.makePaginatedRequest<Template>(TemplatesClient.#SEARCH_PATH, 'POST', query);
	}
	/**
	 * Perform a multi-value search for a set of templates, matching across multiple search filters.
	 * @param filters Optionally contains an intersection of categories, template ids, and/or a rule home. The filter operation is:
	 * - ANY match within a given filter, e.g. given templateIds: ["1", "2"], match a template that has id 1 OR 2
	 * - ALL match across filters, e.g. given templateIds: ["1"], categories: ["jira"], match only a template with id 1 AND the jira category.
	 * @param limit - Optionally provide a limit for the request. If you do not provide a limit, the API will default to a max of 50 results per page.
	 * @returns A Promise resolving the paginated search results (of type {@link PaginatedApiResponse<Template>}), or a rejection in the event of
	 * a 4xx, 5xx response.
	 * @throws `Error` if the underlying {@link Response} failed
	 */
	async searchTemplatesByFilters(
		filters: Partial<TemplateSearchQuery>,
		limit?: number,
	): Promise<PaginatedApiResponse<Template>> {
		const query = TemplatesClient.#buildQuery(filters, limit);
		return this.makePaginatedRequest<Template>(TemplatesClient.#SEARCH_PATH, 'POST', query);
	}
	/**
	 * Given any initial result of the search operations available in this {@link TemplatesClient} interface, page FORWARDS to
	 * the next page of results.
	 * @param previousResults - The response from the previous request.
	 * @param limit - Optionally provide a limit for the request. If you do not provide a limit, the API will default to a max of 50 results per page.
	 * @returns the next page of results if there was a next page, or an empty page otherwise.
	 * @throws `Error` if the underlying {@link Response} failed
	 */
	async getNextSearchPageResults(previousResults: PaginatedApiResponse<Template>, limit?: number) {
		return this.pageForwards(previousResults, limit);
	}
	/**
	 * Given any initial result of the search operations available in this {@link TemplatesClient} interface, page BACKWARDS to
	 * the previous page of results.
	 * @param previousResults - The response from the previous request.
	 * @param limit - Optionally provide a limit for the request. If you do not provide a limit, the API will default to a max of 50 results per page.
	 * @returns the previous page of results if there was a next page, or an empty page otherwise.
	 * @throws `Error` if the underlying {@link Response} failed
	 */
	async getPrevSearchPageResults(previousResults: PaginatedApiResponse<Template>, limit?: number) {
		return this.pageBackwards(previousResults, limit);
	}
	/**
	 * Given any initial result of the search operations available in this {@link TemplatesClient} interface, re-request the current page of results
	 * @param previousResults - The response from the previous request.
	 * @param limit - Optionally provide a limit for the request. If you do not provide a limit, the API will default to a max of 50 results per page.
	 * @returns the current page of results if there was a current page, or an empty page otherwise.
	 * @throws `Error` if the underlying {@link Response} failed
	 */
	async getCurrentSearchPageResults(
		previousResults: PaginatedApiResponse<Template>,
		limit?: number,
	) {
		return this.getSelf(previousResults, limit);
	}
	/**
	 * @param templateId - The corresponding template to create a rule from
	 * @param ruleHome - The location the rule will be created from
	 * @param parameters - The parameter configuration for the rule, if any
	 * @returns - the rule id of the created rule, if successfully created
	 * @throws `Error` if the underlying {@link Response} failed
	 */
	async createRuleFromTemplate(
		templateId: string,
		ruleHome: string,
		parameters?: Record<
			string,
			TemplateParameterTextValue | TemplateParameterNumberValue | TemplateParameterBooleanValue
		>,
	) {
		const payload: CreateRuleFromTemplatePayload = {
			templateId,
			ruleHome,
		};
		if (parameters) {
			payload.parameters = parameters;
		}
		return this.makeRequest<CreateRuleFromTemplateResponse>(
			TemplatesClient.#CREATE_PATH,
			'POST',
			payload,
		);
	}
	/**
	 * Private helper to create a query given some filter args. If there is a cursor present, only attach cursor
	 * as cursor is mutally exlusive with additional filters. Apply limit if it exists.
	 */
	static #buildQuery(filters: Partial<TemplateSearchQuery>, limit?: number): TemplateSearchQuery {
		let query: TemplateSearchQuery = { ...filters };
		if (limit) {
			query.limit = limit;
		}
		return query;
	}
}
