import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';

import { type IconProps, sizes } from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';

interface WrapperProps {
	primaryColor?: string;
	secondaryColor?: string;
	size?: keyof typeof sizes;
}

const getSize = ({ size }: WrapperProps) =>
	size ? `height: ${sizes[size]}; width: ${sizes[size]};` : null;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const IconWrapper = styled.span<WrapperProps>`
	${getSize};
	color: ${(p) => p.primaryColor || 'currentColor'};
	display: inline-block;
	fill: ${(p) => p.secondaryColor || token('elevation.surface')};
	flex-shrink: 0;
	line-height: 1;

	> svg {
		${getSize};
		max-height: 100%;
		max-width: 100%;
		overflow: hidden;
		pointer-events: none;
		vertical-align: bottom;
	}
`;

export const CustomIcon = (props: IconProps) => {
	const { glyph: Glyph, primaryColor, secondaryColor, size, testId, label } = props;
	const glyphProps = { children: Glyph ? <Glyph role="presentation" /> : null };

	return (
		<IconWrapper
			primaryColor={primaryColor}
			secondaryColor={secondaryColor}
			size={size}
			data-testid={testId}
			role={label ? 'img' : 'presentation'}
			aria-label={label || undefined}
			{...glyphProps}
		/>
	);
};
