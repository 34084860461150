export enum Environment {
	PRE_PROD = 'pre-prod',
	PROD = 'prod',
	STAGING = 'staging',
	DEV = 'dev',
}
/**
 * Specifies what product context the API will be operating under.
 * Currently, the automation public API supports operations
 * in both Jira and Confluence.
 */
export enum Product {
	JIRA = 'jira',
	CONFLUENCE = 'confluence',
}
/**
 * Base type for REST API responses from the Automation public API.
 * The Automation API is *mostly* JSONAPIv1.1 compliant.
 * https://jsonapi.org/format/
 */
export type ApiResponse<T = any> = {
	links: {
		prev: string;
		self: string;
		next: string;
	};
	data: T[];
};
