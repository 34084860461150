/** @jsx jsx */
import { jsx } from '@compiled/react';
import CheckCircleIcon from '@atlaskit/icon/core/success';
import { token } from '@atlaskit/tokens';
import { useFlagsService, AkFlag, fromFlagId } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME } from '../../../common/constants.tsx';
import messages from './messages.tsx';

export type Props = {
	automationBaseUrl: string;
	flagId: string;
	flagKey: string;
	ruleId: number;
};

export const SuccessFlag = ({ automationBaseUrl, flagId, flagKey, ruleId }: Props) => {
	const { formatMessage } = useIntl();
	const { dismissFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<AkFlag
			testId="servicedesk-queues-automation-menu.ui.automation-menu.success-flag.success-flag"
			icon={<CheckCircleIcon color={token('color.icon.success')} label="" />}
			id={fromFlagId(flagId)}
			key={flagKey}
			title={formatMessage(messages.successFlagTitle)}
			messageId="servicedesk-queues-automation-menu.ui.automation-menu.success-flag.ak-flag"
			messageType="transactional"
			actions={[
				{
					content: formatMessage(messages.successFlagAction),
					href: `${automationBaseUrl}#/rule/${ruleId}`,
					onClick: () => {
						fireUIAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.successFlag.action clicked`);
					},
				},
			]}
			onDismissed={() => {
				fireUIAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.successFlag.dismiss clicked`);
				dismissFlag(flagId);
			}}
		/>
	);
};
