import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	discoverAutomationMenuItemLabel: {
		id: 'servicedesk-queues-automation-menu.menu-content.template-rules.discover-automation-menu-item-label',
		defaultMessage: 'Discover more automation',
		description: 'A label for the popup menu item for Discover automation deep-link',
	},
	itsmTemplateOne: {
		id: 'servicedesk-queues-automation-menu.menu-content.template-rules.itsm-template-one',
		defaultMessage: 'Notify assignee when the due date of an issue is approaching',
		description: 'Title or short name for an automation rule described for itms template one.',
	},
	itsmTemplateTwo: {
		id: 'servicedesk-queues-automation-menu.menu-content.template-rules.itsm-template-two',
		defaultMessage: 'Set security level by request type',
		description: 'Title or short name for an automation rule described for itms template two.',
	},
	itsmTemplateThree: {
		id: 'servicedesk-queues-automation-menu.menu-content.template-rules.itsm-template-three',
		defaultMessage: 'Assign approver based on request type',
		description: 'Title or short name for an automation rule described for itms template three.',
	},
	hrTemplateOne: {
		id: 'servicedesk-queues-automation-menu.menu-content.template-rules.hr-template-one',
		defaultMessage:
			'Onboarding or off-boarding employee: notify assignee when due date is approaching',
		description: 'Title or short name for an automation rule described for cm template one.',
	},
	hrTemplateTwo: {
		id: 'servicedesk-queues-automation-menu.menu-content.template-rules.hr-template-two',
		defaultMessage: 'Change the security level for confidential HR cases',
		description: 'Title or short name for an automation rule described for cm template two.',
	},
	hrTemplateThree: {
		id: 'servicedesk-queues-automation-menu.menu-content.template-rules.hr-template-three',
		defaultMessage: 'Assign approver for employee transfer requests',
		description: 'Title or short name for an automation rule described for cm template three.',
	},
	itsmTemplateOneIssueTermRefresh: {
		id: 'servicedesk-queues-automation-menu.menu-content.template-rules.itsm-template-one-issue-term-refresh',
		defaultMessage: 'Notify assignee when the due date of a work item is approaching',
		description: 'Title or short name for an automation rule described for itms template one.',
	},
});
