import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

type Props = {
	width: number;
	isVisible: boolean;
};

export default function ResizeColumnOverlay(props: Props) {
	const { width, isVisible } = props;
	return <Overlay width={width} isVisible={isVisible} />;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Overlay = styled.div<{ width: string | number; isVisible: boolean }>({
	pointerEvents: 'none',
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface.overlay'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isVisible }) => (isVisible ? '0.5' : '0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transition: ({ isVisible }) => `opacity ${isVisible ? '0.25s' : '0s'} ease-in`,
});
