import { useMemo } from 'react';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { toProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createStateHook,
	createActionsHook,
	createStore,
} from '@atlassian/react-sweet-state';
import { type Actions, type StoreActions, actions } from './actions/index.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	isLoading: false,
	isVisible: false,
	templateIds: undefined,
	error: undefined,
};

export const store = createStore<State, StoreActions>({
	initialState,
	actions,
	name: 'queues-automation-menu-state',
});

export const MenuStateContainer = createContainer<State, StoreActions, {}>(store);

const useStoreActions = createActionsHook<State, StoreActions>(store);

const useTransformedStoreActions = (): Actions => {
	const { fetchState } = useStoreActions();
	const { data: project } = useProjectContext();
	const projectId = toProjectId(`${project?.projectId}`);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useMemo(() => {
		const actionProps = {
			createAnalyticsEvent,
			projectId,
		};
		return {
			fetchState: fetchState(actionProps),
		};
	}, [createAnalyticsEvent, fetchState, projectId]);
};

export const useFetchState = () => useTransformedStoreActions().fetchState;

export const useIsLoading = createStateHook<State, StoreActions, boolean>(store, {
	selector: ({ isLoading }) => isLoading,
});

export const useHasError = createStateHook<State, StoreActions, boolean>(store, {
	selector: ({ error }) => !!error,
});

export const useIsVisible = createStateHook<State, StoreActions, boolean>(store, {
	selector: ({ isVisible }) => isVisible,
});

export const useTemplateIds = createStateHook<State, StoreActions, string[]>(store, {
	selector: ({ templateIds }) => templateIds ?? [],
});
