// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HTTP = 'HTTP' as const;
export const UNKNOWN = 'UNKNOWN' as const;
// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REDUX = 'REDUX' as const;

type ExceptionBody = {
	error?: string;
};

export type GenericErrorDescription = {
	type: typeof UNKNOWN;
	message: string;
	stack: string | undefined;
};

type ReduxErrorDescription = {
	type: typeof REDUX;
	message: string;
	stack: string | undefined;
};

type HttpErrorDescription = {
	type: typeof HTTP;
	message: string;
	body: ExceptionBody;
	statusCode: number;
};

export type ErrorDescription =
	| GenericErrorDescription
	| HttpErrorDescription
	| ReduxErrorDescription;
