import {
	getFieldTypesToOverrideHtmlDataForAsSet,
	getFieldTypesToOverrideHtmlDataForAsSetOld,
} from '../../../../../json-fields/index.tsx';
import type {
	HtmlOverrideTransformer,
	FieldDataTransformer,
	TransformableFieldType,
	FieldDataTransformers,
	HtmlOverrideTransformers,
} from './common/types.tsx';
import {
	SlaFieldTransformer,
	serviceEntityFieldTransformer,
	cmdbFieldTransformer,
	respondersFieldTransformer,
	majorIncidentFieldTransformer,
	multiUserPickerTransformer,
	multiSelectFieldTransformer,
	multiCheckBoxesFieldTransformer,
	multiVersionFieldTransformer,
	multiGroupPickerTransformer,
	entitlementFieldTransformer,
	sentimentFieldTransformer,
	organizationFieldTransformer,
} from './custom-fields/index.tsx';
import {
	issueKeyTransformer,
	issueTypeTransformer,
	creatorFieldTransformer,
	assigneeFieldTransformer,
	statusFieldTransformer,
	reporterFieldTransformer,
	summaryFieldTransformer,
	labelsFieldTransformer,
	requestParticipantsPickerTransformer,
	priorityTransformer,
	statusCategoryTransformer,
	priorityTransformerNew,
} from './system-fields/index.tsx';

const FIELD_DATA_TRANSFORMERS: FieldDataTransformers = {
	assignee: assigneeFieldTransformer,
	creator: creatorFieldTransformer,
	'com.atlassian.servicedesk:sd-sla-field': SlaFieldTransformer,
	status: statusFieldTransformer,
	issuetype: issueTypeTransformer,
	priority: priorityTransformerNew,
	reporter: reporterFieldTransformer,
	summary: summaryFieldTransformer,
	labels: labelsFieldTransformer,
	'com.atlassian.jira.plugins.service-entity:service-entity-field-cftype':
		serviceEntityFieldTransformer,
	'com.atlassian.jira.plugins.cmdb:cmdb-object-cftype': cmdbFieldTransformer,
	'com.atlassian.jira.modules.servicemanagement.responders-entity:responders-entity-field-cftype':
		respondersFieldTransformer,
	'com.atlassian.jira.modules.servicemanagement.major-incident-entity:major-incident-entity-field-cftype':
		majorIncidentFieldTransformer,
	'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker': multiUserPickerTransformer,
	'com.atlassian.servicedesk:sd-request-participants': requestParticipantsPickerTransformer,
	'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes':
		multiCheckBoxesFieldTransformer,
	'com.atlassian.jira.plugin.system.customfieldtypes:multiselect': multiSelectFieldTransformer,
	'com.atlassian.jira.plugin.system.customfieldtypes:multiversion': multiVersionFieldTransformer,
	'com.atlassian.jira.plugin.system.customfieldtypes:multigrouppicker': multiGroupPickerTransformer,
	'com.atlassian.servicedesk.customer-context:sd-csm-entitlement': entitlementFieldTransformer,
	'com.atlassian.servicedesk.sentiment:sd-sentiment': sentimentFieldTransformer,
	'com.atlassian.servicedesk.customer-context:sd-csm-organization': organizationFieldTransformer,
};

export const getJsonTransformer = (
	fieldType: string,
): FieldDataTransformer<TransformableFieldType> | null => {
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'FieldDataTransformers'.
	const transformer = FIELD_DATA_TRANSFORMERS[fieldType];
	return transformer;
};

const HTML_OVERRIDE_TRANSFORMERS_OLD: HtmlOverrideTransformers = {
	issuekey: issueKeyTransformer,
	priority: priorityTransformer,
};

const HTML_OVERRIDE_TRANSFORMERS: HtmlOverrideTransformers = {
	issuekey: issueKeyTransformer,
	statusCategory: statusCategoryTransformer,
};

export const getHtmlOverrideFieldTransformerOld = (
	fieldType: string,
): HtmlOverrideTransformer | undefined =>
	getFieldTypesToOverrideHtmlDataForAsSetOld().has(fieldType)
		? // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'HtmlOverrideTransformers'.
			HTML_OVERRIDE_TRANSFORMERS_OLD[fieldType]
		: undefined;

export const getHtmlOverrideFieldTransformer = (
	fieldType: string,
): HtmlOverrideTransformer | undefined =>
	getFieldTypesToOverrideHtmlDataForAsSet().has(fieldType)
		? // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'HtmlOverrideTransformers'.
			HTML_OVERRIDE_TRANSFORMERS[fieldType]
		: undefined;
