import { fg } from '@atlassian/jira-feature-gating';
import type { JiraServiceManagementNavReportsData } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/jsm-reports-sidebar-navigation/types.tsx';
import type { JiraServiceManagementNavData } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-navigation/types.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import type {
	ReportFeatures,
	SettingFeatures,
	TMPSettingFeatures,
} from '../../../common/types/index.tsx';
import type { Features } from '../types.tsx';

export const extractData = (navData: JiraServiceManagementNavData): Features => {
	const {
		project,
		permissionInfo,
		isAgent,
		hasCustomerChannels,
		knowledgeBaseInfo,
		raiseRequestInfo,
		opsGenieInfo,
		projectSummaryInfo,
		boardInfo,
		calendarInfo,
		hasNotificationLogs = true,
		hasHelpCenterSettings = false,
		hasArchivedIssues = false,
	} = navData;
	const { isAdmin, projectPermissionsQueried } = permissionInfo;
	const { isOnCallEnabled, isAlertEnabled, opsGenieBaseUrl } = opsGenieInfo;
	const isProjectOrJiraAdmin = isAdmin || projectPermissionsQueried.ADMINISTER_PROJECTS === true;

	const { portalUrl } = raiseRequestInfo;

	return {
		hasArchivedIssues,
		hasQueues: isAgent,
		hasCustomers: isAgent,
		hasCustomerChannels,
		hasReports: isAgent,
		hasPIN: !isAgent,
		hasShortcutsAdd: isProjectOrJiraAdmin,
		hasSettings: isProjectOrJiraAdmin,
		knowledge: {
			enabled: knowledgeBaseInfo.enabled,
			type: knowledgeBaseInfo.enabled ? knowledgeBaseInfo.knowledgeBaseType : null,
		},
		raiseRequest: {
			enabled: raiseRequestInfo.enabled,
			url: raiseRequestInfo.enabled ? portalUrl : '',
		},
		opsgenieAlert: {
			enabled: isAlertEnabled,
			url: isAlertEnabled ? `${opsGenieBaseUrl}/alert/list` : '',
		},
		opsgenieOnCall: {
			enabled: isOnCallEnabled,
			url: isOnCallEnabled ? `${opsGenieBaseUrl}/schedule/whoIsOnCall` : '',
		},
		hasProjectSummary: fg('jsm-project-summary-beta') ? !!projectSummaryInfo?.enabled : false,
		jsmBoard: {
			enabled: boardInfo.enabled,
			url: `/jira/servicedesk/projects/${project.key}/boards/${boardInfo.board.id}`,
		},
		jsmCalendar: {
			enabled: calendarInfo?.enabled ?? false,
			url: `/jira/servicedesk/projects/${project.key}/boards/${boardInfo.board.id}/calendar`,
		},
		hasNotificationLogs: fg('jcs_master_flag') ? hasNotificationLogs : true,
		hasHelpCenterSettings: fg('jcs_master_flag')
			? isProjectOrJiraAdmin && hasHelpCenterSettings
			: false,
	};
};

export const extractReportData = (data: JiraServiceManagementNavReportsData): ReportFeatures => ({
	hasWorkload: true,
	hasSatisfaction: data.hasSatisfactionReport,
	hasRequestsDeflected: data.hasRequestsDeflectedReport,
	hasRequestsResolved: data.hasRequestsResolvedReport,
	hasNewReport: data.hasNewReport,
	hasManageReport: false,
});

export type SettingsInfo = {
	hasActiveSoftwareAccess: boolean;
	environment: Environment;
};

export const extractSettingData = (
	navData: JiraServiceManagementNavData,
	{ hasActiveSoftwareAccess, environment }: SettingsInfo,
): SettingFeatures => {
	const { permissionInfo, settingsInfo } = navData;
	const {
		hasChangeManagement = false,
		hasAddonInstaller = false,
		hasIncidentManagement = false,
		hasExternalLinks = false,
		hasProjectAutomation = false,
		hasWidgetAddon = false,
		hasChat = true,
		hasServices = false,
		hasVersions = true,
		hasComponents = true,
		hasCustomerSupportManagement = false,
		hasProductsAndEntitlements = false,
		hasDeveloperEscalation = false,
		hasVirtualAgent = true,
		hasLanguageSupport = true,
		hasRequestTypes = true,
		hasPortalSettings = true,
		hasCustomerNotifications = true,
		hasCustomerPermissions = true,
		hasForms = true,
		hasKnowledgeBase = true,
		hasChannelsAndSelfService = true,
	} = settingsInfo;
	const { isAdmin, projectPermissionsQueried } = permissionInfo;
	const isProjectAdmin = projectPermissionsQueried.ADMINISTER_PROJECTS === true;
	const isProjectOrJiraAdmin = isAdmin || isProjectAdmin;
	const hasIssueLayoutPermissions =
		permissionInfo.projectPermissionsQueried.EDIT_ISSUE_LAYOUT === true;
	return {
		hasDetails: isProjectOrJiraAdmin,
		hasPeople: isProjectOrJiraAdmin,
		hasSummary: true,
		hasIssueLayout: isProjectOrJiraAdmin || hasIssueLayoutPermissions,
		hasIssueTypes: isProjectOrJiraAdmin,
		hasJsdChangeManagement: hasChangeManagement,
		hasRequestTypes: fg('jcs_master_flag') ? hasRequestTypes : true,
		hasCustomerPermissions: fg('jcs_master_flag') ? hasCustomerPermissions : true,
		hasPortalSettings: fg('jcs_master_flag') ? hasPortalSettings : true,
		hasCustomerNotifications: fg('jcs_master_flag') ? hasCustomerNotifications : true,
		hasAddonInstaller,
		hasWidgetAddon: hasWidgetAddon && environment === 'prod',
		hasWidgetAddonDev: hasWidgetAddon && environment !== 'prod',
		hasChat,
		hasKnowledgeBase: fg('jcs_master_flag') ? hasKnowledgeBase : true,
		hasSla: true,
		hasLegacyJSMAutomation: true,
		hasJsdAppDiscovery: true,
		hasJsdIncidentManagement: hasIncidentManagement,
		hasWorkflows: true,
		hasVersions,
		hasComponents,
		hasPermissions: true,
		hasIssueSecurity: true,
		hasNotifications: true,
		hasIssueCollectors: true,
		hasProjectAutomation,
		hasDevtools: hasActiveSoftwareAccess && isProjectAdmin,
		hasExternalLinks,
		hasServices,
		hasCustomerSupportManagement,
		hasProductsAndEntitlements,
		hasDeveloperEscalation,
		hasVirtualAgent: fg('jcs_master_flag') ? hasVirtualAgent : true,
		hasLanguageSupport: fg('jcs_master_flag') ? hasLanguageSupport : true,
		hasForms: fg('jcs_master_flag') ? hasForms : true,
		hasFields: isProjectOrJiraAdmin,
		hasChannelsAndSelfService: fg('jcs_master_flag') ? hasChannelsAndSelfService : true,
	};
};

export const extractTMPSettingData = (
	navData: JiraServiceManagementNavData,
): TMPSettingFeatures => {
	let hasFields = false;

	const [expConfig] = UNSAFE_noExposureExp('efa_and_project_fields_page_for_tmp');
	if (expConfig.get('isEnabled', false)) {
		const { permissionInfo, hasExplicitFieldAssociationsEnabled = false } = navData;
		const { isAdmin, projectPermissionsQueried } = permissionInfo;
		const isProjectAdmin = projectPermissionsQueried.ADMINISTER_PROJECTS === true;
		const isProjectOrJiraAdmin = isAdmin || isProjectAdmin;

		hasFields = isProjectOrJiraAdmin && hasExplicitFieldAssociationsEnabled;
	}

	return {
		hasExternalLinks: navData?.settingsInfo?.hasExternalLinks,
		hasChat: navData?.settingsInfo?.hasChat,
		hasFields,
	};
};
