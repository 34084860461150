import { fetchState, type FetchState } from './fetch-state/index.tsx';

export const actions = {
	fetchState,
} as const;

export type StoreActions = typeof actions;

export type Actions = {
	fetchState: FetchState;
};
