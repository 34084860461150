import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	parameterModalErrorEmptyNumber: {
		id: 'automation-platform.automation-templates.number-parameter-modal.error.empty',
		defaultMessage: 'Please provide a number value for this parameter',
		description: 'The error text when a required number parameter is missing',
	},
	parameterModalErrorInvalidNumber: {
		id: 'automation-platform.automation-templates.number-parameter-modal.error.invalid-number',
		defaultMessage: 'Please provide a valid number value for this parameter',
		description: 'The error text when a number parameter has a non number value',
	},
});
