import React from 'react';
import { styled } from '@compiled/react';
import Tag from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { memoizeGetAutoGeneratedColorForLabelsTag } from '@atlassian/jira-option-color-picker/src/option-color-generator/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ColoredTag } from '@atlassian/jira-option-color-picker/src/colored-tag/index.tsx';
import { UnselectableChildGutterPaddingContainer } from '../styled/index.tsx';
import messages from './messages.tsx';
import type { TagData } from './types.tsx';

type Props = {
	isLastColumn: boolean;
	tags: TagData[];
	isColored?: boolean;
};

const Tags = ({ tags, isLastColumn, isColored }: Props) => {
	const { formatMessage } = useIntl();

	if (tags.length === 0) {
		return (
			<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
				<MessageWrapper>{formatMessage(messages.none)}</MessageWrapper>
			</UnselectableChildGutterPaddingContainer>
		);
	}

	if (!isVisualRefreshEnabled()) {
		if (isColored) {
			return (
				<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
					<TagWrapper>
						<TagGroup>
							{tags.map((tag, index) => (
								<ColoredTag key={index} labelName={tag.name} href={tag.href} />
							))}
						</TagGroup>
					</TagWrapper>
				</UnselectableChildGutterPaddingContainer>
			);
		}

		return (
			<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
				<TagWrapper>
					<TagGroup>
						{tags.map((tag, index) => (
							<Tag
								key={index}
								appearance="default"
								text={tag.name}
								href={tag.href}
								isRemovable={false}
							/>
						))}
					</TagGroup>
				</TagWrapper>
			</UnselectableChildGutterPaddingContainer>
		);
	}

	return (
		<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
			<TagWrapper>
				<TagGroup>
					{tags.map((tag, index) => (
						// @ts-expect-error - ts2322 - Type 'string' is not assignable to type 'TagColor'.
						<Tag
							key={index}
							appearance="default"
							text={tag.name}
							href={tag.href}
							isRemovable={false}
							{...(isColored ? { color: memoizeGetAutoGeneratedColorForLabelsTag(tag.name) } : {})}
						/>
					))}
				</TagGroup>
			</TagWrapper>
		</UnselectableChildGutterPaddingContainer>
	);
};

export default Tags;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TagWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${token('space.100')} 0 ${token('space.100')} -${gridSize / 2}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageWrapper = styled.div({
	paddingTop: token('space.100'),
	paddingRight: 0,
	paddingBottom: token('space.100'),
	paddingLeft: 0,
});
