import React, { Component, type ComponentType, createRef } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';

import ErrorIcon from '@atlaskit/icon/utility/error';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { JiraInlineDialog as InlineDialog } from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import { UnselectableChildGutterPaddingContainer } from '../styled/index.tsx';

type State = {
	dialogOpen: boolean;
	isHovered: boolean;
};

type Props = {
	isLastColumn: boolean;
	tooltipMessage: string;
	tooltipTitle: string | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Icon: ComponentType<any>;
	color: string;
	backgroundColor: string;
};

const DIALOG_MAX_WIDTH = gridSize * 36;
const TABLE_LEFT_MARGIN = gridSize * 5;

// eslint-disable-next-line jira/react/no-class-components
export default class Alert extends Component<Props, State> {
	static defaultProps = {
		isLastColumn: false,
		tooltipTitle: undefined,
	};

	state = {
		dialogOpen: false,
		isHovered: false,
	};

	onClick = () => this.setState({ dialogOpen: !this.state.dialogOpen });

	onMouseEnterContainer = () =>
		this.setState({
			...this.state,
			isHovered: true,
		});

	onMouseLeaveContainer = () =>
		this.setState({
			...this.state,
			isHovered: false,
		});

	calculateDialogPosition() {
		if (this.alertRef.current) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			const { left } = this.alertRef.current.getBoundingClientRect();

			if (
				this.alertRef.current &&
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				this.alertRef.current.offsetParent &&
				left >
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					this.alertRef.current.offsetParent.getBoundingClientRect().left +
						DIALOG_MAX_WIDTH +
						TABLE_LEFT_MARGIN
			) {
				return 'bottom-end';
			}
		}

		return 'bottom-start';
	}

	alertRef = createRef();

	renderDialogContent() {
		return (
			<DialogContainer>
				{this.props.tooltipTitle && <b>{this.props.tooltipTitle}</b>}
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>{this.props.tooltipMessage}</p>
			</DialogContainer>
		);
	}

	render() {
		const { isLastColumn, Icon, color, backgroundColor } = this.props;
		return !fg('handle-queues-column-exceptions') ? (
			<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
				<InlineDialog
					placement={this.calculateDialogPosition()}
					messageId="issue-table.common.fields.common.alert.inline-dialog"
					messageType="transactional"
					content={this.renderDialogContent()}
					isOpen={this.state.dialogOpen}
					onClose={this.onClick}
				>
					<Container
						onClick={this.onClick}
						color={color}
						backgroundColor={backgroundColor}
						/* @ts-expect-error - TS2322 - Type 'RefObject<unknown>' is not assignable to type '{ current: HTMLDivElement | null; }'. */
						ref={this.alertRef}
						tabIndex={0}
						onMouseEnter={this.onMouseEnterContainer}
						onMouseLeave={this.onMouseLeaveContainer}
						isHovered={this.state.isHovered}
					>
						<Box xcss={IconContainer}>
							<Icon color={color} />
						</Box>
					</Container>
					<Container
						onClick={this.onClick}
						color={color}
						backgroundColor={backgroundColor}
						/* @ts-expect-error - TS2322 - Type 'RefObject<unknown>' is not assignable to type '{ current: HTMLDivElement | null; }'. */
						ref={this.alertRef}
						tabIndex={0}
					>
						<Box xcss={IconContainer}>
							<Icon color="currentColor" />
						</Box>
					</Container>
				</InlineDialog>
			</UnselectableChildGutterPaddingContainer>
		) : (
			<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
				<InlineDialog
					messageId="issue-table.common.fields.common.alert.inline-dialog.1"
					messageType="transactional"
					placement={this.calculateDialogPosition()}
					content={this.renderDialogContent()}
					isOpen={this.state.dialogOpen}
					onClose={this.onClick}
				>
					<ErrorContainer
						onClick={this.onClick}
						color={color}
						backgroundColor={backgroundColor}
						/* @ts-expect-error - TS2322 - Type 'RefObject<unknown>' is not assignable to type '{ current: HTMLDivElement | null; }'. */
						ref={this.alertRef}
						tabIndex={0}
						onMouseEnter={this.onMouseEnterContainer}
						onMouseLeave={this.onMouseLeaveContainer}
						isHovered={this.state.isHovered}
						isLastColumn={isLastColumn}
					>
						<Box xcss={iconContainerStyles}>
							<ErrorIcon label="" />
						</Box>
					</ErrorContainer>
				</InlineDialog>
			</UnselectableChildGutterPaddingContainer>
		);
	}
}

interface ContainerProps {
	color: string;
	backgroundColor: string;
	isHovered?: boolean;
}

interface ErrorContainerProps {
	color: string;
	backgroundColor: string;
	isHovered?: boolean;
	isLastColumn: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.button<ContainerProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: ({ color }) => `2px solid ${color}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ color }) => color,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	borderRadius: '6px',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 5}px`,
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isHovered }) => (isHovered ? 'pointer' : 'auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorContainer = styled.button<ErrorContainerProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isLastColumn }) =>
		`0px ${isLastColumn ? token('space.100') : token('space.300')} 0px ${token('space.100')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: ({ color }) => `2px solid ${color}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ color }) => color,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	borderRadius: '6px',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 5}px`,
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isHovered }) => (isHovered ? 'pointer' : 'auto'),
	display: 'flex',
	alignItems: 'center',
});

const iconContainerStyles = xcss({
	marginLeft: 'space.200',
});

const IconContainer = xcss({
	marginTop: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 36}px`,
});
