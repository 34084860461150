import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	errorTitle: {
		id: 'automation-platform.error.title',
		defaultMessage: "We're having some trouble",
		description: 'Title to display to the user when the error state is shown.',
	},
	errorDescription: {
		id: 'automation-platform.error.desc',
		defaultMessage:
			"It's taking us longer than expected to show this content. It's probably a temporary problem. Try refreshing or navigating to another page.",
		description: 'Description to display to the user when the error state is shown.',
	},
});
