import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	failureFlagTitle: {
		id: 'servicedesk-queues-automation-menu.automation-menu.failure-flag.failure-flag-title',
		defaultMessage: "We couldn't create your automation rule",
		description: 'Title of a failure flag after automation rule fails to be created',
	},
	failureFlagDescription: {
		id: 'servicedesk-queues-automation-menu.automation-menu.failure-flag.failure-flag-description',
		defaultMessage: 'Try again from the automation template.',
		description: 'Description of a failure flag after automation rule fails to be created',
	},
	failureFlagAction: {
		id: 'servicedesk-queues-automation-menu.automation-menu.failure-flag.failure-flag-action',
		defaultMessage: 'Go to automation template',
		description:
			'Title of a link to redirect a user to a template after automation rule fails to be created',
	},
});

export default messages;
