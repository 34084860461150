import { Environment } from '../types';

import type { DevStack, StacksResponse } from './types';

export const AUTOMATION_LOCAL_STORAGE_PREFIX = 'automationPlatform';
export const AUTOMATION_LOCAL_STORAGE_STACK = 'automationStack';
export const AUTOMATION_DEV_STACK_URL = 'https://localhost:8082/devStack.json';

export const getAutomationProdStackUrl = (cloudId: string) =>
	`/gateway/api/automation/internal-api/jira/${cloudId}/pro/rest/environments`;

const getLatestPreProdStack = async (cloudId: string): Promise<string | null> => {
	try {
		const response = await fetch(getAutomationProdStackUrl(cloudId));
		const stacksResponse = (await response.json()) as StacksResponse;
		const preProdStacks =
			stacksResponse.prod?.otherStacks
				.filter(({ releasedSuccessfully }) => !releasedSuccessfully)
				.map(({ stack }) => stack) ?? [];
		const [latestPreProdStack = null] = preProdStacks;
		return latestPreProdStack;
	} catch (e) {
		// eslint-disable-next-line no-console
		console.warn(
			`Failed to fetch pre-prod stack from ${getAutomationProdStackUrl(cloudId)} with error: ${e}`,
		);
		return null;
	}
};

const _getAutomationKeyFromLocalStorage = (key: string) => {
	return window.localStorage.getItem(`${AUTOMATION_LOCAL_STORAGE_PREFIX}:${key}`);
};

const getAutomationDevStackFromLocalServer = async () => {
	try {
		const devStackResponse = await fetch(AUTOMATION_DEV_STACK_URL);
		const devStack: DevStack = await devStackResponse.json();
		return devStack.name;
	} catch (e) {
		// eslint-disable-next-line no-console
		console.warn(`Failed to fetch dev stack from ${AUTOMATION_DEV_STACK_URL} with error: ${e}`);
		return null;
	}
};
/**
 * Utility function for retrieving the devSlug stored in local storage, if any. This is stored under key
 * `automationPlatform:automationStack`. An application consuming this package can control this
 * dev slug by writing a value to the above key.
 * @returns the specified value as per local storage if one exists, `null` otherwise
 */
export const getAutomationStackFromLocalStorage = () =>
	_getAutomationKeyFromLocalStorage(AUTOMATION_LOCAL_STORAGE_STACK);
/**
 * Utility function for resolving an automation devSlug according to the supplied environment and cloudId.
 * The base path for automation changes based on the target environment. Any non-prod environment
 * requires a devSlug to be specified in the base path. This utility will resolve the appropriate
 * devSlug for use in the base path for you.
 * @param env - The target environment to resolve a devSlug for.
 * @param cloudId - The target cloudId, used to retrieve pre-prod dev slugs if necessary.
 * @returns
 * - `pro` if the `env` passed is of type {@link Environment.PROD}
 * - a given build devSlug if `env` passed is of type {@link Environment.PRE_PROD}, and a pre prod stack exists
 * - a given dev stack name (e.g. `devltan3`) if `env` passed is of type {@link Environment.DEV}, and either
 *   the devWatch local server can resolve a devSlug, or one is set in local storage. Resolved `staging`
 *   otherwise.
 * - `staging` if `env` passed is of type {@link Environment.STAGING}.
 */
export const getAutomationStack = async (env: Environment, cloudId: string): Promise<string> => {
	if (env === Environment.PROD) {
		return 'pro';
	}

	if (env === Environment.PRE_PROD) {
		const stack = await getLatestPreProdStack(cloudId);
		return stack ?? 'pro';
	}
	if (env === Environment.STAGING) {
		return 'staging';
	}
	// We fetch the dev stack name from local server (barrel webpack dev server) if it's available.
	// However, this requires that devWatch is running locally.
	// To use manual triggers without running devWatch, set the local storage value to your stack name e.g. devltan3. The full key is: automationPlatform:automationStack
	const stackOverride =
		(await getAutomationDevStackFromLocalServer()) || getAutomationStackFromLocalStorage();
	return stackOverride || 'staging';
};
