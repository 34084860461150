import React, { type ComponentType } from 'react';

import { type IconProps } from '@atlaskit/icon';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import ArchiveIcon from '@atlaskit/icon/core/migration/archive-box--archive';
import MoveIcon from '@atlaskit/icon/core/migration/arrow-right';
import BitbucketBranchIcon from '@atlaskit/icon/core/migration/branch--bitbucket-branches';
import CalendarIcon from '@atlaskit/icon/core/migration/calendar';
import CheckCircleOutlineIcon from '@atlaskit/icon/core/migration/check-circle--check-circle-outline';
import ChildIssuesIcon from '@atlaskit/icon/core/migration/child-issues';
import EmojiFrequentIcon from '@atlaskit/icon/core/migration/clock--emoji-frequent';
import UploadIcon from '@atlaskit/icon/core/migration/cloud-arrow-up--upload';
import CommentIcon from '@atlaskit/icon/core/migration/comment';
import MediaServicesAddCommentIcon from '@atlaskit/icon/core/migration/comment-add--media-services-add-comment';
import CommitIcon from '@atlaskit/icon/core/migration/commit--bitbucket-commits';
import CopyIcon from '@atlaskit/icon/core/migration/copy';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import EditFilledIcon from '@atlaskit/icon/core/migration/edit--edit-filled';
import EmailIcon from '@atlaskit/icon/core/migration/email';
import EmojiAddIcon from '@atlaskit/icon/core/migration/emoji-add';
import WatchIcon from '@atlaskit/icon/core/migration/eye-open--watch';
import WatchFilledIcon from '@atlaskit/icon/core/migration/eye-open-filled--watch-filled';
import DocumentFilledIcon from '@atlaskit/icon/core/migration/file--document-filled';
import IssueIcon from '@atlaskit/icon/core/migration/issue';
import IssuesIcon from '@atlaskit/icon/core/migration/issues';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import BulletListIcon from '@atlaskit/icon/core/migration/list-bulleted--bullet-list';
import LockIcon from '@atlaskit/icon/core/migration/lock-locked--lock';
import NotificationIcon from '@atlaskit/icon/core/migration/notification';
import PageIcon from '@atlaskit/icon/core/migration/page';
import MediaServicesDocumentIcon from '@atlaskit/icon/core/migration/page--media-services-document';
import PersonIcon from '@atlaskit/icon/core/migration/person';
import AppAccessIcon from '@atlaskit/icon/core/migration/person-added--app-access';
import PersonCircleIcon from '@atlaskit/icon/core/migration/person-avatar--person-circle';
import IssueRaiseIcon from '@atlaskit/icon/core/migration/plus-square--issue-raise';
import BitbucketPullrequestsIcon from '@atlaskit/icon/core/migration/pull-request--bitbucket-pullrequests';
import QuoteIcon from '@atlaskit/icon/core/migration/quotation-mark--quote';
import ShipIcon from '@atlaskit/icon/core/migration/release--ship';
import SearchIcon from '@atlaskit/icon/core/migration/search';
import SprintIcon from '@atlaskit/icon/core/migration/sprint';
import LabelIcon from '@atlaskit/icon/core/migration/tag--label';
import TaskIcon from '@atlaskit/icon/core/migration/task';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { AwsIcon, MsTeamsIcon, SlackIcon } from '@atlaskit/legacy-custom-icons';

import {
	AnnotateIcon,
	BitbucketIcon,
	CompareIcon,
	CompassBlueIcon,
	ConfluenceIcon,
	FieldValueChangedIcon,
	ForkIcon,
	GitHubIcon,
	GitLabIcon,
	JiraBlueIcon,
	JiraServiceDeskIcon,
	JiraSoftwareIcon,
	LdCreateFeatureFlagIcon,
	ManualTriggerIcon,
	ProductDiscoveryIcon,
	SecurityIcon,
	SmartValueIcon,
	TransitionIcon,
} from './assets';
import { type IconId } from './types';

// All icons in this package were firstly defined in the rule-library-icons.ts in the barrel repository.
// When adding a new icon, make sure to define it in the barrel file as well.
const ruleLibraryIcons: {
	[id in IconId]: React.ComponentType<IconProps>;
} = {
	SoftwareIcon: JiraSoftwareIcon,
	ServiceDeskIcon: JiraServiceDeskIcon,
	MediaServicesAddCommentIcon,
	MediaServicesAnnotateIcon: AnnotateIcon,
	MediaServicesDocumentIcon,
	EditFilledIcon,
	PersonCircleIcon,
	LinkIcon,
	TransitionIcon,
	ConfluenceIcon,
	DocumentFilledIcon,
	LabelIcon,
	ArchiveIcon,
	IssueIcon,
	EmailIcon,
	QuoteIcon,
	SlackIcon,
	MsTeams: MsTeamsIcon,
	LaunchDarklyIcon: LdCreateFeatureFlagIcon,
	GitLabIcon,
	BitbucketIcon,
	GitHubIcon,
	CheckCircleOutlineIcon,
	TaskIcon,
	PageIcon,
	WatchFilledIcon,
	CalendarIcon,
	IssueRaiseIcon,
	SecurityIcon,
	NotificationIcon,
	AwsIcon,
	WarningIcon,
	UploadIcon,
	ManualTriggerIcon,
	LockIcon,
	EmojiFrequentIcon,
	ProductDiscoveryIcon,
	EmojiAddIcon,
	EditorAddIcon,
	SmartValueIcon,
	ChildIssuesIcon,
	MoveIcon,
	JiraBlueIcon,
	PersonIcon,
	CommentIcon,
	CopyIcon,
	WatchIcon,
	TrashIcon,
	BitbucketPullrequestsIcon,
	ShipIcon,
	BitbucketBranchIcon,
	BitbucketCompareIcon: CompareIcon,
	CommitIcon,
	SearchIcon,
	IssuesIcon,
	BulletListIcon,
	FieldValueChangedIcon,
	BitbucketForksIcon: ForkIcon,
	SprintIcon,
	AppAccessIcon,
	CompassBlueIcon,
};

const crossProductIcons = new Set<React.ComponentType<IconProps>>([
	ruleLibraryIcons.SoftwareIcon,
	ruleLibraryIcons.SlackIcon,
	ruleLibraryIcons.GitHubIcon,
	ruleLibraryIcons.GitLabIcon,
	ruleLibraryIcons.LaunchDarklyIcon,
	ruleLibraryIcons.ConfluenceIcon,
	ruleLibraryIcons.AwsIcon,
	ruleLibraryIcons.ServiceDeskIcon,
	ruleLibraryIcons.JiraBlueIcon,
	ruleLibraryIcons.CompassBlueIcon,
]);

export function getIconByDisplayMetadata(iconId: IconId): ComponentType<IconProps> {
	return ruleLibraryIcons[iconId];
}
export const isCrossProductIcon = (icon: React.ComponentType<IconProps>) =>
	crossProductIcons.has(icon);
