import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import Form, { ErrorMessage, FormSection } from '@atlaskit/form';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';

import type {
	CreateRuleFromTemplatePayload,
	TemplateParameter,
	TemplateParameterBooleanValue,
	TemplateParameterNumberValue,
	TemplateParameterTextValue,
} from '../../../services/template/types';

import BooleanParameterInput from './boolean-parameter-input';
import { messages } from './messages';
import NumberParameterInput from './number-parameter-input';
import TextParameterInput from './text-parameter-input';
import { type TargetRuleCreation } from './types';

export interface TemplateParameterFormProps {
	targetRuleCreation: TargetRuleCreation;
	clearSelectedTemplate: () => any;
	createRule: (parameters: CreateRuleFromTemplatePayload['parameters']) => Promise<void>;

	onInputsModalOpened?: () => void;
	onInputsModalClosed?: () => void;
}

const TemplateParameterForm = (props: TemplateParameterFormProps) => {
	const {
		targetRuleCreation,
		clearSelectedTemplate,
		createRule,
		onInputsModalOpened,
		onInputsModalClosed,
	} = props;
	const handleSubmit = (data: Record<string, string | number | boolean>) => {
		const parameters = _transformInputs(data);
		return createRule(parameters);
	};
	const _transformInputs = (
		data: Record<string, string | number | boolean | undefined>,
	): Record<
		string,
		TemplateParameterTextValue | TemplateParameterNumberValue | TemplateParameterBooleanValue
	> => {
		return Object.entries(data).reduce((prev, [key, value]) => {
			const parameter = targetRuleCreation.template.parameters.find(
				(parameter: TemplateParameter) => parameter.key === key,
			);
			// If for some reason we didn't find a matching parameter (some odd entry into the form)
			// then ignore this key.
			if (!parameter) {
				return prev;
			}
			return {
				...prev,
				[key]: {
					type: parameter.type,
					value,
				},
			};
		}, {});
	};

	const handleOpen = () => {
		if (onInputsModalOpened) {
			onInputsModalOpened();
		}
	};

	const handleClose = () => {
		clearSelectedTemplate();
		if (onInputsModalClosed) {
			onInputsModalClosed();
		}
	};

	const generateForm = (parameter: TemplateParameter) => {
		switch (parameter.type) {
			case 'TEXT':
				return (
					<TextParameterInput
						key={parameter.key}
						name={parameter.key}
						required={parameter.required}
					/>
				);
			case 'NUMBER':
				return (
					<NumberParameterInput
						key={parameter.key}
						name={parameter.key}
						required={parameter.required}
					/>
				);
			case 'BOOLEAN':
				return <BooleanParameterInput key={parameter.key} name={parameter.key} />;
			default:
				return (
					<>
						<br />
						<ErrorMessage>
							<FormattedMessage {...messages.parameterModalErrorInvalidField} />
						</ErrorMessage>
						<br />
					</>
				);
		}
	};

	return (
		<Modal data-testid="template-input-form" onClose={handleClose} onOpenComplete={handleOpen}>
			<Form<Record<string, string | number | boolean>> onSubmit={handleSubmit}>
				{({ formProps, submitting }: any) => (
					<form {...formProps}>
						<ModalHeader>
							<ModalTitle>{targetRuleCreation.template.description}</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<FormattedMessage {...messages.parameterModalDescription} />
							<FormSection>{targetRuleCreation.template.parameters.map(generateForm)}</FormSection>
						</ModalBody>
						<ModalFooter>
							<Button
								appearance="subtle"
								onClick={handleClose}
								isDisabled={submitting}
								testId="template-parameters-form-cancel"
							>
								<FormattedMessage {...messages.parameterModalCancel} />
							</Button>
							<Button
								appearance="primary"
								type="submit"
								isLoading={submitting}
								autoFocus
								testId="template-parameters-form-submit"
							>
								<FormattedMessage {...messages.parameterModalSubmit} />
							</Button>
						</ModalFooter>
					</form>
				)}
			</Form>
		</Modal>
	);
};

export default TemplateParameterForm;
