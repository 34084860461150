import flow from 'lodash/flow';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { resetSortOrderAction } from '../../../../../state/actions/table/index.tsx';
import ResetSortingButton from './view.tsx';

export default flow(
	connect(null, {
		onResetSorting: resetSortOrderAction,
	}),
)(ResetSortingButton);
