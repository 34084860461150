import React, { type ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
import omit from 'lodash/omit';
import type { ButtonProps } from '@atlaskit/button';
import Button from '@atlaskit/button/standard-button';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import EmojiObjectsIcon from '@atlaskit/icon/core/migration/lightbulb--emoji-objects';
import { token } from '@atlaskit/tokens';
import { type IntlShape, injectIntl } from '@atlassian/jira-intl/src/index.tsx';
import { PurpleButton } from '../purple-button/index.tsx';
import messages from './messages.tsx';

export type SpotlightButtonProps = {
	text: string;
} & ButtonProps;

export type Props = {
	heading?: string;
	children: ReactNode;
	steps?: {
		current: number;
		count: number;
	};
	buttons: {
		primary?: SpotlightButtonProps;
		secondary?: SpotlightButtonProps;
		close?: ButtonProps;
	};
	infoBox?: string;
};

type PropsWithIntl = Props & {
	intl: IntlShape;
};

export const ItsmSpotlightCard = injectIntl(
	({ heading, steps, buttons, children, intl, infoBox }: PropsWithIntl) => {
		const hasFooter = buttons.primary || buttons.secondary || steps;
		return (
			<SpotlightContent>
				<Body>
					<TextContent>
						{heading !== undefined && (
							<StyledTitleWrapper>
								<CustomHeading>{heading}</CustomHeading>
							</StyledTitleWrapper>
						)}
						{children}
					</TextContent>
					{buttons.close && (
						<Button spacing="none" appearance="link" {...buttons.close}>
							<CrossIcon
								label={intl.formatMessage(messages.exitTourButtonLabel)}
								color={token('color.icon')}
								LEGACY_size="small"
								spacing="none"
							/>
						</Button>
					)}
				</Body>
				{infoBox !== undefined && (
					<InfoBox>
						{}
						{/* @ts-expect-error - TS2769 - No overload matches this call. */}
						<EmojiObjectsIcon spacing="spacious" color={token('color.icon.discovery')} />
						<InfoBoxContent>{infoBox}</InfoBoxContent>
					</InfoBox>
				)}
				{hasFooter && (
					<Footer>
						{steps && (
							<Position>
								{steps.current}/{steps.count}
							</Position>
						)}
						{buttons.secondary && (
							<PurpleButton appearance="subtle-link" {...omit(buttons.secondary, 'text')}>
								{buttons.secondary && buttons.secondary.text}
							</PurpleButton>
						)}
						{buttons.primary && (
							<PurpleButton autoFocus appearance="primary" {...omit(buttons.primary, 'text')}>
								{buttons.primary && buttons.primary.text}
							</PurpleButton>
						)}
					</Footer>
				)}
			</SpotlightContent>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Body = styled2.div({
	display: 'flex',
	alignItems: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div + p': {
		marginTop: token('space.200'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	p: {
		font: token('font.body'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:nth-of-type(n+2)': {
			marginTop: token('space.200'),
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:has(+ *)': {
			marginBottom: token('space.200'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextContent = styled2.div({
	paddingRight: token('space.100'),
	flex: '1 1 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoBoxContent = styled2.p({
	flex: '1 1 auto',
	marginTop: 0,
	marginRight: 0,
	marginBottom: 0,
	marginLeft: token('space.150'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '24px',

	color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpotlightContent = styled2.div({
	color: token('color.text'),

	backgroundColor: token('elevation.surface'),
	marginTop: token('space.negative.200'),
	marginBottom: token('space.negative.200'),
	marginLeft: token('space.negative.250'),
	marginRight: token('space.negative.250'),
	paddingTop: token('space.200'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledTitleWrapper = styled2.div({
	marginBottom: token('space.200'),
});

// Cannot use atlaskit/heading due to automatic color setting that breaks due to overriding Spotlight background color above.
// This whole custom spotlight gets applied as children to @atlaskit/onboarding (see jira/src/packages/project-settings/issue-type-page-product-features-jsm-cmp/src/ui/sidebar/fvh-changeboarding-tour/1-intro-spotlight/index.tsx)
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CustomHeading = styled2.h4({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.heading.small'),

	color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Footer = styled2.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	marginTop: token('space.400'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Position = styled2.div({
	flex: '1 0 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
	font: token('font.body.small'),
	fontFamily: token('font.family.code'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoBox = styled2.div({
	display: 'flex',
	paddingTop: token('space.200'),
	paddingRight: token('space.250'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.150'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.discovery'),
	marginTop: token('space.400'),
	borderRadius: '5px',
});
