import { useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type ProjectType,
	CUSTOMER_SERVICE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useCalendarFilterResource } from '@atlassian/jira-router-resources-servicedesk-calendar/src/services/filters/index.tsx';
import { TARGET_FILTER_TYPE as REQUEST_TYPE_FILTER_TYPE } from '@atlassian/jira-router-resources-servicedesk-calendar/src/services/filters/utils.tsx';
import {
	TYPE,
	ASSIGNEE_FIELD_JQL_TERM,
	STATUS_FIELD_JQL_TERM,
} from '@atlassian/jira-jql-builder-basic/src/common/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { IrremovableFieldsConfig } from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import messages from './messages.tsx';

export const useQueuesFilters = ({
	isSimplified,
	projectType,
}: {
	isSimplified: boolean;
	projectType?: ProjectType;
}) => {
	const { data: classicProjectFilters } = useCalendarFilterResource();
	const { formatMessage } = useIntl();

	// Translate the request type filter, as resource doesnot allow for i18n
	const classicProjectFiltersI18n = classicProjectFilters?.map((item) => {
		if (typeof item === 'object' && Object.values(item).includes(REQUEST_TYPE_FILTER_TYPE)) {
			return {
				...item,
				displayName: formatMessage(messages.requestType),
			};
		}

		return item;
	});

	const simplifiedProjectFilters: IrremovableFieldsConfig = useMemo(
		() => [
			{
				...TYPE,
				displayName: formatMessage(messages.requestType),
			},
			STATUS_FIELD_JQL_TERM,
			ASSIGNEE_FIELD_JQL_TERM,
		],
		[formatMessage],
	);

	const jcsProjectFilters: IrremovableFieldsConfig = useMemo(
		() => [STATUS_FIELD_JQL_TERM, ASSIGNEE_FIELD_JQL_TERM],
		[],
	);

	if (projectType === CUSTOMER_SERVICE_PROJECT && fg('jcs_project_type_m3')) {
		return jcsProjectFilters;
	}

	if (isSimplified) {
		return simplifiedProjectFilters;
	}

	return classicProjectFiltersI18n;
};
