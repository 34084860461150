import React from 'react';
import { styled } from '@compiled/react';

export const ServiceDeskGlyph = () => (
	<svg width="32" fill="none" viewBox="0 0 32 32" focusable="false" role="presentation">
		<rect width="32" height="32" />
		<path
			d="m18.066 12.045 7.4011 0.037c0.9341 0 1.473 1.1081 0.9341 1.8839l-10.922 15.034c-3.449-2.6227-4.1317-7.6465-1.5808-11.193l4.1675-5.7626z"
			fill="url(#a)"
		/>
		<path
			d="m13.898 17.807-7.3651-0.037c-0.93411 0-1.473-1.1081-0.93411-1.8839l10.85-14.886c3.449 2.6227 4.1316 7.6464 1.5808 11.193l-4.1317 5.6148z"
			fill="#2684FF"
		/>
		<defs>
			<linearGradient id="a" x1="17.786" x2="21.058" y1="13.178" y2="22.799">
				<ServiceDeskGradientStopLightBlue offset="0%" />
				<ServiceDeskGradientStopDarkBlue offset="100%" />
			</linearGradient>
		</defs>
	</svg>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ServiceDeskGradientStopLightBlue = styled.stop({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	stopColor: '#0052CC !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ServiceDeskGradientStopDarkBlue = styled.stop({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	stopColor: '#2684FF !important',
});
