import React from 'react';

import { type IconProps } from '@atlaskit/icon';

import { CustomIcon } from './custom-icon';

// Because this is a logo it should be presented on white and design tokens are not used
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const GitLabGlyph: React.FunctionComponent = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_61_46)">
			<path
				d="M23.6005 9.14904L23.5668 9.06281L20.3002 0.537693C20.2337 0.370605 20.116 0.228863 19.964 0.132806C19.8119 0.0383803 19.6345 -0.0070981 19.4557 0.00251117C19.2769 0.0121205 19.1054 0.0763547 18.9643 0.186541C18.8248 0.299891 18.7235 0.453483 18.6744 0.626418L16.4688 7.37454H7.53749L5.33186 0.626418C5.28402 0.452541 5.18257 0.298179 5.04194 0.185291C4.90083 0.0751048 4.7293 0.0108705 4.55053 0.00126126C4.37175 -0.00834802 4.19433 0.0371304 4.04222 0.131556C3.89054 0.228001 3.77297 0.369619 3.70606 0.536443L0.433225 9.05782L0.400733 9.14404C-0.069507 10.3727 -0.127551 11.7209 0.235354 12.9855C0.598259 14.25 1.36244 15.3623 2.41267 16.1546L2.42392 16.1633L2.45391 16.1846L7.43002 19.911L9.89184 21.7743L11.3914 22.9065C11.5668 23.0396 11.781 23.1117 12.0012 23.1117C12.2215 23.1117 12.4357 23.0396 12.6111 22.9065L14.1107 21.7743L16.5725 19.911L21.5786 16.1621L21.5911 16.1521C22.639 15.3596 23.4014 14.2485 23.7637 12.9857C24.1261 11.7229 24.0688 10.3765 23.6005 9.14904V9.14904Z"
				fill="#E24329"
			/>
			<path
				d="M23.6005 9.14904L23.5668 9.06282C21.9751 9.38953 20.4752 10.0637 19.1742 11.0373L12 16.462C14.4431 18.3102 16.57 19.916 16.57 19.916L21.5761 16.1671L21.5886 16.1571C22.638 15.3647 23.4015 14.2528 23.7644 12.9889C24.1272 11.725 24.0697 10.3774 23.6005 9.14904Z"
				fill="#FC6D26"
			/>
			<path
				d="M7.43003 19.916L9.89185 21.7793L11.3914 22.9114C11.5668 23.0446 11.781 23.1167 12.0013 23.1167C12.2215 23.1167 12.4357 23.0446 12.6111 22.9114L14.1107 21.7793L16.5725 19.916C16.5725 19.916 14.4431 18.3052 12 16.462C9.55694 18.3052 7.43003 19.916 7.43003 19.916Z"
				fill="#FCA326"
			/>
			<path
				d="M4.8245 11.0373C3.5246 10.0617 2.02503 9.38577 0.433225 9.05782L0.400734 9.14404C-0.0695071 10.3727 -0.127551 11.7209 0.235354 12.9855C0.598259 14.25 1.36244 15.3623 2.41267 16.1546L2.42392 16.1633L2.45391 16.1846L7.43002 19.911C7.43002 19.911 9.55443 18.3052 12 16.457L4.8245 11.0373Z"
				fill="#FC6D26"
			/>
		</g>
		<defs>
			<clipPath id="clip0_61_46">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export const GitLabIcon: React.FunctionComponent<IconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <GitLabGlyph />} />
);
