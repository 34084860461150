import React, { useMemo, useRef, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { LinkItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import Icon from '@atlaskit/icon';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import EmailIcon from '@atlaskit/icon/core/migration/email';
import ClockIcon from '@atlaskit/icon/core/migration/clock--emoji-frequent';
import LockIcon from '@atlaskit/icon/core/migration/lock-locked--lock';
import PersonGlyph from '@atlaskit/icon/core/migration/person-avatar--person-circle';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../../common/constants.tsx';
import {
	buildDiscoverAutomationUrl,
	buildTemplateRuleAutomationUrl,
} from '../../../common/utils/automation-urls.tsx';
import { useTemplateIds } from '../../../services/menu-state/index.tsx';
import { ServiceDeskGlyph } from '../../../common/assets/service-desk-glyph.tsx';
import messages from './messages.tsx';
import type { TemplateKey, LinkItemType, TemplateMap } from './types.tsx';

import { TemplateIds } from './utils.tsx';

type Props = {
	onLinkClick: () => void;
};

const PersonIcon = <PersonGlyph LEGACY_size="medium" spacing="spacious" label="" />;
const ServiceDeskIcon = <Icon size="medium" glyph={ServiceDeskGlyph} label="" />;
const Email = <EmailIcon LEGACY_size="medium" spacing="spacious" label="" />;
const Clock = <ClockIcon LEGACY_size="medium" spacing="spacious" label="" />;
const Lock = <LockIcon LEGACY_size="medium" spacing="spacious" label="" />;

const ActionAndTriggerIcons = ({ action, trigger }: { action: ReactNode; trigger: ReactNode }) => (
	<>
		<IconShadow>{action}</IconShadow>
		<ArrowRightIcon spacing="spacious" label="" />
		<IconShadow>{trigger}</IconShadow>
	</>
);

export const TemplateRules = ({ onLinkClick }: Props) => {
	const { data: project } = useProjectContext();
	const projectKey = toProjectKey(`${project?.projectKey}`);
	const isSimplified = !!project?.simplified;
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const templateIds = useTemplateIds();
	const renderDepsRef = useRef<{ onLinkClick: () => void }>();
	renderDepsRef.current = {
		onLinkClick,
	};

	return useMemo(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'link',
		});

		const DiscoverMoreLink = (
			<DiscoverAutomationLink
				{...(fg('concurrent-rendering-fix-unique-list-key') ? { key: 'discover-more-link' } : {})}
				href={buildDiscoverAutomationUrl({
					projectKey,
					isSimplified,
				})}
				onClick={() => {
					fireUIAnalytics(analyticsEvent, 'discoverAutomationLink');
					renderDepsRef.current?.onLinkClick();
				}}
			>
				{formatMessage(messages.discoverAutomationMenuItemLabel)}
			</DiscoverAutomationLink>
		);

		const templatesMap: TemplateMap = {
			[TemplateIds.GENERAL_TEMPLATE_1]: {
				icon: <ActionAndTriggerIcons action={Clock} trigger={Email} />,
				message: fg('itsm-issue-renaming')
					? messages.itsmTemplateOneIssueTermRefresh
					: messages.itsmTemplateOne,
			},
			[TemplateIds.GENERAL_TEMPLATE_2]: {
				icon: <ActionAndTriggerIcons action={ServiceDeskIcon} trigger={Lock} />,
				message: messages.itsmTemplateTwo,
			},
			[TemplateIds.GENERAL_TEMPLATE_3]: {
				icon: <ActionAndTriggerIcons action={ServiceDeskIcon} trigger={PersonIcon} />,
				message: messages.itsmTemplateThree,
			},
			[TemplateIds.HR_TEMPLATE_1]: {
				icon: <ActionAndTriggerIcons action={Clock} trigger={Email} />,
				message: messages.hrTemplateOne,
			},
			[TemplateIds.HR_TEMPLATE_2]: {
				icon: <ActionAndTriggerIcons action={ServiceDeskIcon} trigger={Lock} />,
				message: messages.hrTemplateTwo,
			},
			[TemplateIds.HR_TEMPLATE_3]: {
				icon: <ActionAndTriggerIcons action={ServiceDeskIcon} trigger={PersonIcon} />,
				message: messages.hrTemplateThree,
			},
		};

		const rules = templateIds.reduce((templates: LinkItemType[], templateId: TemplateKey) => {
			const template = templatesMap[templateId];
			if (!templateId || !template) {
				fireErrorAnalytics({
					meta: {
						id: 'templateRules',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: {
						templateId,
					},
				});
				return templates;
			}

			const linkItem: LinkItemType = (
				<LinkItem
					key={templateId}
					shouldTitleWrap
					href={buildTemplateRuleAutomationUrl(
						{
							projectKey,
							isSimplified,
						},
						templateId,
					)}
					iconBefore={template.icon}
					onClick={() => {
						fireUIAnalytics(analyticsEvent, 'automationTemplateRulesClicked', {
							templateId,
						});
						renderDepsRef.current?.onLinkClick();
					}}
					UNSAFE_shouldDisableRouterLink
				>
					{formatMessage(template.message)}
				</LinkItem>
			);

			templates.push(linkItem);
			return templates;
		}, []);

		rules.push(DiscoverMoreLink);

		return <>{rules}</>;
	}, [createAnalyticsEvent, templateIds, projectKey, isSimplified, formatMessage]);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DiscoverAutomationLink = styled.a({
	paddingTop: token('space.200'),
	paddingRight: '0px',
	paddingBottom: token('space.200'),
	paddingLeft: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconShadow = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${4 * gridSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${4 * gridSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${0.5 * gridSize}px`,
	boxShadow: token('elevation.shadow.raised'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
