import React from 'react';

import { type IconProps } from '@atlaskit/icon';

import { CustomIcon } from './custom-icon';

// Because this is a logo it should be presented on white and design tokens are not used
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const ConfluenceGlyph: React.FunctionComponent = () => (
	<svg width="33" height="32" viewBox="0 0 33 32" fill="none" role="presentation">
		<rect width="32" height="32" transform="translate(0.75)" fill="white" fillOpacity="0.01" />
		<path
			d="M6.29647 21.0344C6.0813 21.4137 5.79441 21.8275 5.57924 22.1723C5.36407 22.4827 5.47166 22.8965 5.83027 23.1034L10.4922 25.862C10.8508 26.0689 11.2812 25.9654 11.4963 25.6206C11.6757 25.3103 11.9267 24.931 12.1777 24.5172C14.0425 21.5861 15.8714 21.931 19.2424 23.4827L23.8685 25.5861C24.2271 25.7585 24.6574 25.5861 24.8367 25.2413L27.0601 20.4137C27.2036 20.0689 27.0601 19.6551 26.7015 19.5172C25.7333 19.0689 23.7968 18.2068 22.0396 17.3792C15.728 14.4827 10.3847 14.6551 6.29647 21.0344Z"
			fill="url(#paint0_linear_634_156)"
		/>
		<path
			d="M27.2035 10.1034C27.4187 9.72411 27.7056 9.31032 27.9207 8.96549C28.1359 8.65514 28.0283 8.24135 27.6697 8.03445L23.0078 5.27583C22.6491 5.06894 22.2188 5.17239 22.0036 5.51721C21.8243 5.82756 21.5733 6.20687 21.3223 6.62066C19.4575 9.5517 17.6286 9.20687 14.2576 7.65514L9.66737 5.51721C9.30875 5.3448 8.87842 5.51721 8.69911 5.86204L6.47571 10.6896C6.33227 11.0345 6.47571 11.4482 6.83433 11.5862C7.80258 12.0345 9.73909 12.8965 11.4963 13.7241C17.772 16.6551 23.1153 16.4827 27.2035 10.1034Z"
			fill="url(#paint1_linear_634_156)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_634_156"
				x1="26.9105"
				y1="27.2939"
				x2="13.0857"
				y2="19.0322"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0052CC" />
				<stop offset="0.9182" stopColor="#2380FB" />
				<stop offset="1" stopColor="#2684FF" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_634_156"
				x1="6.60851"
				y1="3.8409"
				x2="20.4333"
				y2="12.1025"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0052CC" />
				<stop offset="0.9182" stopColor="#2380FB" />
				<stop offset="1" stopColor="#2684FF" />
			</linearGradient>
		</defs>
	</svg>
);

export const ConfluenceIcon: React.FunctionComponent<IconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <ConfluenceGlyph />} />
);
