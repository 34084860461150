import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	parameterModalDescription: {
		id: 'automation-platform.automation-templates.parameter-modal.description',
		defaultMessage:
			'This template requires additional configuration before you can create a rule from it.',
		description: 'The description for the parameters modal dialog',
	},
	parameterModalCancel: {
		id: 'automation-platform.automation-templates.parameter-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'The cancel button text',
	},
	parameterModalSubmit: {
		id: 'automation-platform.automation-templates.parameter-modal.submit',
		defaultMessage: 'Create rule',
		description: 'The submit button text',
	},
	parameterModalErrorInvalidField: {
		id: 'automation-platform.automation-templates.parameter-modal.error.invalid-field',
		defaultMessage: 'This template contains an unsupported parameter type',
		description: "The error text when a field from the template isn't supported",
	},
});
