import React from 'react';

import { Box, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';

const descriptionStyles = xcss({
	boxSizing: 'border-box',
	marginLeft: 'space.100',
});

/**
 *
 * Component which wraps the description text for a template, capping out at 2 lines, with the overflow hidden.
 *
 * @param props - text
 */
export const TemplateDescription = (props: { text: string }) => {
	return (
		<Tooltip ignoreTooltipPointerEvents content={props.text}>
			{(tooltipProps) => (
				<Box xcss={descriptionStyles} {...tooltipProps}>
					<Text size="UNSAFE_small" weight="medium" maxLines={2}>
						{props.text}
					</Text>
				</Box>
			)}
		</Tooltip>
	);
};
