/* eslint-disable jira/react-no-inline-function-prop */
import React, { useCallback, useState } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { Box } from '@atlaskit/primitives';
import RefreshIcon from '@atlaskit/icon/core/refresh';
import Popup from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import commonMessages from '@atlassian/jira-servicedesk-queues-common/src/view/messages.tsx';
import { messages } from './messages.tsx';

export type Props = {
	onResetSorting: () => void;
	isDisabled?: boolean;
};

export function ResetSortingButton({ onResetSorting, isDisabled = false }: Props) {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const onClick = useCallback(() => {
		if (!isDisabled) {
			onResetSorting();
			return;
		}

		setIsOpen((prev) => !prev);
	}, [isDisabled, onResetSorting]);

	return (
		<Popup
			isOpen={isOpen && isDisabled}
			onClose={() => setIsOpen(false)}
			placement="bottom-start"
			content={() => (
				<Box padding="space.200">{formatMessage(messages.resetSortingButtonDisabled)}</Box>
			)}
			trigger={(triggerProps) => (
				<ButtonItem {...triggerProps} onClick={onClick} iconBefore={<RefreshIcon label="" />}>
					{formatMessage(commonMessages.resetSorting)}
				</ButtonItem>
			)}
		/>
	);
}

export default ResetSortingButton;
