// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore } from '@atlassian/react-sweet-state';

const ProjectBuilderStore = createStore({
	initialState: {
		isProjectBuilderOpen: false,
	},
	actions: {
		hideProjectBuilder:
			() =>
			({ setState }) => {
				setState({
					isProjectBuilderOpen: false,
				});
			},
		showProjectBuilder:
			() =>
			({ setState }) => {
				setState({
					isProjectBuilderOpen: true,
				});
			},
	},
	name: 'projectBuilder',
});

export const useProjectBuilder = createHook(ProjectBuilderStore);
