import React from 'react';
import Icon from '@atlaskit/icon';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import EmojiFrequentIcon from '@atlaskit/icon/core/migration/clock--emoji-frequent';
import { MenuGroup, LinkItem } from '@atlaskit/menu';
import { AutomationGlyph } from '@atlassian/jira-automation-menu/src/common/assets/automation.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	buildAuditLogAutomationUrl,
	buildManageRulesAutomationUrl,
	buildNewRuleAutomationUrl,
} from '../../../common/utils/automation-urls.tsx';
import messages from './messages.tsx';

type Props = {
	onLinkClick: () => void;
};
export const DeepLinks = ({ onLinkClick }: Props) => {
	const { data: project } = useProjectContext();
	const projectKey = toProjectKey(`${project?.projectKey}`);
	const isSimplified = !!project?.simplified;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'link',
	});
	const { formatMessage } = useIntl();
	const linkOnClick = (eventName: string) => {
		fireUIAnalytics(analyticsEvent, eventName);
		onLinkClick();
	};
	return (
		<MenuGroup>
			<LinkItem
				href={buildManageRulesAutomationUrl({
					projectKey,
					isSimplified,
				})}
				iconBefore={<Icon glyph={AutomationGlyph} label="" />}
				onClick={() => linkOnClick('manageRulesAutomationLink')}
				UNSAFE_shouldDisableRouterLink
			>
				{formatMessage(messages.manageAutomationMenuItemLabel)}
			</LinkItem>
			<LinkItem
				href={buildNewRuleAutomationUrl({
					projectKey,
					isSimplified,
				})}
				iconBefore={<EditorAddIcon spacing="spacious" label="" />}
				onClick={() => linkOnClick('newRuleAutomationLink')}
				UNSAFE_shouldDisableRouterLink
			>
				{formatMessage(messages.createAutomationMenuItemLabel)}
			</LinkItem>
			<LinkItem
				href={buildAuditLogAutomationUrl({
					projectKey,
					isSimplified,
				})}
				iconBefore={<EmojiFrequentIcon spacing="spacious" label="" />}
				onClick={() => linkOnClick('auditLogAutomationLink')}
				UNSAFE_shouldDisableRouterLink
			>
				{formatMessage(messages.auditLogAutomationMenuItemLabel)}
			</LinkItem>
		</MenuGroup>
	);
};
