import type { State } from '../../../state/reducers/types.tsx';
import {
	getUseJSMQueueDurationEvent,
	getIssueLinkOverride,
} from '../../../state/selectors/ui/index.tsx';
import { connect } from '../../../state/store/index.tsx';
import type { StateProps, OwnProps } from './types.tsx';
import IssueKey from './view.tsx';

const mapStateToProps = (state: State) => ({
	useJSMQueueDurationEvent: getUseJSMQueueDurationEvent(state),
	getIssueLinkOverride: getIssueLinkOverride(state),
});

const mergeProps = (stateProps: StateProps, dispatchProps: {}, ownProps: OwnProps) => ({
	...ownProps,
	...stateProps,
});

export default connect(mapStateToProps, {}, mergeProps)(IssueKey);
