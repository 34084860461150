// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createHook,
	createStore,
	createSubscriber,
} from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import type { Actions } from './actions/types.tsx';
import { BULK_SELECTION_LIMIT } from './constants.tsx';
import type { State } from './types.tsx';

export const initialState: State = {
	issueKeys: [],
	limit: BULK_SELECTION_LIMIT,
	isLocked: false,
};

const Store = createStore<State, Actions>({
	name: 'issue-table-selection',
	initialState,
	actions,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectedIssuesContainer = createContainer<State, Actions, Record<any, any>>(Store);

export const SelectedIssuesSubscriber = createSubscriber<State, Actions>(Store, {
	displayName: 'SelectedIssuesSubscriber',
});

export type SelectedIssuesSubscriberType = typeof SelectedIssuesSubscriber;

export const SelectedIssuesActionsSubscriber = createSubscriber<
	State,
	Actions,
	null,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Record<any, any>
>(Store, {
	displayName: 'SelectedIssuesActionsSubscriber',
	selector: null,
});

export const useSelectedIssues = createHook(Store);

export default Store;
