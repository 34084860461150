import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import memoizeOne from 'memoize-one';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldType } from '@atlassian/jira-issue-table/src/model/fields/types.tsx';

type FeatureFlagMap = Record<FieldType, boolean | string>;

const getFieldTypesFromFeatureFlagMap = (featureFlagMap: FeatureFlagMap): string[] =>
	keys(
		pickBy(featureFlagMap, (isAlwaysJsonOrFFKey: boolean | string) => {
			if (typeof isAlwaysJsonOrFFKey === 'boolean') return isAlwaysJsonOrFFKey;
			// eslint-disable-next-line jira/ff/static-feature-flags
			return ff(isAlwaysJsonOrFFKey) || fg(isAlwaysJsonOrFFKey);
		}),
	);

// @ts-expect-error(PARTIAL_RECORD) TS2740 | TS2740 - Type '{ assignee: true; 'com.atlassian.servicedesk:sd-sla-field': true; status: true; issuetype: true; reporter: true; summary: true; 'com.atlassian.jira.plugins.service-entity:service-entity-field-cftype': true; ... 4 more ...; 'com.atlassian.jira.plugins.work-category-field:work-category-field-cftype': string; }' is missing the following properties from type 'FeatureFlagMap': date, priority, creator, issuekey, and 2 more. | Type '{ assignee: true; 'com.atlassian.servicedesk:sd-sla-field': true; status: true; issuetype: true; reporter: true; summary: true; 'com.atlassian.jira.plugins.service-entity:service-entity-field-cftype': true; ... 4 more ...; 'com.atlassian.jira.plugins.work-category-field:work-category-field-cftype': string; }' is missing the following properties from type 'FeatureFlagMap': date, creator, priority, issuekey, and 2 more.
export const FIELD_TYPES_TO_REQUEST_AS_JSON_FF_KEYS: FeatureFlagMap = {
	assignee: true,
	'com.atlassian.servicedesk:sd-sla-field': true,
	status: true,
	issuetype: true,
	reporter: true,
	priority: 'jsm_queues_ssr_transform_status_category_priority',
	summary: true,
	'com.atlassian.jira.plugins.service-entity:service-entity-field-cftype': true,
	labels: true,
	'com.atlassian.jira.plugins.cmdb:cmdb-object-cftype': true,
	'com.atlassian.jira.modules.servicemanagement.major-incident-entity:major-incident-entity-field-cftype':
		true,
	'com.atlassian.jira.plugins.work-category-field:work-category-field-cftype':
		'jsm_unscreenable_field_phase_2_fg',
	'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes': true,
	'com.atlassian.jira.plugin.system.customfieldtypes:multiselect': true,
	'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker': true,
	'com.atlassian.servicedesk:sd-request-participants': true,
	'com.atlassian.jira.plugin.system.customfieldtypes:multiversion': true,
	'com.atlassian.jira.plugin.system.customfieldtypes:multigrouppicker': true,
	'com.atlassian.servicedesk.customer-context:sd-csm-entitlement': true,
	'com.atlassian.servicedesk.sentiment:sd-sentiment': true,
	'com.atlassian.servicedesk.customer-context:sd-csm-organization': 'jcs_master_flag',
};

export const getFieldTypesEligibleForJsonData: () => string[] = memoizeOne(() =>
	getFieldTypesFromFeatureFlagMap(FIELD_TYPES_TO_REQUEST_AS_JSON_FF_KEYS),
);

export const getFieldTypesEligibleForJsonDataAsSet: () => Set<string> = memoizeOne(
	() => new Set(getFieldTypesEligibleForJsonData()),
);

// @ts-expect-error(PARTIAL_RECORD) TS2740 | TS2740 - Type '{ issuekey: true; }' is missing the following properties from type 'FeatureFlagMap': summary, status, reporter, date, and 13 more. | Type '{ issuekey: true; }' is missing the following properties from type 'FeatureFlagMap': summary, date, labels, assignee, and 13 more.
const FIELD_TYPES_TO_OVERRIDE_HTML_DATA_FOR_FF_KEYS_OLD: FeatureFlagMap = {
	issuekey: true,
	priority: true,
};

// @ts-expect-error(PARTIAL_RECORD) TS2740 | TS2740 - Type '{ issuekey: true; }' is missing the following properties from type 'FeatureFlagMap': summary, status, reporter, date, and 13 more. | Type '{ issuekey: true; }' is missing the following properties from type 'FeatureFlagMap': summary, date, labels, assignee, and 13 more.
const FIELD_TYPES_TO_OVERRIDE_HTML_DATA_FOR_FF_KEYS: FeatureFlagMap = {
	issuekey: true,
	statusCategory: true,
};

// delete old variation when FG jsm_queues_ssr_transform_status_category_priority is removed
export const getFieldTypesToOverrideHtmlDataForOld: () => string[] = memoizeOne(() =>
	getFieldTypesFromFeatureFlagMap(FIELD_TYPES_TO_OVERRIDE_HTML_DATA_FOR_FF_KEYS_OLD),
);

export const getFieldTypesToOverrideHtmlDataForAsSetOld: () => Set<string> = memoizeOne(
	() => new Set(getFieldTypesToOverrideHtmlDataForOld()),
);

export const getFieldTypesToOverrideHtmlDataFor: () => string[] = memoizeOne(() =>
	getFieldTypesFromFeatureFlagMap(FIELD_TYPES_TO_OVERRIDE_HTML_DATA_FOR_FF_KEYS),
);

export const getFieldTypesToOverrideHtmlDataForAsSet: () => Set<string> = memoizeOne(
	() => new Set(getFieldTypesToOverrideHtmlDataFor()),
);
