/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//
export default {
  'automation-platform.error.desc':
    "It's taking us longer than expected to show this content. It's probably a temporary problem. Try refreshing or navigating to another page.",
  'automation-platform.error.title': "We're having some trouble",
  'automation-platform.footer.audit-log-item': 'Audit log',
  'automation-platform.footer.create-automation-item': 'Create automation',
  'automation-platform.footer.manage-automations-item': 'Manage automations',
};
