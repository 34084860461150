/** @jsx jsx */
import { jsx } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/core/warning';
import { token } from '@atlaskit/tokens';
import { useFlagsService, AkFlag, fromFlagId } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME } from '../../../common/constants.tsx';
import messages from './messages.tsx';

export type Props = {
	automationBaseUrl: string;
	flagId: string;
	flagKey: string;
	templateId: string;
};

export const FailureFlag = ({ automationBaseUrl, flagId, flagKey, templateId }: Props) => {
	const { formatMessage } = useIntl();
	const { dismissFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<AkFlag
			testId="servicedesk-queues-automation-menu.ui.automation-menu.failure-flag.failure-flag"
			icon={<WarningIcon color={token('color.icon.warning')} label="" />}
			id={fromFlagId(flagId)}
			key={flagKey}
			title={formatMessage(messages.failureFlagTitle)}
			description={formatMessage(messages.failureFlagDescription)}
			messageId="servicedesk-queues-automation-menu.ui.automation-menu.failure-flag.ak-flag"
			messageType="transactional"
			actions={[
				{
					content: formatMessage(messages.failureFlagAction),
					href: `${automationBaseUrl}#/rule/template?templateId=${templateId}`,
					onClick: () => {
						fireUIAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.failureFlag.action clicked`);
					},
				},
			]}
			onDismissed={() => {
				fireUIAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.failureFlag.dismiss clicked`);
				dismissFlag(flagId);
			}}
		/>
	);
};
