import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewsInQueuesChangeboardHeading: {
		id: 'servicedesk.queues.agent-view.views-in-queues-changeboard-heading',
		defaultMessage: 'Introducing views',
		description:
			'Heading for the views in queues change board communicating views have been moved within queues',
	},
	viewsInQueuesChangeboardBody: {
		id: 'servicedesk.queues.agent-view.views-in-queues-changeboard-description',
		defaultMessage:
			'Admins can add views to queues so you can see your work in a board or calendar.',
		description:
			'Description for the views in queues change board communicating views have been moved within queues',
	},
	viewsInQueuesChangeboardButton: {
		id: 'servicedesk.queues.agent-view.views-in-queues-changeboard-button',
		defaultMessage: 'OK',
		description: 'Acknowledge button for the views in queues change board',
	},
	introducingViewsImageAltText: {
		id: 'servicedesk.queues.agent-view.views-in-queues-changeboard-image-alt-text',
		defaultMessage: 'Introducing views in queues image',
		description: 'Image alt text for the views in queues change board',
	},
	readMoreLink: {
		id: 'servicedesk.queues.agent-view.views-in-queues-changeboard-read-more-link',
		defaultMessage: 'Read more about views of queues.',
		description: 'Read more link for the views in queues change board',
	},
});
