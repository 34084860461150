import React, { useState } from 'react';
import { Anchor, Box, Stack, xcss } from '@atlaskit/primitives';

import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useSeenStatus } from '@atlassian/jira-servicedesk-itsm-onboarding-common/src/controllers/seen-status/index.tsx';
import ViewInQueuesIntroImage from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/jsm-add-views-in-queues/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';

import { ItsmSpotlightCard } from '@atlassian/jira-servicedesk-itsm-onboarding-common/src/common/ui/itsm-spotlight-card/index.tsx';
import {
	JSM_VIEWS_IN_QUEUES_AGENT_VIEW_CHANGE_BOARDING_SEEN_STATUS,
	PACKAGE_NAME,
	TEAM_NAME,
} from '../../../common/constants.tsx';
import SparklesIcon from './sparkles-icon/index.tsx';
import messages from './messages.tsx';

const SpotlightCardWithImage = ({
	markChangeboardingTourAsSeen,
	setIsOpen,
}: {
	markChangeboardingTourAsSeen: () => void;
	setIsOpen: (isOpen: boolean) => void;
}) => {
	const { formatMessage } = useIntl();
	const READ_MORE_LINK =
		'https://support.atlassian.com/jira-service-management-cloud/docs/enable-new-views-for-your-service-project/';

	return (
		<Stack
			as="div"
			xcss={changeboardStyles}
			testId="servicedesk-queues-agent-view.layout.views-in-queues-changeboard.agent-views-in-queues-changeboard"
		>
			<Box xcss={imageContainerStyles}>
				<ViewInQueuesIntroImage alt={formatMessage(messages.introducingViewsImageAltText)} />
			</Box>
			<Box xcss={spotlightCardStyles}>
				<ItsmSpotlightCard
					buttons={{
						primary: {
							text: formatMessage(messages.viewsInQueuesChangeboardButton),
							onClick: () => {
								markChangeboardingTourAsSeen();
								setIsOpen(false);
							},
						},
					}}
				>
					<Box xcss={headingStyles}>
						<Box xcss={headingTextStyles} as="b">
							{formatMessage(messages.viewsInQueuesChangeboardHeading)}
						</Box>
						<SparklesIcon />
					</Box>
					<Box xcss={bodyTextStyles} as="p">
						{formatMessage(messages.viewsInQueuesChangeboardBody)}
					</Box>
					<Box as="div" xcss={linkContainerStyles}>
						<Anchor href={READ_MORE_LINK} target="_blank">
							{formatMessage(messages.readMoreLink)}
						</Anchor>
					</Box>
				</ItsmSpotlightCard>
			</Box>
		</Stack>
	);
};

const ViewsInQueuesAgentViewChangeboard = () => {
	const [isOpen, setIsOpen] = useState(true);
	const { data: projectContextData } = useProjectContext();
	const isProjectAdmin = projectContextData?.isProjectAdmin ?? false;
	const [{ isSeen: hasSeenChangeboardingTour }, { markAsSeen: markChangeboardingTourAsSeen }] =
		useSeenStatus({
			userPropertyKey: JSM_VIEWS_IN_QUEUES_AGENT_VIEW_CHANGE_BOARDING_SEEN_STATUS,
			isSeenValue: true,
			packageName: PACKAGE_NAME,
			teamName: TEAM_NAME,
		});

	return (
		isOpen &&
		!isProjectAdmin &&
		!hasSeenChangeboardingTour && (
			<JSErrorBoundary
				id="service.desk-agent-views-in-queues-change-boarding-tour"
				packageName={PACKAGE_NAME}
				teamName={TEAM_NAME}
				fallback="unmount"
			>
				<Box xcss={changeboardPositionStyles}>
					<SpotlightCardWithImage
						markChangeboardingTourAsSeen={markChangeboardingTourAsSeen}
						setIsOpen={setIsOpen}
					/>
				</Box>
			</JSErrorBoundary>
		)
	);
};

export default ViewsInQueuesAgentViewChangeboard;

const changeboardStyles = xcss({
	width: '320px',
	borderRadius: 'border.radius',
	overflow: 'hidden',
});

const changeboardPositionStyles = xcss({
	position: 'fixed',
	bottom: '24px',
	right: '24px',
	zIndex: 'flag',
	borderRadius: 'border.radius.100',
	boxShadow: 'elevation.shadow.overlay',
});

const headingStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
	marginTop: 'space.100',
	marginBottom: 'space.200',
});

const headingTextStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '260px',
});

const imageContainerStyles = xcss({
	marginBottom: 'space.negative.250',
	width: '320px',
	overflow: 'hidden',
});

const linkContainerStyles = xcss({
	marginBottom: 'space.negative.200',
	width: '320px',
	overflow: 'hidden',
});

const spotlightCardStyles = xcss({
	width: '320px',
	overflow: 'hidden',
	padding: 'space.200',
});

const bodyTextStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: 'block',
	maxHeight: '4.5em',
	marginBottom: 'space.200',
	paddingRight: 'space.200',
	maxLines: 3,
});
