import { styled } from '@compiled/react';
import Scrollbars from 'react-custom-scrollbars';
import { token } from '@atlaskit/tokens';

/* The scrollbar library is not compatible with rendering styled
 * components as track or thumb elements. This leaves
 * classes or inline-styles. Inline-styles have the limitation
 * of not being able to support element states (such as hover)
 * out of the box.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledScrollbars = styled(Scrollbars)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> .track-vertical': {
		right: token('space.0'),
		top: token('space.100'),
		bottom: token('space.100'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		width: `${token('space.075')} !important`,
		borderRadius: '99px',
		'&:hover': {
			transition: 'width 0.1s ease-in, background 0.1s ease-in',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			width: '10px !important',
			background: 'rgba(0, 0, 0, 0.3)',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> .track-vertical > .thumb-vertical': {
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		cursor: 'pointer',
		borderRadius: '99px',
		position: 'relative',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> .track-horizontal': {
		right: token('space.100'),
		bottom: token('space.0'),
		left: token('space.100'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		height: `${token('space.075')} !important`,
		borderRadius: '99px',
		'&:hover': {
			transition: 'height 0.1s ease-in, background 0.1s ease-in',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			height: '10px !important',
			background: 'rgba(0, 0, 0, 0.3)',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> .track-horizontal > .thumb-horizontal': {
		backgroundColor: token('color.background.neutral.bold'),
		cursor: 'pointer',
		borderRadius: '99px',
	},
});
