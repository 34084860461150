import type { MouseEvent } from 'react';
import { Maybe } from 'monet';
import type { IssueViewTransitionCallback } from '../../../model/fields/types.tsx';
import type {
	IssueViewTransitionCallbackState,
	JSMQueueDurationEvent,
	QueueSidebarIconCallbackState,
	QueueSidebarRefreshCallbackState,
} from '../../../model/index.tsx';
import type { State } from '../../reducers/types.tsx';

export const getUiState = (state: State) => state.ui;

export const shouldShowPagination = (state: State) => getUiState(state).showPagination;

export const getCurrentPage = (state: State): number => getUiState(state).currentPage;

const maybeEditingFieldPosition = (state: State) =>
	Maybe.fromNull(getUiState(state).editingFieldPosition);

export const getEditingFieldHorizontalOffset = (state: State): number =>
	maybeEditingFieldPosition(state)
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		.map((position) => position.horizontalOffset)
		.orSome(0);

export const getEditingFieldVerticalOffset = (state: State): number =>
	maybeEditingFieldPosition(state)
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		.map((position) => position.verticalOffset)
		.orSome(0);

export const getEditingFieldWidth = (state: State): number =>
	maybeEditingFieldPosition(state)
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		.map((position) => position.fieldWidth)
		.orSome(0);

export const getTableWidth = (state: State): number =>
	maybeEditingFieldPosition(state)
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		.map((position) => position.tableWidth)
		.orSome(0);

export const getTableHeight = (state: State): number =>
	maybeEditingFieldPosition(state)
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		.map((position) => position.tableHeight)
		.orSome(0);

// TODO SHIELD-3318 refactor to be generic to all editable fields
export const isUserPickerDisabled = (state: State): boolean => getUiState(state).userPickerDisabled;

export const isTableDisabled = (state: State): boolean => getUiState(state).isTableDisabled;

export const getOnIssueViewTransition = (
	state: State,
): IssueViewTransitionCallbackState | undefined => getUiState(state).onIssueViewTransition;

export const getOnIssueViewTransitionCallback = (
	state: State,
	issueKey: string,
): IssueViewTransitionCallback => {
	const onIssueViewTransition = getOnIssueViewTransition(state);
	return onIssueViewTransition
		? (event: MouseEvent<HTMLElement>) => onIssueViewTransition(issueKey, event)
		: undefined;
};

export const getUseJSMQueueDurationEvent = (state: State): JSMQueueDurationEvent | undefined =>
	getUiState(state).useJSMQueueDurationEvent;

export const getRenderSidebarIcon = (state: State): QueueSidebarIconCallbackState | undefined =>
	getUiState(state).renderSidebarIcon;

export const getRefreshSidebar = (state: State): QueueSidebarRefreshCallbackState | undefined =>
	getUiState(state).refreshSidebar;

export const getIssueLinkOverride = (state: State): ((issueKey: string) => string) | undefined =>
	getUiState(state).getIssueLinkOverride;
