import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	manageAutomationMenuItemLabel: {
		id: 'servicedesk-queues-automation-menu.menu-content.deep-links.manage-automation-menu-item-label',
		defaultMessage: 'Manage automation',
		description: 'A label for the popup menu item for Manage automation deep-link',
	},
	createAutomationMenuItemLabel: {
		id: 'servicedesk-queues-automation-menu.menu-content.deep-links.create-automation-menu-item-label',
		defaultMessage: 'Create automation',
		description: 'A label for the popup menu item for Create automation deep-link',
	},
	auditLogAutomationMenuItemLabel: {
		id: 'servicedesk-queues-automation-menu.menu-content.deep-links.audit-log-automation-menu-item-label',
		defaultMessage: 'Audit log',
		description: 'A label for the popup menu item for Audit Log automation deep-link',
	},
});
