import { staticSidebarJsmReportsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-jsm-reports-sidebar/index.tsx';
import type { JiraServiceManagementNavReportsData } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/jsm-reports-sidebar-navigation/types.tsx';
import type { UseResourceCustomContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/types.tsx';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import type { ProjectKeyRouteParams } from '../project-sidebar-navigation-resource/types.tsx';

export const useJiraServiceManagementReportsNavigation = (
	customContext?: UseResourceCustomContext<ProjectKeyRouteParams>,
) =>
	useResourceWithCustomRouterContext<JiraServiceManagementNavReportsData | null>(
		staticSidebarJsmReportsResource,
		customContext,
	);
