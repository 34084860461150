import React from 'react';

import { ButtonItem } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';

import { TemplateDescription } from '../template-description';

const triggerStyles = xcss({
	zIndex: 'card',
});
const actionStyles = xcss({
	marginLeft: 'space.negative.100',
});
const templateStyles = xcss({
	alignItems: 'center',
	display: 'flex',
});

interface Props {
	onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
	isDisabled: boolean;
	triggerIcon: JSX.Element;
	actionIcon: JSX.Element;
	description: string;
	templateId: string;
}

/**
 *
 * Component which contains the trigger/action icons and a description.
 *
 * A template must contain two icons or product logos that correspond
 * to the trigger and action components of the associated rule.
 *
 * Descriptions are capped out at 2 lines, with the overflow becoming ellipsis.
 *
 * @param props - onClick, isDisabled, isHovered, tabIndex, triggerIcon, actionIcon, description, templateId
 */
export const TemplateItem = ({
	onClick,
	isDisabled,
	triggerIcon,
	actionIcon,
	description,
	templateId,
}: Props) => {
	return (
		<ButtonItem
			onClick={(event: React.MouseEvent | React.KeyboardEvent) => !isDisabled && onClick(event)}
			testId={'template.item.' + templateId}
			isDisabled={isDisabled}
			shouldTitleWrap={true}
		>
			<Box xcss={templateStyles}>
				<Box testId={'template.item.trigger.icon.' + templateId} xcss={triggerStyles}>
					{triggerIcon}
				</Box>
				<Box testId={'template.item.action.icon.' + templateId} xcss={actionStyles}>
					{actionIcon}
				</Box>
				<Box testId={'template.item.desc.' + templateId}>
					<TemplateDescription text={description} />
				</Box>
			</Box>
		</ButtonItem>
	);
};
