import React from 'react';

import { type IconProps } from '@atlaskit/icon';
import { useThemeObserver } from '@atlaskit/tokens';

import { CustomIcon } from './custom-icon';

/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const LdCreateFeatureFlagGlyph: React.FunctionComponent = () => {
	const theme = useThemeObserver() as string;
	const iconColor = theme === 'dark' ? '#FFFFFF' : '#172b4d';

	return (
		<svg
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 216 214.94"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M109.8,214.94a4.87,4.87,0,0,1-4.26-2.66,4.5,4.5,0,0,1,.44-4.82l50.49-69.53L68,174.11a4.61,4.61,0,0,1-1.9.41,4.77,4.77,0,0,1-4.52-3.4,4.57,4.57,0,0,1,2-5.21L141.33,120,4.41,112.13a4.69,4.69,0,0,1,0-9.36l137-7.87L63.61,49a4.56,4.56,0,0,1-1.94-5.2,4.74,4.74,0,0,1,4.51-3.4,4.6,4.6,0,0,1,1.9.4L156.5,77,106,7.48a4.56,4.56,0,0,1-.44-4.83A4.84,4.84,0,0,1,109.84,0a4.59,4.59,0,0,1,3.28,1.41L213.77,102.05a7.65,7.65,0,0,1,0,10.8L113.08,213.53A4.59,4.59,0,0,1,109.8,214.94Z"
				clipRule="evenodd"
				fill={iconColor}
				fillRule="evenodd"
			/>
		</svg>
	);
};

export const LdCreateFeatureFlagIcon: React.FunctionComponent<IconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <LdCreateFeatureFlagGlyph />} />
);
