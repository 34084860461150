import type { MajorIncident } from '@atlassian/jira-major-incident/src/common/types.tsx';
import type { Responder } from '@atlassian/jira-responders/src/common/types/index.tsx';
import type { OrganizationFieldData } from '@atlassian/jira-servicedesk-customer-service-custom-fields/src/common/types.tsx';
import type { EntitlementFieldData } from '@atlassian/jira-servicedesk-customer-service-entitlements-field/src/common/types.tsx';
import type { CmdbObject } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/cmdb-object.tsx';
import type { SLA_DISPLAY_FORMAT } from '@atlassian/jira-servicedesk-sla-panel/src/common/types.tsx';
import type { Service } from '../../../../common/fields/affected-services/types.tsx';
import type {
	MultiUserFieldItem,
	MultiValueFieldItem,
	SentimentFieldData,
} from '../common/types.tsx';

export const ONGOING = 'ONGOING' as const;
export const COMPLETED = 'COMPLETED' as const;
export const PAUSED = 'PAUSED' as const;
export type SlaStatus = typeof ONGOING | typeof COMPLETED | typeof PAUSED;

export const SLA_CUSTOM_FIELD_TYPE = 'com.atlassian.servicedesk:sd-sla-field' as const;

export const SERVICE_ENTITY_CUSTOM_FIELD_TYPE =
	'com.atlassian.jira.plugins.service-entity:service-entity-field-cftype' as const;

export const CMDB_OBJECT_CUSTOM_FIELD_TYPE =
	'com.atlassian.jira.plugins.cmdb:cmdb-object-cftype' as const;

export const RESPONDERS_CUSTOM_FIELD_TYPE =
	'com.atlassian.jira.modules.servicemanagement.responders-entity:responders-entity-field-cftype' as const;

export const MAJOR_INCIDENT_CUSTOM_FIELD_TYPE =
	'com.atlassian.jira.modules.servicemanagement.major-incident-entity:major-incident-entity-field-cftype' as const;

export const WORK_CATEGORY_CUSTOM_FIELD_TYPE =
	'com.atlassian.jira.plugins.work-category-field:work-category-field-cftype' as const;

export const MULTI_USER_CUSTOM_FIELD_TYPE =
	'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker' as const;

export const MULTI_CHECKBOX_CUSTOM_FIELD_TYPE =
	'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes' as const;

export const MULTI_SELECT_CUSTOM_FIELD_TYPE =
	'com.atlassian.jira.plugin.system.customfieldtypes:multiselect' as const;

export const MULTI_VERSION_CUSTOM_FIELD_TYPE =
	'com.atlassian.jira.plugin.system.customfieldtypes:multiversion' as const;

export const MULTI_GROUP_PICKER_CUSTOM_FIELD_TYPE =
	'com.atlassian.jira.plugin.system.customfieldtypes:multigrouppicker' as const;

export const ENTITLEMENT_CUSTOM_FIELD_TYPE =
	'com.atlassian.servicedesk.customer-context:sd-csm-entitlement' as const;

export const SENTIMENT_CUSTOM_FIELD_TYPE =
	'com.atlassian.servicedesk.sentiment:sd-sentiment' as const;

export const ORGANIZATION_CUSTOM_FIELD_TYPE =
	'com.atlassian.servicedesk.customer-context:sd-csm-organization' as const;

export type SlaProps = {
	isBreached: boolean;
	timeRemainingFriendly: string;
	status: SlaStatus;
	timeRemainingInMinutes: number;
	breachTime: string | undefined;
	elapsedTime: string;
	goalDuration: string;
	withinCalendarHours: boolean | undefined;
	hoverContent?: string | undefined;
	slaDisplayFormat?: SLA_DISPLAY_FORMAT;
};

export type SlaError = {
	errorMessage: string;
	i18nErrorMessage: {
		i18nKey: string;
		parameters: string[];
	};
};

export type SlaDataSelectorProps = {
	fieldType: typeof SLA_CUSTOM_FIELD_TYPE;
	value?: SlaProps | SlaError;
};

export type ServiceEntityDataSelectorProps = {
	fieldType: typeof SERVICE_ENTITY_CUSTOM_FIELD_TYPE;
	value: string[] | Service[];
};

export type CmdbObjectDataSelectorProps = {
	fieldType: typeof CMDB_OBJECT_CUSTOM_FIELD_TYPE;
	value: CmdbObject[];
};

export type RespondersDataSelectorProps = {
	fieldType: typeof RESPONDERS_CUSTOM_FIELD_TYPE;
	value: Responder[];
};

export type MajorIncidentDataSelectorProps = {
	fieldType: typeof MAJOR_INCIDENT_CUSTOM_FIELD_TYPE;
	value: MajorIncident;
};

export type WorkCategoryDataSelectorProps = {
	fieldType: typeof WORK_CATEGORY_CUSTOM_FIELD_TYPE;
	value: string;
};

export type MultiUserDataSelectorProps = {
	fieldType: typeof MULTI_USER_CUSTOM_FIELD_TYPE;
	value: MultiUserFieldItem[];
};

export type MultiCheckboxDataSelectorProps = {
	fieldType: typeof MULTI_CHECKBOX_CUSTOM_FIELD_TYPE;
	value: MultiValueFieldItem[];
};

export type MultiSelectDataSelectorProps = {
	fieldType: typeof MULTI_SELECT_CUSTOM_FIELD_TYPE;
	value: MultiValueFieldItem[];
};

export type MultiVersionDataSelectorProps = {
	fieldType: typeof MULTI_VERSION_CUSTOM_FIELD_TYPE;
	value: MultiValueFieldItem[];
};

export type MultiGroupPickerDataSelectorProps = {
	fieldType: typeof MULTI_GROUP_PICKER_CUSTOM_FIELD_TYPE;
	value: MultiValueFieldItem[];
};

export type EntitlementDataSelectorProps = {
	fieldType: typeof ENTITLEMENT_CUSTOM_FIELD_TYPE;
	value: EntitlementFieldData | null;
};

export type SentimentDataSelectorProps = {
	fieldType: typeof SENTIMENT_CUSTOM_FIELD_TYPE;
	value: SentimentFieldData | null;
};

export type OrganizationDataSelectorProps = {
	fieldType: typeof ORGANIZATION_CUSTOM_FIELD_TYPE;
	value: OrganizationFieldData | null;
};
