import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const deselectIssues =
	(deselectedIssueKeys: IssueKey[], analyticsEvent?: UIAnalyticsEvent): Action<State> =>
	({ getState, setState }) => {
		const { issueKeys } = getState();

		const newlyDeselectedIssueKeys = intersection(deselectedIssueKeys, issueKeys);

		if (!isEmpty(newlyDeselectedIssueKeys)) {
			const newIssueKeys = without(issueKeys, ...newlyDeselectedIssueKeys);
			setState({ issueKeys: newIssueKeys });
			analyticsEvent &&
				fireTrackAnalytics(analyticsEvent, 'bulkSelection reduced', {
					selectedIssuesCount: newIssueKeys.length,
					previousSelectedIssuesCount: issueKeys.length,
				});
		}
	};
