// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ReactNode, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { isChrome, isSafari, isEdge } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

const isWebkit = () => isChrome() || isSafari() || isEdge();

const wrapperStyles = {
	overflow: 'hidden',
	flex: '1 1 auto',
	marginBottom: 0,
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ScrollableDiv = styled.div({
	scrollbarWidth: 'none',
	MsOverflowStyle: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...(isWebkit() && {
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	}),
	display: 'flex',
	paddingBottom: 0,
});

type Props = {
	scrollLeft: number;
	setHorizontalScrollOffset: (scrollLeft: number) => void;
	children: ReactNode;
	// additionalColumnsWidth is used in DropTarget
	additionalColumnsWidth: number;
};

// eslint-disable-next-line jira/react/no-class-components
class AdditionalColumnsContainer extends PureComponent<Props> {
	componentDidMount() {
		const { scrollLeft } = this.props;
		this.updateScrollLeft(scrollLeft);
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.updateScrollLeft(nextProps.scrollLeft);
	}

	onScroll = (event: MouseEvent<HTMLDivElement>) => {
		const { setHorizontalScrollOffset } = this.props;
		const { scrollRef, ignoreScrollEvent } = this;

		if (event.currentTarget === scrollRef && !ignoreScrollEvent) {
			setHorizontalScrollOffset(event.currentTarget.scrollLeft);
		}
		this.ignoreScrollEvent = false;
	};

	setScrollRef = (ref?: HTMLDivElement | null) => {
		this.scrollRef = ref;
	};

	updateScrollLeft(scrollLeft: number) {
		const { scrollRef } = this;

		if (scrollRef && scrollRef.scrollLeft !== scrollLeft) {
			this.ignoreScrollEvent = true;
			scrollRef.scrollLeft = scrollLeft;
		}
	}

	scrollRef: HTMLDivElement | undefined | null;

	// @ts-expect-error - TS2564 - Property 'ignoreScrollEvent' has no initializer and is not definitely assigned in the constructor.
	ignoreScrollEvent: boolean;

	render() {
		const { children } = this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<div style={wrapperStyles}>
				<ScrollableDiv
					ref={(div) => {
						this.setScrollRef(div);
					}}
					style={{
						overflowX:
							__SERVER__ && fg('issuelist_disable_horizontal_row_scroll_ssr') ? 'hidden' : 'scroll',
					}}
					onScroll={this.onScroll}
				>
					{children}
				</ScrollableDiv>
			</div>
		);
	}
}

export default AdditionalColumnsContainer;
