import { ResourceIdentifier } from '@atlassian/cs-ari';

import { Product } from '../types';
/**
 * Utility function to extract a a resource owner (of type {@link Product}) as well as a cloudId from a
 * provided site ARI string.
 * @param site - Attempt to parse and resolve the above from the ARI string
 * @returns (resourceOwner, cloudId) if the ARI is parsable, is a site ARI, the resource owner is
 * supported by automation, and includes a cloudId. Throws {@link Error} otherwise.
 */
export const extractResourceOwnerAndCloudIdFromSiteAri = (site: string) => {
	let cloudId;
	let resourceOwner;
	let resourceType;
	try {
		const ari = ResourceIdentifier.parse(site);
		cloudId = ari.cloudId;
		resourceOwner = ari.resourceOwner;
		resourceType = ari.resourceType;
		// Keep backwards compatibility for platform site ARIs
		if (resourceOwner === 'platform') {
			resourceOwner = Product.JIRA;
		}
	} catch (error) {
		throw new Error('Attempted to parse malformed ARI: ' + site);
	}
	if (resourceType !== 'site') {
		throw new Error('The supplied ARI is not a site ARI: ' + site);
	}
	if (cloudId === undefined) {
		throw new Error('The supplied ARI did not include a cloudId: ' + site);
	}
	const supportedResourceOwners = Object.values(Product);
	if (!supportedResourceOwners.includes(resourceOwner as Product)) {
		throw new Error(
			`The supplied ARI is not supported by Automation: ${site}. (Supported resource owners: ${supportedResourceOwners})`,
		);
	}
	return { resourceOwner: resourceOwner as Product, cloudId };
};
