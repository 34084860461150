import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	parameterModalErrorEmptyText: {
		id: 'automation-platform.automation-templates.text-parameter-modal.error.empty',
		defaultMessage: 'Please provide a text value for this parameter',
		description: 'The error text when a required text parameter is missing',
	},
	parameterModalErrorInvalidText: {
		id: 'automation-platform.automation-templates.text-parameter-modal.error.invalid-text',
		defaultMessage: 'Please provide a valid text value for this parameter',
		description: 'The error text when a text parameter has a non text value',
	},
});
