// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore } from '@atlassian/react-sweet-state';
import actions from './actions.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	issueKeyContext: undefined,
};

export default createStore<State, typeof actions>({
	name: 'issue-context',
	initialState,
	actions,
});
