import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	menuTitle: {
		id: 'servicedesk-queues-automation-menu.menu-content.menu-title',
		defaultMessage: 'Automation',
		description: 'Title of the automation menu',
	},
	menuSubTitle: {
		id: 'servicedesk-queues-automation-menu.menu-content.menu-sub-title',
		defaultMessage:
			'Setup your queues automations from these templates, or create a new one of your own.',
		description: 'A short message explaining purpose of the automation menu',
	},
});
