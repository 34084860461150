import React from 'react';

import { type IconProps } from '@atlaskit/icon';

import { CustomIcon } from './custom-icon';

// Because this is a logo it should be presented on white and design tokens are not used
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const BitbucketGlyph: React.FunctionComponent = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="32" height="32" fill="white" fillOpacity="0.01" />
		<path
			d="M5.50533 6.18945C5.07406 6.18945 4.71467 6.57176 4.78655 6.98882L7.84135 24.8877C7.91323 25.3395 8.30856 25.6871 8.8117 25.6871H23.4388C23.7982 25.6871 24.0857 25.4438 24.1576 25.0962L27.2124 6.98882C27.2843 6.57176 26.9609 6.18945 26.4936 6.18945H5.50533ZM18.3715 19.1184H13.6994L12.4415 12.7234H19.5215L18.3715 19.1184Z"
			fill="#2684FF"
		/>
		<path
			d="M26.2413 12.7236H19.4848L18.3707 19.1186H13.6987L8.16406 25.444C8.16406 25.444 8.41563 25.6873 8.81096 25.6873H23.4381C23.7975 25.6873 24.085 25.444 24.1569 25.0965L26.2413 12.7236Z"
			fill="url(#paint0_linear_13_34916)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_13_34916"
				x1="27.7908"
				y1="14.5201"
				x2="16.9275"
				y2="23.2887"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0.176" stopColor="#0052CC" />
				<stop offset="1" stopColor="#2684FF" />
			</linearGradient>
		</defs>
	</svg>
);

export const BitbucketIcon: React.FunctionComponent<IconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <BitbucketGlyph />} />
);
