import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	successFlagTitle: {
		id: 'servicedesk-queues-automation-menu.automation-menu.success-flag.success-flag-title',
		defaultMessage: 'Your automation rule has been created',
		description: 'Title of a success flag after automation rule is successfully created',
	},
	successFlagAction: {
		id: 'servicedesk-queues-automation-menu.automation-menu.success-flag.success-flag-action',
		defaultMessage: 'Go to automation rule',
		description: 'Title of a link to redirect a user to created automation rule',
	},
});

export default messages;
