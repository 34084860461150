import React from 'react';

import { type IconProps } from '@atlaskit/icon';

import { CustomIcon } from './custom-icon';

// Because this is a logo it should be presented on white and design tokens are not used
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const JiraSoftwareGlyph: React.FunctionComponent = () => (
	<svg width="32" fill="none" viewBox="0 0 32 32" focusable="false" role="presentation">
		<rect width="32" height="32" />
		<path
			d="m28.801 14.224-12.819-13.224-8.7725 9.0501-4.0103 4.1742c-0.42967 0.4432-0.42967 1.1451 0 1.5514l8.0206 8.2744 4.7622 4.913 8.7725-9.0502 0.1432-0.1477 3.9029-3.9895c0.4296-0.4432 0.4296-1.1451 0-1.5514zm-12.819 4.9129-4.0103-4.1372 4.0103-4.1372 4.0103 4.1372-4.0103 4.1372z"
			fill="#2684FF"
		/>
		<path
			d="m15.982 10.863c-2.6139-2.6966-2.6497-7.0924-0.0358-9.8259l-8.7367 9.0501 4.7622 4.913 4.0103-4.1372z"
			fill="url(#b)"
		/>
		<path
			d="m20.028 14.963-4.046 4.1741c2.6496 2.7335 2.6496 7.1293 0 9.8628l8.8083-9.087-4.7623-4.9499z"
			fill="url(#a)"
		/>
		<defs>
			<linearGradient
				id="b"
				x1="15.28"
				x2="9.5046"
				y1="6.6685"
				y2="12.267"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0052CC" offset=".176" />
				<stop stopColor="#2684FF" offset="1" />
			</linearGradient>
			<linearGradient
				id="a"
				x1="16.762"
				x2="22.526"
				y1="23.267"
				y2="17.68"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#0052CC" offset=".176" />
				<stop stopColor="#2684FF" offset="1" />
			</linearGradient>
		</defs>
	</svg>
);

export const JiraSoftwareIcon: React.FunctionComponent<IconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <JiraSoftwareGlyph />} />
);
