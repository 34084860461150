import { useMemo } from 'react';
import { useJiraServiceManagementReportsNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/jsm-reports-resource/index.tsx';
import { useJiraServiceManagementProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { useHasActiveSoftwareAccess } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/has-active-software-access/index.tsx';
import type {
	UseResourceCustomContext,
	UseResourceReturn,
} from '@atlassian/jira-resource-with-custom-router-context/src/controllers/types.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import type {
	Features,
	UseReportFeaturesReturn,
	UseSettingFeaturesReturn,
	UseTMPSettingFeaturesReturn,
} from './types.tsx';
import {
	extractData,
	extractReportData,
	extractSettingData,
	extractTMPSettingData,
} from './utils/index.tsx';

/**
 * Features is also being consumed by the new Jira V4 navigation.
 * When making updates, ensure that the new navigation is supported/updated.
 * `jira/src/packages/navigation-apps/sidebar-nav4/jsm-project-menu`
 */
export const useFeatures = (
	customContext?: UseResourceCustomContext,
): UseResourceReturn<Features> => {
	const { data, ...rest } = useJiraServiceManagementProjectNavigation(customContext);
	const extractedData = useMemo(() => data && extractData(data), [data]);
	return { data: extractedData, ...rest };
};

/**
 * Report features is also being consumed by the new Jira V4 navigation.
 * When making updates, ensure that the new navigation is supported/updated.
 * `jira/src/packages/navigation-apps/sidebar-nav4/jsm-project-menu`
 */
export const useReportFeatures = (
	customContext?: UseResourceCustomContext,
): UseReportFeaturesReturn => {
	const { data, ...rest } = useJiraServiceManagementReportsNavigation(customContext);
	const extractedData = useMemo(() => data && extractReportData(data), [data]);
	return { data: extractedData, ...rest };
};

/**
 * Setting features is also being consumed by the new Jira V4 navigation.
 * When making updates, ensure that the new navigation is supported/updated.
 * `jira/src/packages/navigation-apps/sidebar-nav4/jsm-project-menu`
 *
 * `customContext` is used e.g. in JSM project menu, see `useThrowSettingFeatures = withThrowOnError(useSettingFeatures)`
 */
export const useSettingFeatures = (
	customContext?: UseResourceCustomContext,
): UseSettingFeaturesReturn => {
	const { data, ...rest } = useJiraServiceManagementProjectNavigation(customContext);
	const hasActiveSoftwareAccess = useHasActiveSoftwareAccess();
	const environment = useEnvironment();
	const extractedData = useMemo(
		() => data && extractSettingData(data, { hasActiveSoftwareAccess, environment }),
		[data, environment, hasActiveSoftwareAccess],
	);
	return { data: extractedData, ...rest };
};

/**
 * Setting features is also being consumed by the new Jira V4 navigation.
 * When making updates, ensure that the new navigation is supported/updated.
 * `jira/src/packages/navigation-apps/sidebar-nav4/jsm-project-menu`
 */
export const useTMPSettingFeatures = (): UseTMPSettingFeaturesReturn => {
	const { data, ...rest } = useJiraServiceManagementProjectNavigation();
	const extractedData = useMemo(() => data && extractTMPSettingData(data), [data]);
	return { data: extractedData, ...rest };
};
