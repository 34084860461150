import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';
import type { StatusCategory } from '../../../model/fields/json-fields/system-fields/types.tsx';
import Alert from '../error/view.tsx';

const statusCategoryToAppearanceMap = (statusCategory: StatusCategory) => {
	switch (statusCategory) {
		case 'inProgress':
			return 'inprogress';
		case 'done':
			return 'success';
		default:
			return 'default';
	}
};

const StatusCategoryLozenge = ({
	dataSelectorProps,
	tableInjectedProps,
}: JsonComponentProps<'statusCategory'>) => {
	const { value } = dataSelectorProps;
	const { isLastColumn } = tableInjectedProps;

	if (!value) {
		return <Alert isLastColumn={isLastColumn} />;
	}

	return (
		<Lozenge
			appearance={statusCategoryToAppearanceMap(value.category)}
			isBold={isVisualRefreshEnabled()}
			maxWidth="192px"
		>
			{value.name}
		</Lozenge>
	);
};

export default StatusCategoryLozenge;
