import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl-next';
import { di } from 'react-magnetic-di';

import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import DropdownMenu, {
	type DropdownMenuProps,
	type OnOpenChangeArgs,
} from '@atlaskit/dropdown-menu';
import Link from '@atlaskit/link';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import {
	type Environment,
	type Template,
	TemplatesContainer,
	type TemplateSearchQuery,
} from '@atlassian/automation-api';
import {
	AutomationIntlMessagesProvider,
	ErrorState,
	Footer,
} from '@atlassian/automation-ui-commons';

import { locales } from '../../../utils/i18n';
import { TemplateEmptyState } from '../../empty-state';
import { TemplateList } from '../template-list';

import { AutomationGlyph } from './icon';
import { messages } from './messages';

type TemplateDropdownProps = {
	env: Environment;
	site: string;
	query: TemplateSearchQuery;
	baseAutomationUrl: string;
	targetRuleHome: string;
	onCreateSuccess?: (templateId: string, ruleId: number) => void;
	onCreateFailure?: (templateId: string, error: string) => void;
	onFetchSuccess?: (templates: Template[]) => void;
	onFetchFailed?: (error: string) => void;
	dropdownMenuProps?: Partial<DropdownMenuProps>;
};

const headerStyles = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '1.25rem',
	paddingTop: 'space.250',
	paddingRight: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.250',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
});

const descriptionStyles = xcss({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '1.25rem',
	paddingTop: 'space.0',
	paddingRight: 'space.250',
	paddingBottom: 'space.100',
	paddingLeft: 'space.250',
});

const linkStyles = xcss({
	font: 'font.body',
	fontWeight: 'font.weight.regular',
	color: 'color.link',
	paddingTop: 'space.100',
	paddingRight: 'space.250',
	paddingBottom: 'space.200',
	paddingLeft: 'space.250',
});

const menuStyles = xcss({
	width: '21.75rem',
});

const tooltipOptions: IconButtonProps['tooltip'] = {
	position: 'top',
	hideTooltipOnClick: false,
};

type TemplateSubMenuProps = {
	templates: Template[] | null;
	ruleLibraryUrl: string;
	targetRuleHome: string;
	createRuleOrShowDialog: (templateId: string, targetRuleHome: string) => Promise<void>;
	isDisabled: boolean;
	error: string | null;
};

/**
 * TemplateSubMenu decides whether to display the empty state, or the list of templates based on the error and templates props.
 * @param templates - The list of templates (of type {@link Template}) to display.
 * @param ruleLibraryUrl - The URL to the rule library.
 * @param targetRuleHome - The scope which rules should be created under.
 * @param createRuleOrShowDialog - The function to call when a template is selected.
 * @param isDisabled - Boolean to determine if the dropdown is disabled (e.g. when the user clicks on the template to create a rule).
 * @param error - The error message to display.
 */
const TemplateSubMenu = ({
	templates,
	ruleLibraryUrl,
	targetRuleHome,
	createRuleOrShowDialog,
	isDisabled,
	error,
}: TemplateSubMenuProps) => {
	const shouldShowEmptyState = error || !templates?.length;
	if (shouldShowEmptyState) {
		return <TemplateEmptyState ruleLibraryUrl={ruleLibraryUrl} />;
	}
	return (
		<>
			<Box xcss={descriptionStyles}>
				<Text color="color.text.subtle" weight="regular" size="UNSAFE_small">
					<FormattedMessage {...messages.templateDropdownDescription} />
				</Text>
			</Box>
			<TemplateList
				templates={templates}
				onTemplateSelected={(templateId: string) =>
					createRuleOrShowDialog(templateId, targetRuleHome)
				}
				isDisabled={isDisabled}
			/>
			<Box xcss={linkStyles}>
				<Link href={ruleLibraryUrl} testId="discover-templates-link">
					<FormattedMessage {...messages.discoverMoreTemplates} />
				</Link>
			</Box>
		</>
	);
};

/**
 * TemplateDropdown is a HOC that renders a dropdown menu containing a list of templates that can be used to create automation rules.
 * @param env - The environment (of type {@link Environment}) that the client should target.
 * @param site - The site ARI that the client should target.
 * @param query - The query to filter templates.
 * @param targetRuleHome - The scope which rules should be created under
 * @param baseAutomationUrl - The host URL that the create/manage automations links should route to.
 * @param TemplatesContainer - Optional prop to override the default TemplatesContainer component, useful for testing.
 */
export const TemplateDropdown = ({
	env,
	site,
	query,
	targetRuleHome,
	onCreateFailure,
	onCreateSuccess,
	onFetchSuccess,
	onFetchFailed,
	baseAutomationUrl,
	dropdownMenuProps,
}: TemplateDropdownProps) => {
	di(TemplatesContainer);
	const ruleLibraryUrl = `${baseAutomationUrl}#/tab/rule-library`;
	const [didOpen, setDidOpen] = useState<boolean>(false);
	const handleOpen = async (fetchNext: () => Promise<void>, isOpen: boolean) => {
		if (didOpen || !isOpen) {
			return;
		}
		await fetchNext();
		setDidOpen(true);
	};

	return (
		// Note: The limit prop is set to 5, to allow displaying up to 5 templates in the dropdown, as per design guidelines.
		<AutomationIntlMessagesProvider locales={locales}>
			<TemplatesContainer
				env={env}
				site={site}
				query={query}
				limit={5}
				onCreateSuccess={onCreateSuccess}
				onCreateFailure={onCreateFailure}
				onFetchSuccess={onFetchSuccess}
				onFetchFailed={onFetchFailed}
			>
				{({ isLoading, isDisabled, templates, fetchNext, error, createRuleOrShowDialog }) => (
					<DropdownMenu<HTMLButtonElement>
						{...dropdownMenuProps}
						trigger={({ triggerRef, ...props }) => (
							<IconButton
								{...props}
								label={<FormattedMessage {...messages.templateDropdownTooltipLabel} />}
								isTooltipDisabled={false}
								testId="automation-button"
								tooltip={tooltipOptions}
								ref={triggerRef}
								icon={AutomationGlyph}
								appearance="subtle"
							/>
						)}
						isLoading={isLoading}
						onOpenChange={(args: OnOpenChangeArgs) => handleOpen(fetchNext, args.isOpen)}
						testId="template-dropdown"
					>
						<Stack grow="hug" testId="template.dropdown" xcss={menuStyles}>
							<Box xcss={headerStyles}>
								<Text color="color.text.subtle" weight="semibold" size="UNSAFE_small">
									<FormattedMessage {...messages.templateDropdownTitle} />
								</Text>
							</Box>
							{error ? (
								<ErrorState />
							) : (
								<TemplateSubMenu
									templates={templates}
									ruleLibraryUrl={ruleLibraryUrl}
									targetRuleHome={targetRuleHome}
									createRuleOrShowDialog={createRuleOrShowDialog}
									isDisabled={isDisabled}
									error={error}
								/>
							)}

							<Footer baseAutomationUrl={baseAutomationUrl} />
						</Stack>
					</DropdownMenu>
				)}
			</TemplatesContainer>
		</AutomationIntlMessagesProvider>
	);
};
