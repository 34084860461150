import React, { useState, type ComponentType } from 'react';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { FFErrorBoundary } from '../../../common/ff-error-boundary/index.tsx';
import { messages } from './messages.tsx';
import Actions from './actions/index.tsx';

export type Props = {
	IssueCount: ComponentType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	IssueSearch: ComponentType<any>;
	isQueuesErrorHeaderDisplayed: boolean;
	renderDisplayError: () => void;
};

export default function QueuePageDetails(props: Props) {
	const [addTopMargin, setAddTopMargin] = useState(false);

	const { renderDisplayError, IssueCount, IssueSearch, isQueuesErrorHeaderDisplayed } = props;
	const { formatMessage } = useIntl();

	const handleError = (location?: string) => {
		if (location === 'common.error-boundary.queues-details') {
			setAddTopMargin(true);
		}
		renderDisplayError();
	};

	const handleReload = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.reload();
	};

	const errorSectionDefaultStyles = xcss({
		marginTop: 'space.0',
	});

	const errorMarginTopStyles = xcss({
		marginTop: 'space.400',
	});

	const queueSearch = (
		<FFErrorBoundary onError={renderDisplayError} id="queue-issue-search">
			<Stack space="space.0">
				<IssueSearch />
				<IssueCount />
			</Stack>
		</FFErrorBoundary>
	);

	return (
		<FFErrorBoundary onError={handleError} id="queues-details-wrapper">
			<Stack
				space="space.400"
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
				xcss={[errorSectionDefaultStyles, addTopMargin && errorMarginTopStyles]}
			>
				{isQueuesErrorHeaderDisplayed && (
					<SectionMessage
						title={formatMessage(messages.errorSectionTitle)}
						appearance="error"
						actions={
							<SectionMessageAction onClick={handleReload}>
								<FormattedMessage {...messages.reloadPageLinkText} />
							</SectionMessageAction>
						}
					>
						<FormattedMessage {...messages.errorSectionBody} />
					</SectionMessage>
				)}
				{fg('jsm_views_inside_queues_-_main_flag') ? (
					<Box paddingBlockStart="space.150">
						<Flex alignItems="start" justifyContent="space-between">
							{queueSearch}
							<Box xcss={fixedActionButtonWidthStyles}>
								<Actions />
							</Box>
						</Flex>
					</Box>
				) : (
					queueSearch
				)}
			</Stack>
		</FFErrorBoundary>
	);
}

const fixedActionButtonWidthStyles = xcss({ flexShrink: 0 });
