import { getBaseAutomationUrl } from '@atlassian/jira-automation-menu/src/common/utils.tsx';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const buildNewRuleAutomationUrl = ({
	projectKey,
	isSimplified,
}: {
	projectKey: ProjectKey;
	isSimplified: boolean;
}) =>
	`${getBaseAutomationUrl({
		projectKey,
		isSimplified,
		projectType: SERVICE_DESK_PROJECT,
	})}#rule/new?componentFilterCriteria=SERVICE_MANAGEMENT_TRIGGERS`;

export const buildManageRulesAutomationUrl = ({
	projectKey,
	isSimplified,
}: {
	projectKey: ProjectKey;
	isSimplified: boolean;
}) =>
	`${getBaseAutomationUrl({
		projectKey,
		isSimplified,
		projectType: SERVICE_DESK_PROJECT,
	})}#/rule-list`;

export const buildAuditLogAutomationUrl = ({
	projectKey,
	isSimplified,
}: {
	projectKey: ProjectKey;
	isSimplified: boolean;
}) =>
	`${getBaseAutomationUrl({
		projectKey,
		isSimplified,
		projectType: SERVICE_DESK_PROJECT,
	})}#tab/audit-log`;

export const buildDiscoverAutomationUrl = ({
	projectKey,
	isSimplified,
}: {
	projectKey: ProjectKey;
	isSimplified: boolean;
}) =>
	`${getBaseAutomationUrl({
		projectKey,
		isSimplified,
		projectType: SERVICE_DESK_PROJECT,
	})}#/tab/rule-library`;

export const buildTemplateRuleAutomationUrl = (
	{
		projectKey,
		isSimplified,
	}: {
		projectKey: ProjectKey;
		isSimplified: boolean;
	},
	templateId: string,
) =>
	`${getBaseAutomationUrl({
		projectKey,
		isSimplified,
		projectType: SERVICE_DESK_PROJECT,
	})}#/rule/template?templateId=${templateId}`;
