import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	manageAutomationsItem: {
		id: 'automation-platform.footer.manage-automations-item',
		defaultMessage: 'Manage automations',
		description: 'Label for the manage automations item which is a link to project automation',
	},
	createAutomationItem: {
		id: 'automation-platform.footer.create-automation-item',
		defaultMessage: 'Create automation',
		description:
			'Label for the create automation item which is a link to rule creation in project automation',
	},
	auditLogItem: {
		id: 'automation-platform.footer.audit-log-item',
		defaultMessage: 'Audit log',
		description: 'Label for the audit log item which is a link to audit log in project automation',
	},
});

export default messages;
