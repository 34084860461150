import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../../../state/reducers/types.tsx';
import { isManuallySorted } from '../../../../state/selectors/sorting/index.tsx';
import { showIssueFailureError } from '../../../../state/selectors/ui/index.tsx';
import Actions from './view.tsx';

const mapStateToProps = (state: State) => ({
	isManuallySorted: isManuallySorted(state),
	showIssueFailureError: showIssueFailureError(state),
});

export default connect(mapStateToProps, {})(Actions);
