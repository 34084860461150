import { type Locales } from '@atlassian/automation-ui-commons';

export const locales: Locales = {
	cs: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-cs" */ '../../i18n/cs'
		),
	da: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-da" */ '../../i18n/da'
		),
	de: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-de" */ '../../i18n/de'
		),
	en_GB: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-en-GB" */ '../../i18n/en_GB'
		),
	en_ZZ: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-en-ZZ" */ '../../i18n/en_ZZ'
		),
	en: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-en" */ '../../i18n/en'
		),
	es: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-es" */ '../../i18n/es'
		),
	fi: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-fi" */ '../../i18n/fi'
		),
	fr: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-fr" */ '../../i18n/fr'
		),
	hu: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-hu" */ '../../i18n/hu'
		),
	it: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-it" */ '../../i18n/it'
		),
	ja: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-ja" */ '../../i18n/ja'
		),
	ko: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-ko" */ '../../i18n/ko'
		),
	nb: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-nb" */ '../../i18n/nb'
		),
	nl: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-nl" */ '../../i18n/nl'
		),
	pl: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-pl" */ '../../i18n/pl'
		),
	pt_BR: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-pt-BR" */ '../../i18n/pt_BR'
		),
	ru: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-ru" */ '../../i18n/ru'
		),
	sv: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-sv" */ '../../i18n/sv'
		),
	tr: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-tr" */ '../../i18n/tr'
		),
	zh_TW: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-zh-tw" */ '../../i18n/zh_TW'
		),
	zh: () =>
		import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/automation-templates-i18n-zh" */ '../../i18n/zh'
		),
};
