import React from 'react';

import Checkbox from '@atlaskit/checkbox';
import { CheckboxField } from '@atlaskit/form';

interface BooleanParameterInput {
	name: string;
}

const BooleanParameterInput = ({ name }: BooleanParameterInput) => {
	return (
		<CheckboxField name={name} defaultIsChecked={false}>
			{({ fieldProps }: any) => (
				<Checkbox {...fieldProps} label={name} testId={'boolean-parameter-input-' + name} />
			)}
		</CheckboxField>
	);
};

export default BooleanParameterInput;
