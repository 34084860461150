import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	templateDropdownTooltipLabel: {
		id: 'automation-platform.automation-templates.template-dropdown-tooltip-label',
		defaultMessage: 'Automation',
		description: 'The label for the tooltip that appears when hovering over the automation button',
	},
	templateDropdownTitle: {
		id: 'automation-platform.automation-templates.template-dropdown-title',
		defaultMessage: 'Automation',
		description: 'The title for the dropdown menu that contains the automation templates',
	},
	templateDropdownDescription: {
		id: 'automation-platform.automation-templates.template-dropdown-description',
		defaultMessage: "Boost your team's efficiency by creating automations from these templates.",
		description: 'The description for the dropdown menu that contains the automation templates',
	},
	discoverMoreTemplates: {
		id: 'automation-platform.automation-templates.discover-more-templates',
		defaultMessage: 'Discover more templates',
		description: 'The description of the link to the page to see more automation templates',
	},
});
