import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { ErrorMessage, Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

import { messages } from './messages';

interface TextParameterInputProps {
	name: string;
	required: boolean;
}

enum Errors {
	EMPTY = 'EMPTY',
}

const TextParameterInput = ({ name, required }: TextParameterInputProps) => {
	const validate = (value?: string): Errors | undefined =>
		required && !value ? Errors.EMPTY : undefined;

	return (
		<Field name={name} label={name} isRequired={required} validate={validate}>
			{({ fieldProps, error }: any) => (
				<>
					<Textfield {...fieldProps} testId={'text-parameter-input-' + name} />
					{error === Errors.EMPTY && (
						<ErrorMessage testId={'text-parameter-error-' + name}>
							<FormattedMessage {...messages.parameterModalErrorEmptyText} />
						</ErrorMessage>
					)}
				</>
			)}
		</Field>
	);
};

export default TextParameterInput;
