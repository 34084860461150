import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const deselectAllIssues =
	(analyticsEvent?: UIAnalyticsEvent): Action<State> =>
	({ getState, setState }) => {
		const { issueKeys } = getState();

		if (!isEmpty(issueKeys)) {
			setState({ issueKeys: [] });
			analyticsEvent &&
				fireTrackAnalytics(analyticsEvent, 'bulkSelection reduced', {
					selectedIssuesCount: 0,
					previousSelectedIssuesCount: issueKeys.length,
				});
		}
	};
