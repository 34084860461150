import { Component, type ReactNode, type ErrorInfo } from 'react';

export type Props<A> = {
	children: ReactNode;
	// @ts-expect-error - TS2300 - Duplicate identifier 'error'. | TS2300 - Duplicate identifier 'error'.
	// eslint-disable-next-line jira/react/handler-naming
	handler: (error: Error, error: ErrorInfo, arg: A) => void;
	arg: A;
	isRethrowEnabled: boolean;
};

class OnCatch<A> extends Component<Props<A>> {
	static defaultProps = { arg: undefined, isRethrowEnabled: false };

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		const { handler, isRethrowEnabled, arg } = this.props;

		try {
			handler(error, errorInfo, arg);
		} finally {
			// No throwing in error handlers!
		}

		if (isRethrowEnabled) {
			throw error;
		}
	}

	render() {
		return this.props.children;
	}
}

export default OnCatch;
