/** @jsx jsx */
import React from 'react';
import { styled, css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { withTheme } from '../../../app/context/theme-context/index.tsx';
import type { CompiledTheme } from '../../../model/themes/index.tsx';
import TableBlanket from '../overlays/blanket/index.tsx';
import RowList from './rows/row-list/index.tsx';
import HorizontalScrollbar from './scrollbars/horizontal/index.tsx';
import VerticalScrollbar from './scrollbars/vertical/index.tsx';
import UtilityRow from './utility-row/index.tsx';

type Props = {
	isHorizontalScrollVisible: boolean;
	isContentStale: boolean;
	shouldHideLoadingIndicator: boolean;
	theme: CompiledTheme;
};
function Content(props: Props) {
	const { isHorizontalScrollVisible, isContentStale, shouldHideLoadingIndicator, theme } = props;
	return (
		<Wrapper>
			{__SERVER__ ? (
				<div css={hiddenScrollBarsStyles}>
					<InnerWrapper theme={theme} {...(fg('jsm_virtual_table_a11y') && { role: 'rowgroup' })}>
						{/* @ts-expect-error - Type '{}' is missing the following properties from type 'Readonly<Omit<Props, never>>': onRowListFocusFunctionChanged, onReactPortalContainerRefChanged, isAutoHeight, AutoHeightList, VirtualList */}
						<RowList />
						{/* @ts-expect-error - Type '{}' is missing the following properties from type 'Readonly<Omit<Props, never>>': coreColumnsWidth, AddLink, ScrollArea */}
						<UtilityRow />
					</InnerWrapper>
					{isContentStale && (
						// @ts-expect-error - TS2322 - Type '{ shouldHideLoadingIndicator: boolean; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<Omit<DispatchProp<any>, "dispatch">, any, any>> & Readonly<...> & Readonly<...>'.
						<TableBlanket shouldHideLoadingIndicator={shouldHideLoadingIndicator} />
					)}
				</div>
			) : (
				<VerticalScrollbar>
					<InnerWrapper theme={theme} {...(fg('jsm_virtual_table_a11y') && { role: 'rowgroup' })}>
						{/* @ts-expect-error - Type '{}' is missing the following properties from type 'Readonly<Omit<Props, never>>': onRowListFocusFunctionChanged, onReactPortalContainerRefChanged, isAutoHeight, AutoHeightList, VirtualList */}
						<RowList />
						{/* @ts-expect-error - Type '{}' is missing the following properties from type 'Readonly<Omit<Props, never>>': coreColumnsWidth, AddLink, ScrollArea */}
						<UtilityRow />
					</InnerWrapper>
					{isContentStale && (
						// @ts-expect-error - TS2322 - Type '{ shouldHideLoadingIndicator: boolean; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<Omit<DispatchProp<any>, "dispatch">, any, any>> & Readonly<...> & Readonly<...>'.
						<TableBlanket shouldHideLoadingIndicator={shouldHideLoadingIndicator} />
					)}
				</VerticalScrollbar>
			)}
			{isHorizontalScrollVisible && (
				<HorizontalScrollBarWrapper>
					<HorizontalScrollbar />
				</HorizontalScrollBarWrapper>
			)}
		</Wrapper>
	);
}

Content.defaultProps = {
	isContentStale: false,
	shouldHideLoadingIndicator: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
});

const hiddenScrollBarsStyles = css({
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InnerWrapper = styled.div<{ theme: CompiledTheme }>({
	paddingRight: token('space.400'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	'.virtual-table-row': ({ theme }) => ({
		'&:hover': {
			backgroundColor: theme.row.useCssHover
				? token('color.background.neutral.subtle.hovered')
				: 'inherit',
		},
	}),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HorizontalScrollBarWrapper = styled.div({
	marginBottom: token('space.100'),
	marginTop: token('space.150'),
	paddingBottom: token('space.050'),
	position: 'relative',
});

export default withTheme(Content);
