// Issue Cache API is an internal tool and this file should not be edited. Please contact Odin team for any modifications.

import type { CacheAPI } from '@atlassian/jira-cache/src/common/types.tsx';
import { Cache } from '@atlassian/jira-cache/src/common/utils/storage/index.tsx';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import IssueMutation from '../issue-mutation/index.tsx';
import { MutationSource, CacheType } from './constants.tsx';
import { mergeData } from './utils.tsx';

const MAX_AGE_MIN = 5;
const MAX_AGE = MAX_AGE_MIN * 60 * 1000;
const CACHE_SIZE = 100;
const CACHE_KEY_PREFIX = '__storejs';
const BE_CACHE_SAVE_ISSUE_MUTATION = '/gateway/api/jira-jql-service/api/v1/issue-mutation';

// TODO: JFP-2253 Owning team to add a prefix here for the local storage provider and update the same for the test
const localStorageProvider = createLocalStorageProvider('');
export default class IssueCache {
	maxAge: number;

	namespace: string;

	#localCache: CacheAPI<
		string,
		{
			[key: string]: IssueMutation;
		}
	>;

	#sessionCache: CacheAPI<
		string,
		{
			[key: string]: IssueMutation;
		}
	>;

	static activationID: string;

	static cloudID: string;

	static userID: string | null;

	constructor(namespace: string, maxAge: number = MAX_AGE) {
		this.maxAge = maxAge;
		this.namespace = namespace;

		// @ts-expect-error - TS7009 - 'new' expression, whose target lacks a construct signature, implicitly has an 'any' type.
		this.#localCache = new Cache<
			string,
			{
				[key: string]: IssueMutation;
			}
		>({
			size: CACHE_SIZE,
			storageType: CacheType.LOCAL,
			cacheKey: namespace,
		});

		// @ts-expect-error - TS7009 - 'new' expression, whose target lacks a construct signature, implicitly has an 'any' type.
		this.#sessionCache = new Cache<
			string,
			{
				[key: string]: IssueMutation;
			}
		>({
			size: CACHE_SIZE,
			storageType: CacheType.SESSION,
			cacheKey: namespace,
		});
	}

	/**
	 * A method to set tenant level details.
	 * These details include activation id, cloud id and user id
	 */

	static setTenantDetails(
		activationID: string | undefined,
		cloudID: string | undefined,
		userID: string | null | undefined,
	) {
		if (activationID && activationID !== IssueCache.activationID)
			IssueCache.activationID = activationID;
		if (cloudID && cloudID !== IssueCache.cloudID) IssueCache.cloudID = cloudID;
		if (userID && userID !== IssueCache.userID) IssueCache.userID = userID;
	}

	/**
	 * A util method to decide whether an issue mutation in cache is stale or not
	 */
	static isIssueMutationStale(issueMutation: IssueMutation): boolean {
		const lastModified: number = new Date(issueMutation.lastModified).getTime();
		return Date.now() - lastModified < MAX_AGE;
	}

	/**
	 * A util method to generate partial key containing username for storing in cache
	 */
	static generateKey(): string {
		let anonymousId = fg('jfp-vulcan-browser-storage-migration')
			? localStorageProvider.get('ajs_anonymous_id')
			: // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.localStorage.getItem('ajs_anonymous_id');
		anonymousId = anonymousId ? `.${anonymousId}` : '';
		anonymousId = anonymousId.replace(/["]+/g, '');
		// TODO : Replace this with something more generic
		return `jsis.ecCache.data${anonymousId}.newIssuesMutationList`;
	}

	/**
	 * A method to generate key containing username as well as namespace for storing in cache
	 */
	generateReadKey(): string {
		return `${CACHE_KEY_PREFIX}_${this.namespace}_${IssueCache.generateKey()}`;
	}

	/**
	 * A method to get the issue mutations stored in the cache
	 * Accepts issueIds or array of issuesIds and returns corresponding issue mutations
	 * Returns all the issue mutations when provided with null argument in form of an array
	 * Cleans the cache before accessing issue mutations in order to avoid stale data
	 */
	async get(key: null | string | string[]): Promise<{
		[key: string]: IssueMutation;
	}> {
		return this.cleanCache().then(() => this.getWithoutClean(key));
	}

	/**
	 * A method to get the issue mutations stored in the cache
	 * Accepts issueIds or array of issuesIds to return selective issue mutations
	 * Returns all the issue mutations when provided with null argument
	 */
	async getWithoutClean(key?: null | string | string[]): Promise<{
		[key: string]: IssueMutation;
	}> {
		if (key == null) {
			return new Promise((resolve) => {
				this.#localCache
					.get(this.generateReadKey())
					.then((data) => (data ? resolve(data) : resolve({})));
			});
		}

		return new Promise(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(resolve: (result: Promise<Record<any, any>> | Record<any, any>) => void) => {
				this.#localCache.get(this.generateReadKey()).then((data) => {
					if (!data) resolve({});
					else {
						const iterableData = data;
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						const newData: Record<string, any> = {};
						if (typeof key === 'string') newData[key] = iterableData[key];
						else if (Array.isArray(key)) {
							key.forEach((issueId) => {
								newData[issueId] = iterableData[issueId];
							});
						}
						resolve(newData);
					}
				});
			},
		);
	}

	/**
	 * A method to construct object to be stored in backend cache
	 * Can receive an issue mutation or an array of issue mutations to be saved in cache
	 */
	static constructBackendCacheObject(value: IssueMutation | IssueMutation[]): Object | Object[] {
		if (value instanceof IssueMutation) {
			return {
				issueId: value.issueId,
				mutationType: value.mutationType,
				mutationTimestamp: value.lastModified,
			};
		}
		const result: Object[] = [];
		value.forEach((data: IssueMutation) => {
			result.push(IssueCache.constructBackendCacheObject(data));
		});

		return result;
	}

	/**
	 * A method to save the issue mutations in the backend cache
	 * Can receive an issue mutation or an array of issue mutations to be saved in cache
	 */
	static setBackendCache(data: IssueMutation | IssueMutation[]) {
		try {
			let issueBody: Object[] | Object = IssueCache.constructBackendCacheObject(data);
			let trackingId = '-1';
			if (data instanceof IssueMutation) {
				trackingId = String(new Date(data.lastModified).valueOf()) + (IssueCache.userID || '');
				issueBody = [issueBody];
			} else {
				trackingId = String(new Date(data[0].lastModified).valueOf()) + (IssueCache.userID || '');
			}

			fetchJson(BE_CACHE_SAVE_ISSUE_MUTATION, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'ATL-activationId': IssueCache.activationID || '',
					'atl-CloudId': IssueCache.cloudID || '',
					trackingId,
				},
				body: JSON.stringify({
					issueMutations: issueBody,
				}),
			});
			return true;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			logger.safeErrorWithoutCustomerData(
				'jsis-ec-client',
				`${e?.toString() || ''}mutationObj: ${data}`,
			);
			return false;
		}
	}

	/**
	 * A method to save the issue mutations in the cache
	 * Can receive an issue mutation or an array of issue mutations to be saved in cache
	 */
	async set(value: IssueMutation | IssueMutation[]): Promise<boolean> {
		return this.getWithoutClean().then(async (cacheData) => {
			if (value instanceof IssueMutation) {
				const storeValue = { [value.issueId]: value };
				return new Promise((resolve: (result: Promise<boolean> | boolean) => void) => {
					try {
						this.#localCache.set(this.generateReadKey(), mergeData(cacheData, storeValue));
						resolve(true);
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (e: any) {
						resolve(false);
					}
				});
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const storeData: Record<string, any> = {};
			value.forEach((data) => {
				storeData[data.issueId] = data;
			});

			return new Promise((resolve: (result: Promise<boolean> | boolean) => void) => {
				try {
					this.#localCache.set(this.generateReadKey(), mergeData(cacheData, storeData));
					resolve(true);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (e: any) {
					resolve(false);
				}
			});
		});
	}

	/**
	 * A method to create a new issue mutation in cache
	 * Can receive an issue ids or an array of issue ids to be created in cache
	 */
	async create(value: string[] | string): Promise<boolean> {
		return this.cleanCache().then(() => {
			if (!Array.isArray(value))
				return this.set(
					new IssueMutation(value, MutationSource.CREATE, '1', [], new Date().toISOString()),
				);

			const storeData = value.map(
				(data) => new IssueMutation(data, MutationSource.CREATE, '1', [], new Date().toISOString()),
			);
			return this.set(storeData);
		});
	}

	/**
	 * A method to update an already existing issue mutation in cache
	 * Can receive an issue ids or an array of issue ids to be updated in cache
	 */
	async update(value: string | string[]): Promise<boolean> {
		if (ff('odin.enable.backend.cache.ec.client')) {
			if (typeof value === 'string') {
				return this.set(new IssueMutation(value, MutationSource.UPDATE, '1', []));
			}
			return this.set(
				value.map((issueId: string) => new IssueMutation(issueId, MutationSource.UPDATE, '1', [])),
			);
		}

		return this.getWithoutClean(value).then((data) => {
			const valueToUpdate: Array<IssueMutation> = [];
			Object.entries(data).forEach(([issueId, issueMutationObj]) => {
				if (issueMutationObj instanceof IssueMutation) {
					if (IssueCache.isIssueMutationStale(issueMutationObj))
						valueToUpdate.push(
							new IssueMutation(
								String(issueId),
								MutationSource.UPDATE,
								issueMutationObj.version,
								issueMutationObj.matchStatus,
								new Date().toISOString(),
							),
						);
					else
						valueToUpdate.push(
							new IssueMutation(
								String(issueId),
								MutationSource.CREATE,
								'1',
								issueMutationObj.matchStatus,
								new Date().toISOString(),
							),
						);
				} else {
					valueToUpdate.push(
						new IssueMutation(
							String(issueId),
							MutationSource.UPDATE,
							// TODO: Incorporate the version and match status and change the update method signature
							'1',
							[],
							new Date().toISOString(),
						),
					);
				}
			});
			return this.set(valueToUpdate);
		});
	}

	/**
	 * A method to delete an issue mutation in cache
	 */
	async delete(key: string | string[]): Promise<boolean> {
		if (typeof key === 'string')
			return this.set(
				new IssueMutation(key, MutationSource.DELETE, '', [], new Date().toISOString()),
			);

		const storeData = key.map(
			(data) => new IssueMutation(data, MutationSource.DELETE, '', [], new Date().toISOString()),
		);
		return this.set(storeData);
	}

	/**
	 * A method to clear the cache
	 */
	async clear(): Promise<void> {
		return this.#localCache.clear();
	}

	/**
	 * A method that selectively deletes the stale cache entries
	 */
	async cleanCache(): Promise<boolean> {
		// We don't clean the backend cache
		if (ff('odin.enable.backend.cache.ec.client')) {
			return Promise.resolve(true);
		}

		return this.getWithoutClean(null).then((data) => {
			const issuesToKeep: Array<IssueMutation> = [];

			if (
				data &&
				Object.keys(data).length !== 0 &&
				Object.getPrototypeOf(data) === Object.prototype
			) {
				Object.keys(data).forEach((issueId) => {
					const issueMutationObj = data[issueId];
					if (IssueCache.isIssueMutationStale(issueMutationObj))
						issuesToKeep.push(issueMutationObj);
				});
			}

			return this.clear().then(() => this.set(issuesToKeep));
		});
	}

	/**
	 * A method to check if an issue id is present in the cache
	 */
	isPresent(issueId: string): Promise<boolean> {
		return this.get(issueId).then((data) => !!data && !!Object.values(data)[0]);
	}

	/**
	 * A method to check if any mutation is present in the cache
	 */
	isEmpty(): Promise<boolean> {
		return this.get(null).then((data) => Object.keys(data).length === 0);
	}

	/**
	 * A method to find the size of the local storage for any given key
	 */
	static getLocalStorageSizeForGivenKey(key: string): number {
		const value = fg('jfp-vulcan-browser-storage-migration')
			? localStorageProvider.get(key)
			: // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.localStorage.getItem(key);
		if (value != null) {
			const keySize = value.length;
			return keySize;
		}
		return 0;
	}

	/**
	 * A method to find the size of the local storage for any given key
	 * It returns size of all the keys when provided with no key
	 */
	static getLocalStorageSize(key?: string): number {
		if (key != null) {
			return IssueCache.getLocalStorageSizeForGivenKey(key);
		}

		let totalSize = 0;
		if (fg('jfp-vulcan-browser-storage-migration')) {
			Object.keys(localStorageProvider).forEach((keys: string) => {
				totalSize += IssueCache.getLocalStorageSizeForGivenKey(keys);
			});
		} else {
			Object.keys(localStorage).forEach((keys: string) => {
				totalSize += IssueCache.getLocalStorageSizeForGivenKey(keys);
			});
		}
		return totalSize;
	}
}
