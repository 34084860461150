/** @jsx jsx */
import React, { Component, type ComponentType, createRef } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { withTheme } from '../../../../../../app/context/theme-context/index.tsx';
import type {
	RowId,
	RowContentUpdateCallback,
	RowContentMountCallback,
	OnFocusCallback,
} from '../../../../../../model/rows/index.tsx';
import type { CompiledTheme } from '../../../../../../model/themes/index.tsx';
import { RowHighlight } from '../../../../common/styled.tsx';

// regarding height & overflow:
// we set the height here to reduce positioning complexity.
// otherwise we need to consider the add bar and its' visibility
// while offsetting margins on the row
const getOuterWrapperStyles = (isSelected: boolean) => ({
	...(isSelected ? { backgroundColor: token('color.background.selected') } : {}),
	marginLeft: token('space.0'),
	outline: 'none',
});

const getContentWrapperStyles = () => ({
	height: 'auto',
	minHeight: token('space.500'),
	overflow: 'hidden',
	marginTop: token('space.0'),
	borderRadius: token('space.050'),
	flexGrow: 1,
});

// The following styles create a faux absolute element with the height and width of its flex(direction row) parent.
// We cannot use position relative on OuterWrapper as elements in virtual-table-row-list-portal
// depend on a relative parent at the table level.
const rowHighlightWrapperStylesOld = {
	position: 'relative',
	flexShrink: 0,
	width: '100%',
	marginLeft: '-100%',
	transform: 'translateX(100%)',
	pointerEvents: 'none',
} as const;

type Props = {
	id: RowId;
	setFocusOnRow: boolean;
	isActiveItem: boolean;
	isRowHighlighted: boolean;
	isRowLeftEdgeVisible: boolean;
	isRowRightEdgeVisible: boolean;
	isGlobalAddEnabled: boolean;
	isSelected: boolean;
	onClick: () => void;
	AddBar: ComponentType<{
		rowId: RowId;
	}>;
	Content: ComponentType<{
		id: RowId;
		onRowContentMount: RowContentMountCallback;
		onRowContentUpdate: RowContentUpdateCallback;
	}>;
	theme: CompiledTheme;
	onRowContentMount: RowContentMountCallback;
	onRowContentUpdate: RowContentUpdateCallback;
	onFocusCallback: OnFocusCallback;
};

type State = {
	render: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
class DefaultRow extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { render: false };
	}

	componentDidMount() {
		this.timeoutId = setTimeout(() => this.setState({ render: true }), 250); // we render the AddBar quite delayed as it will only be visible when hovered
		if (this.props.isActiveItem && this.myRef.current) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			this.myRef.current.focus();
		}
		this.myRef.current &&
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			this.myRef.current.addEventListener('focusin', this.props.onFocusCallback);
	}

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		const {
			id,
			isActiveItem,
			isRowHighlighted,
			isRowLeftEdgeVisible,
			isRowRightEdgeVisible,
			isSelected,
		} = this.props;
		return (
			id !== nextProps.id ||
			this.state.render !== nextState.render ||
			isActiveItem !== nextProps.isActiveItem ||
			isRowHighlighted !== nextProps.isRowHighlighted ||
			isRowLeftEdgeVisible !== nextProps.isRowLeftEdgeVisible ||
			isRowRightEdgeVisible !== nextProps.isRowRightEdgeVisible ||
			isSelected !== nextProps.isSelected
		);
	}

	componentDidUpdate(prevProps: Props) {
		if (
			this.props.isActiveItem &&
			!prevProps.isActiveItem &&
			this.myRef.current &&
			this.props.setFocusOnRow
		) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			this.myRef.current.focus();
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timeoutId);
		this.myRef.current &&
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			this.myRef.current.removeEventListener('focusin', this.props.onFocusCallback);
	}

	myRef = createRef();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	timeoutId: any;

	render() {
		const {
			id,
			isRowHighlighted,
			isRowLeftEdgeVisible,
			isRowRightEdgeVisible,
			isGlobalAddEnabled,
			AddBar,
			Content,
			theme,
			onClick,
			onRowContentMount,
			onRowContentUpdate,
			isSelected,
		} = this.props;

		return fg('jsm_virtual_table_a11y') ? (
			<Box
				// @ts-expect-error - TS2322 - Type 'RefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
				ref={this.myRef}
				onClick={onClick}
				xcss={[outerWrapperStyles, isSelected && selectOuterWrapperStyles]}
				// tabIndex is needed to be able to focus / navigate through rows and scroll vertically while navigating through the rows that are offscreen
				tabIndex={-1}
			>
				<Box xcss={innerWrapperStyles} role="row">
					{isRowHighlighted && (
						<div css={rowHighlightWrapperStyles}>
							<RowHighlight
								theme={theme}
								isRowLeftEdgeVisible={isRowLeftEdgeVisible}
								isRowRightEdgeVisible={isRowRightEdgeVisible}
							/>
						</div>
					)}
					<Box xcss={contentWrapperStyles}>
						<Content
							id={id}
							onRowContentMount={onRowContentMount}
							onRowContentUpdate={onRowContentUpdate}
						/>
					</Box>
				</Box>

				{isGlobalAddEnabled && this.state.render && <AddBar rowId={id} />}
			</Box>
		) : (
			<div
				// @ts-expect-error - TS2322 - Type 'RefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
				ref={this.myRef}
				onClick={onClick}
				role="presentation"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={getOuterWrapperStyles(isSelected)}
				tabIndex={-1}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
				<div style={{ display: 'flex' }}>
					{isRowHighlighted && (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						<div style={rowHighlightWrapperStylesOld}>
							<RowHighlight
								theme={theme}
								isRowLeftEdgeVisible={isRowLeftEdgeVisible}
								isRowRightEdgeVisible={isRowRightEdgeVisible}
							/>
						</div>
					)}
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
					<div style={getContentWrapperStyles()}>
						<Content
							id={id}
							onRowContentMount={onRowContentMount}
							onRowContentUpdate={onRowContentUpdate}
						/>
					</div>
				</div>

				{isGlobalAddEnabled && this.state.render && <AddBar rowId={id} />}
			</div>
		);
	}
}

export default ComponentWithAnalytics('rowItem', {
	onClick: 'clicked',
})(withTheme(DefaultRow));

const outerWrapperStyles = xcss({
	marginLeft: 'space.0',
	outline: 'none',
});

const selectOuterWrapperStyles = xcss({
	backgroundColor: 'color.background.selected',
});

const innerWrapperStyles = xcss({
	display: 'flex',
});

const rowHighlightWrapperStyles = css({
	position: 'relative',
	flexShrink: 0,
	width: '100%',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-100%',
	transform: 'translateX(100%)',
	pointerEvents: 'none',
});

const contentWrapperStyles = xcss({
	height: 'auto',
	minHeight: 'space.500',
	overflow: 'hidden',
	marginTop: 'space.0',
	borderRadius: 'space.050',
	flexGrow: 1,
});
