import { useMemo } from 'react';
import { useQueueCustomRouterContext } from '@atlassian/jira-servicedesk-common/src/navigation/queues/use-queue-custom-router-context/index.tsx';
import { useCategorizedGroupsNavItems } from '@atlassian/jira-servicedesk-queues-categorized-store/src/controllers/priority-group/index.tsx';
import {
	useCategorizedNavItems,
	useFavouriteNavItems,
} from '@atlassian/jira-servicedesk-queues-categorized-store/src/main.tsx';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { usePathParam, useRouter } from '@atlassian/react-resource-router';
import {
	getCategoryStateKey,
	usePoppedOutState,
} from '../../common/controllers/queues-category-state/index.tsx';
import { QueueListSection } from '../../common/types/index.tsx';
import { isValidCategorisedViewQueueUrl } from '../common/index.tsx';
import type { UsePopoutConfigReturn } from './types.tsx';

const getPopoutConfig = (
	isPoppedOut: boolean,
	queueSection?: QueueListSection,
): UsePopoutConfigReturn => ({
	isPoppedOut,
	highestAvailableSection: isPoppedOut ? queueSection : undefined,
});

export const usePopoutConfig = (
	projectKey: string,
	practice: ItsmPractice,
): UsePopoutConfigReturn => {
	const [{ location }] = useRouter();
	const pathname = location.pathname;

	const queueCustomContext = useQueueCustomRouterContext(projectKey);
	const [allQueues] = useCategorizedNavItems('', projectKey, practice, queueCustomContext);
	const [starredQueues] = useFavouriteNavItems('', projectKey, practice, queueCustomContext);
	const [isPoppedOut] = usePoppedOutState(getCategoryStateKey(projectKey, practice));
	const [queueIdFromPath] = usePathParam('queueId');
	const [boardIdFromPath] = usePathParam('boardId');
	const [groups] = useCategorizedGroupsNavItems(projectKey, practice, queueCustomContext);
	const isCategorisedViewQueueUrl = isValidCategorisedViewQueueUrl(
		projectKey,
		practice,
		queueIdFromPath,
		pathname,
		boardIdFromPath,
	);
	const queueFoundInPractice = allQueues?.some((queue) => queue.id === queueIdFromPath);

	return useMemo(() => {
		// First, determine if the popout is active according to the criteria below
		//  - The popout must be in the popped out state (i.e. the user has clicked the popout button)
		//  - The current URL must be showing a valid view queue page
		//  - The current queue must be part of the practice
		if (!isPoppedOut || !isCategorisedViewQueueUrl || !queueFoundInPractice) {
			return getPopoutConfig(false);
		}

		// Next, if the popout is active, determine the highest available queue section available in this popout
		const hasNonEmptyPriorityGroups = groups?.some((group) => group.queues.length > 0);
		const hasStarredQueues = starredQueues && starredQueues.length > 0;
		if (hasStarredQueues) {
			return getPopoutConfig(true, QueueListSection.STARRED);
		}
		if (hasNonEmptyPriorityGroups) {
			return getPopoutConfig(true, QueueListSection.PRIORITY_GROUP);
		}
		return getPopoutConfig(true, QueueListSection.ALL_QUEUES);
	}, [isPoppedOut, isCategorisedViewQueueUrl, queueFoundInPractice, groups, starredQueues]);
};
