import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { PACKAGE_NAME, TEAM_NAME } from '../../../../common/constants.tsx';
import type { State, ActionProps } from '../../types.tsx';

export type FetchState = () => Promise<void>;

type AutomationTemplatesResponse = {
	showAutomation: boolean;
	projectSpecificTemplateIds: string[];
	genericTemplateIds: string[];
};

export const fetchState =
	({ projectId, createAnalyticsEvent }: ActionProps) =>
	({ getState, setState }: StoreActionApi<State>): FetchState =>
	async () => {
		const { isLoading, templateIds } = getState();
		if (isLoading || templateIds) {
			return;
		}

		try {
			const fetchStartTime = performance.now();
			const { showAutomation, genericTemplateIds } = await fetchJson<AutomationTemplatesResponse>(
				`/rest/servicedesk/latest/automation/templates/${projectId}`,
			);
			addSpanToAll(
				'fetch',
				'rest/servicedesk/latest/automation/templates/projectId',
				[{ name: 'network' }],
				fetchStartTime,
				performance.now(),
			);
			setState({
				isVisible: showAutomation,
				templateIds: genericTemplateIds,
			});

			fireTrackAnalytics(createAnalyticsEvent({}), `${PACKAGE_NAME}.fetchState succeeded`);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			!isClientFetchError(error) &&
				fireErrorAnalytics({
					error,
					meta: {
						id: 'fetchState',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					sendToPrivacyUnsafeSplunk: true,
				});

			throw error;
		}
	};
