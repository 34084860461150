import noop from 'lodash/noop';
import type { ItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, type StoreActionApi } from '@atlassian/react-sweet-state';
import { getActions as createActions } from '../actions/index.tsx';
import type { ChildrenProps as Actions } from '../types.tsx';

type State = {
	currentCacheKey: string;
	actionsByCategory: Record<ItsmPractice, Actions>;
};

export const INITIAL_STATE: State = {
	currentCacheKey: '',
	// @ts-expect-error(PARTIAL_RECORD) TS2740 - Type '{}' is missing the following properties from type 'Record<ItsmPractice, ChildrenProps>': "change-management", "incident-management", "problem-management", "service-request", and 2 more.
	actionsByCategory: {},
};

export const storeActions = {
	// Actions are lazily created for each category of a project.
	// Project key is used as the cache key. Once the cache key has changed
	// the store will be reset
	getActions:
		(...args: Parameters<typeof createActions>) =>
		({ getState, setState }: StoreActionApi<State>): Actions => {
			const [category, , { projectKey }] = args;
			const { currentCacheKey, actionsByCategory } = getState();
			const categoryActions = actionsByCategory[category];
			const hasSameKey = projectKey === currentCacheKey;

			if (hasSameKey && Boolean(categoryActions)) {
				return categoryActions;
			}

			const newCategoryActions = createActions(...args);
			setState({
				currentCacheKey: projectKey,
				// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type '{ "change-management"?: Actions | undefined; "incident-management"?: Actions | undefined; "problem-management"?: Actions | undefined; "service-request"?: Actions | undefined; "post-incident-review"?: Actions | undefined; "no-category"?: Actions | undefined; }' is not assignable to type 'Record<ItsmPractice, ChildrenProps>'.
				actionsByCategory: {
					...(hasSameKey ? actionsByCategory : {}),
					[category]: newCategoryActions,
				},
			});

			return newCategoryActions;
		},
} as const;

const Store = createStore<State, typeof storeActions>({
	initialState: INITIAL_STATE,
	actions: storeActions,
	name: 'jira-servicedesk-categorized-queues-actions-store',
});

const useActionsStore = createHook(Store, {
	selector: noop,
});

export const useCategorizedActionsStore = (...args: Parameters<typeof createActions>) => {
	const [, { getActions }] = useActionsStore();
	return getActions(...args);
};
export default useCategorizedActionsStore;
