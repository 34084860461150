import React, { useMemo } from 'react';

import { type IconProps, type NewIconProps } from '@atlaskit/icon';
import DashboardIcon from '@atlaskit/icon/core/dashboard';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { isCrossProductIcon } from '@atlassian/automation-ui-commons';

import { type IconSizeType, type TemplateIconType } from './types';

const borderContainerStyles = xcss({
	backgroundColor: 'elevation.surface',
	borderRadius: 'border.radius.200',
	boxSizing: 'border-box',
	height: 'size.300',
	padding: 'space.025',
	width: 'size.300',
});

const baseStyles = xcss({
	alignItems: 'center',
	padding: 'space.100',
	borderRadius: 'border.radius.200',
	display: 'inline-flex',
	height: '100%',
	justifyContent: 'center',
	width: '100%',
});

const triggerStyles = xcss({
	backgroundColor: 'color.background.success',
	zIndex: 'card',
});

const actionStyles = xcss({
	backgroundColor: 'color.background.selected',
});

const crossProductStyles = xcss({
	backgroundColor: 'color.background.neutral',
});

function getPrimaryColor(
	icon: React.ComponentType<IconProps | (NewIconProps & { spacing: 'spacious' })>,
	type: string,
) {
	if (isCrossProductIcon(icon)) {
		return;
	}

	switch (type) {
		case 'trigger':
			return token('color.icon.accent.green', colors.G500);
		case 'action':
			return token('color.icon.accent.blue', colors.B500);
		default:
			return token('color.icon', colors.N200);
	}
}

interface Props {
	icon: React.ComponentType<IconProps | (NewIconProps & { spacing: 'spacious' })>;
	size: IconSizeType;
	type: TemplateIconType;
}

/**
 *
 * Component which creates a React Element rendering out a template symbol.
 *
 * @param props - icons, size, type
 */
const TemplateSymbol = ({ icon, size, type }: Props) => {
	const primaryColor = getPrimaryColor(icon, type);
	const templateIcon = useMemo(() => {
		try {
			const Icon = icon ?? DashboardIcon;
			return <Icon label="" size={size} color={primaryColor} spacing="spacious" />;
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn('Failed to create icon element, using defaults.');
			return React.createElement(DashboardIcon, {
				label: '',
			});
		}
	}, [icon, size, primaryColor]);

	return templateIcon || null;
};

/**
 *
 * Container for TemplateSymbols which contain the logic for resolving iconType and whether it is a cross product icon.
 *
 * @param props - icons, size, type
 */
export const TemplateIcon = ({ icon, size, type }: Props) => {
	return (
		<Box xcss={borderContainerStyles}>
			<Box
				xcss={[
					baseStyles,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					isCrossProductIcon(icon)
						? crossProductStyles
						: type === 'trigger'
							? triggerStyles
							: actionStyles,
				]}
			>
				<TemplateSymbol icon={icon} size={size} type={type} />
			</Box>
		</Box>
	);
};
