import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'servicedesk.queues.agent-view.layout.queues-details.issue-search.placeholder',
		defaultMessage: 'Search for issues',
		description: 'Placeholder text for search input',
	},
	clearSearch: {
		id: 'servicedesk.queues.agent-view.layout.queues-details.issue-search.clear-search',
		defaultMessage: 'Clear search',
		description: 'Clear search input',
	},
	placeholderIssueTermRefresh: {
		id: 'servicedesk.queues.agent-view.layout.queues-details.issue-search.placeholder-issue-term-refresh',
		defaultMessage: 'Search for work items',
		description: 'Placeholder text for search input',
	},
	requestType: {
		id: 'servicedesk.queues.agent-view.layout.queues-details.issue-search.request-type',
		defaultMessage: 'Request type',
		description:
			'This text is used on a button in queues for agents to click to filter issues by request type',
	},
});
